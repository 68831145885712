<template>
  <div v-if="$store.state.showSelectedFeaturedProject" class="modal-content-featured-project">
    <ion-header>
      <ion-progress-bar v-if="showProgress" type="indeterminate"></ion-progress-bar>

      <ion-toolbar color="tertiary" style="padding:6px">
        <ion-buttons slot="end">
          <ion-button @click="closeFeaturedProject()" color="primary">Fermer</ion-button>
        </ion-buttons>
        <ion-label style="padding:0px 12px;padding-left:24px;display:block;font-weight: 600;">
          {{
    convert($store.state.selectedFeaturedProject.displayName.content)
  }}
        </ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-segment :value="'description'">
      <ion-segment-button style="--color-checked:var(--ion-color-step-950);--indicator-color: var(--ion-color-step-150)"
        value="description" @click="
    showInfos = false;
  showDescription = true;
  showPhotos = false;
  ">
        <ion-label>Description</ion-label>
      </ion-segment-button>
      <ion-segment-button style="--color-checked:var(--ion-color-step-950);--indicator-color: var(--ion-color-step-150)"
        value="infos" @click="
    showDescription = false;
  showInfos = true;
  showPhotos = false;
  ">
        <ion-label>Infos</ion-label>
      </ion-segment-button>
      <ion-segment-button style="--color-checked:var(--ion-color-step-950);--indicator-color: var(--ion-color-step-150)"
        value="photos" @click="
    showDescription = false;
  showInfos = false;
  showPhotos = true;
  ">
        <ion-label>Photos</ion-label>
      </ion-segment-button>

    </ion-segment>
    <span>
      <div v-if="showDescription" style="height: 200px;max-height:36vh;overflow-y: scroll;padding-bottom:32px">
        <ion-item color="tertiary">
          <span style="margin-right: 12px;font-weight: 600;">Lieu:</span>
          <span v-html="$store.state.selectedFeaturedProject.address.content"></span>
        </ion-item>
        <div color="tertiary" style="padding: 8px;">
          <img :src="$store.state.selectedFeaturedProject.posterFrame" class="img-featured-project-posterframe">
          <span v-html="$store.state.selectedFeaturedProject.particularites.content"></span>
        </div>
      </div>
      <div v-if="showInfos" style="height: 200px;max-height:36vh;overflow-y: scroll;padding-bottom:32px;padding-top:12px">
        <ion-item color="tertiary">
          <span v-html="$store.state.selectedFeaturedProject.partenairesDuProjet.content"></span>
        </ion-item>
      </div>
      <div v-if="showPhotos"
        style="height: 200px;max-height:36vh;overflow: auto;padding-bottom:16px;white-space:nowrap">
        <div color="tertiary" style="padding: 8px;">
          <span v-for="(someting, i) in $store.state.selectedFeaturedProject.projectPhotosAsArray" :key="someting">
            <img :src="someting.url" class="img-featured-project" @click="openPicturesSlider(i)"></span>
        </div>
      </div>
    </span>
  </div>
  <ion-modal :is-open="$store.state.showPicturesSlider" @didDismiss="$store.state.showPicturesSlider = false">
    <PicturesSlider :embedded="false"></PicturesSlider>
  </ion-modal>
</template>

<script>
import {
  IonHeader,
  IonToolbar,
  IonItem,
  IonLabel,
  IonButton,
  IonSegment,
  IonSegmentButton,
  IonModal
} from "@ionic/vue";
import {
  closeCircle,
  personOutline,
} from "ionicons/icons";
import PicturesSlider from "@/components/PicturesSlider.vue"
import { defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { convert } from "html-to-text";
import mapService from "@/services/mapService";
//console.log("newSpecimen view loaded");
export default defineComponent({
  name: "DetailProjetVedette",
  props: {
    // title: { type: String, default: "Ajouter un spécimen" },
  },
  setup() {
    const store = useStore();
    const showProgress = ref(true);
    const displayFeaturedProject = () => {
      showProgress.value = true;
      store.state.fetchedSpecimensAsFeatureCollection.features = [];
      const projectName = store.state.selectedFeaturedProject.name;
      store.state.fetchedSpecimensBySpecies = store.state.featuredProjectsList[projectName].species;
      store.state.fetchedSpecimensByPlantationDate = store.state.featuredProjectsList[projectName].plantationDate;
      store.state.fetchedSpecimensByGenus = store.state.featuredProjectsList[projectName].genus;
      // set bounding box for displayed specimens
      const bounds = [];
      let noOffset = false;
      Object.values(store.state.featuredProjectsList[projectName].bbox).forEach((point) => {
        bounds.push(point);
      })
      store.state.mapboxMap.fitBounds(bounds, {
        padding: 50,
        offset: noOffset ? [0, 0] : [0, -100],
      });
      if (store.state.currentMapStyle && store.state.currentMapStyle.indexOf("light") > -1) {
        // light
        store.state.mapboxMap.setStyle(store.state.featuredProjectsList[projectName].mapStyles.light);
      } else {
        store.state.mapboxMap.setStyle(store.state.featuredProjectsList[projectName].mapStyles.dark);
      }
      function moveendCallback() {
          store.state.mapboxMap.off("moveend", moveendCallback);
          showProgress.value = false;
          Object.values(store.state.featuredProjectsList[projectName].mapLayers).forEach((layer) => {
          store.state.mapboxMap.setLayoutProperty(layer, 'visibility', 'visible');
        });
        }
      store.state.mapboxMap.on("moveend", moveendCallback);
    };
    displayFeaturedProject();
    watch(
      () => store.state.selectedFeaturedProject,
      (oldData, newData) => {
        if (store.state.selectedFeaturedProject) {
          console.log("display oproject");
          displayFeaturedProject();
        }
      }
    );
    const showInfos = ref(false);
    const showDescription = ref(true);
    const openPicturesSlider = (i, picturesToSlide) => {
      const tempArray = [];
      store.state.selectedFeaturedProject.projectPhotosAsArray.forEach((picture) => {
        tempArray.push({ path: picture.url, id: picture.id })
      })
      store.state.picturesToSlide = tempArray;
      store.state.picturesToSlideInitialSlideIndex = i;
      console.log(store.state.picturesToSlideInitialSlideIndex);
      store.state.showPicturesSlider = true;
    };
    const closeFeaturedProject = () => {
      store.state.showSelectedFeaturedProject = false;
      store.state.selectedFeaturedProject = null;
      if (store.state.fetchedSpecimensAsFeatureCollection.features.length == 0) {
        store.state.fetchedSpecimensBySpecies = [];
        store.state.fetchedSpecimensByPlantationDate = [];
        store.state.fetchedSpecimensByGenus = [];
      }
      if (store.state.currentMapStyle && store.state.currentMapStyle.indexOf("light") > -1) {
        // light
        store.state.mapboxMap.setStyle("mapbox://styles/mapbox/light-v11");
      } else {
        store.state.mapboxMap.setStyle("mapbox://styles/mapbox/dark-v11");
      }
      function onStyleLoad() {
        store.state.mapboxMap.off('style.load', onStyleLoad);
        if (store.state.fetchedSpecimensAsFeatureCollection.features.length > 0) {
          mapService.putSpecimensOnMap(store.state.fetchedSpecimensAsFeatureCollection);
        }
      }
      store.state.mapboxMap.on('style.load', onStyleLoad);
      // make sure specimen detail is closed
      store.state.currentSegment = null;
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
      modalRef.setCurrentBreakpoint(0.001);
    };
    return {
      closeCircle,
      personOutline,
      convert,
      closeFeaturedProject,
      IonSegment,
      IonSegmentButton,
      showInfos,
      showDescription,
      showProgress,
      openPicturesSlider
    };
  },
  components: {
    IonHeader,
    IonToolbar,
    IonItem,
    IonLabel,
    IonButton,
    PicturesSlider,
    IonModal
  },
});
</script>
