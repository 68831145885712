import { ref } from "vue";
import store from "../store";
import { useShepherd } from 'vue-shepherd'
import 'shepherd.js/dist/css/shepherd.css'
import firebaseService from "../services/firebaseService";

console.log("loading guidedTour composable");

export function useGuidedTour() {
  const callFetchSpecimens = ref(false);
  const guidedTour = ()=>{
  console.log("elements");
  console.log(store.state.elementsForGuidedTour);
  const {loading } = firebaseService();

  const closeGuidedTour = () => {
    tour.complete();
  }

  const tour = useShepherd({
    useModalOverlay: true,
    keyboardNavigation: false,
    confirmCancel: true,
    confirmCancelMessage: "Êtes-vous certain de vouloir annuler le tour guidé?",
    defaultStepOptions: {
      classes: "tour-customized",
      highlightClass: "tour-highlight",
      scrollTo: true,
      cancelIcon: { enabled: true, label: "Annuler" },
      disabled: true
      // modalOverlayOpeningPadding: "30"
      // modalOverlayOpeningRadius: 60
    },
    steps: [
      {
      title: "<img style='width:48px;height:48px;margin-top:-8px;margin-bottom:-8px' src='./assets/icon/icon.png'><span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Bienvenue dans Branché!<span>",
      text: "Branché affiche des informations sur les arbres que vous croisez et vous permet d’y laisser des messages.  </br> </br> Explorez les principales fonctionnalités en suivant ce tour guidé.",
      cancelIcon: {enabled: false, label: "Annuler"},
      buttons: [
        {
          action() {
            store.state.setLocalPreferences('doNotShowGuidedTour','true');
            closeGuidedTour();
          },
          classes: 'shepherd-button-secondary-custom',
          text: 'Ne plus afficher'
        },
        {
          action() {
            this.hide();
            store.state.showNearestSpecimenModal = true;
            return this.next();
          },
          classes: 'shepherd-button-primary-custom',
          text: 'Suivant'
        }
      ],
    },{
      attachTo: { element: () => store.state.elementsForGuidedTour.tour2.$el, on: 'bottom' },
      title: "<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Mode Près de vous<span>",
      classes: "tour-customized-left",
      text: "En mode Près de vous, l’app affiche automatiquement l’espèce la plus près de l’usager et garde la carte centrée sur la position de l'usager. </br> </br>  Le mode Près de vous est activé par défaut lors de la première utilisation de l’application. </br> </br> Vous pouvez utiliser le bouton en forme de cible en haut à droite de la carte pour activer ou désactiver ce mode.",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          let counter = 0;
          console.log("promise!");
          console.log(store.state.elementsForGuidedTour);
          const interval = setInterval(() => {
            if (counter > 100 || store.state.elementsForGuidedTour.tour2) {
              console.log(counter);
              counter++;
              clearInterval(interval)
              resolve();
            }
          }, 20);
        });
      },
      buttons: [
        {
          action() {
            store.state.showNearestSpecimenModal = false;
            return this.back();
          },
          classes: 'shepherd-button-secondary-custom',
          text: 'Précédent'
        },
        {
          action() {
            this.hide();
            store.state.showNearestSpecimenModal = false;
            // toggle compass
            store.state.showCompass = true;
            // tilt camera
            store.state.mapboxMap.jumpTo({
              pitch: 60,
            });
            //  setTimeout(() => {
            return this.next();
            //   }, 100)
          },
          classes: 'shepherd-button-primary-custom',
          text: 'Suivant'
        }
      ],
    }, {
      attachTo: { element: () => store.state.elementsForGuidedTour.tour3?.$el, on: 'bottom' },
      title:"<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Mode boussole</span>",
      classes: "tour-customized-left",
      text: "En mode boussole, la carte s'oriente automatiquement en fonction de la direction vers laquelle pointe votre appareil.  </br> </br>Utile pour déterminer avec plus de précision où se trouvent les spécimens, particulièrement dans un parc.  </br> </br>  Vous pouvez utiliser le bouton en forme de cible en haut à droite de la carte pour activer ou désactiver ce mode.  Le bouton devient rouge et l'icône prend la forme d'une boussole lorsqu'activé.  </br> </br>  Les résultats varient selon la calibration du gyroscope de votre appareil.",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          let counter = 0;
          console.log("beforeShowPromise entered");
          const interval = setInterval(() => {
            console.log("interval entered");
            console.log(counter);
            console.log(store.state.elementsForGuidedTour);
            counter++;
            if (counter > 100 || store.state.elementsForGuidedTour.tour3?.$el) {
              console.log("beforeShowPromise entered");
              clearInterval(interval);
              resolve();
            }
          }, 20);
        });
      },
      buttons: [
        {
          action() {
            this.hide();
            store.state.showNearestSpecimenModal = true;
            store.state.deactivateCompassMode();
            //  setTimeout(() => {
            return this.back();
            //  }, 100)
          },
          classes: 'shepherd-button-secondary-custom',
          text: 'Précédent'
        },
        {
          action() {
            loading.value = true;
            store.state.deactivateCompassMode();
            return this.next();
          },
          classes: 'shepherd-button-primary-custom',
          text: 'Suivant'
        }
      ],
    },{
      attachTo: { element: () => store.state.elementsForGuidedTour.tour1?.$el, on: 'bottom' },
      title: "<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Afficher les arbres</span>",
      text: "Lorsque vous appuyez sur ce bouton, une requête est effectuée selon les critères de recherche que vous avez choisis. </br> </br>  Par défaut, tous les arbres à l'intérieur d'un rayon de 100 mètres à partir du centre de la carte sont retournés. </br> </br> Vous pouvez donc afficher les arbres éloignés de vous en déplaçant la carte à l'endroit voulu et en réappuyant sur ce bouton.",
      classes: "tour-customized-left",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          let counter = 0;
          const interval = setInterval(() => {
            console.log(counter);
            console.log(store.state.elementsForGuidedTour);
            counter++;
            if (counter > 100 || store.state.elementsForGuidedTour.tour1) {
              clearInterval(interval);
              resolve();
            }
          }, 20);
        });
      },
      buttons: [
        {
          action() {
            loading.value = false;
             // toggle compass
             store.state.showCompass = true;
             // tilt camera
             store.state.mapboxMap.jumpTo({
               pitch: 60,
             });
            return this.back();
          },
          classes: 'shepherd-button-secondary-custom',
          text: 'Précédent'
        },
        {
          action() {
           // store.commit("setShowGuidedTour", false);
           loading.value = false;
            store.state.showQueryFilters = true;
            return this.next();
          },
          classes: 'shepherd-button-primary-custom',
          text: 'Suivant'
        }
      ],
    },{
      attachTo: { element: () => store.state.elementsForGuidedTour.tour4.$el, on: 'bottom' },
      title: "<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Afficher les filtres de requête</span>",
      text: "Vous pouvez préciser les caractéristiques des arbres qui seront affichés sur la carte et modifier le rayon d'action avec ce menu.",
      classes: "tour-customized-bottom",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          let counter = 0;
          const interval = setInterval(() => {
            console.log(counter);
            counter++;
            if (counter > 100 || store.state.elementsForGuidedTour.tour4) {
              console.log(store.state.elementsForGuidedTour);
              clearInterval(interval);
              resolve();
            }
          }, 20);
        });
      },
      buttons: [
        {
          action() {
            loading.value = true;
            store.state.showQueryFilters = false;
            return this.back();
          },
          classes: 'shepherd-button-secondary-custom',
          text: 'Précédent'
        },
        {
          action() {
           // store.commit("setShowGuidedTour", false);

            return this.next();
          },
          classes: 'shepherd-button-primary-custom',
          text: 'Suivant'
        }
      ],
    },{
      attachTo: { element: () => store.state.elementsForGuidedTour.tour5, on: 'bottom' },
      title: "<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Modifier le rayon</span>",
      text: "Le rayon sélectionné est affiché brièvement sur la carte à chaque fois que vous la déplacez, sauf lorsque le mode Près de vous est activé.",
      classes: "tour-customized-bottom",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          let counter = 0;
          const interval = setInterval(() => {
            console.log(counter);
            counter++;
            if (counter > 100 || store.state.elementsForGuidedTour.tour5) {
              console.log(store.state.elementsForGuidedTour);
              clearInterval(interval);
              resolve();
            }
          }, 20);
        });
      },
      buttons: [
        {
          action() {
            return this.back();
          },
          classes: 'shepherd-button-secondary-custom',
          text: 'Précédent'
        },
        {
          action() {
           // store.commit("setShowGuidedTour", false);
            return this.next();
          },
          classes: 'shepherd-button-primary-custom',
          text: 'Suivant'
        }
      ],
    },{
      attachTo: { element: () => store.state.elementsForGuidedTour.tour6, on: 'bottom' },
      title: "<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Ajouter un filtre</span>",
      text: "Uniquement les spécimens correspondants à tous les filtres sélectionnés seront affichés sur la carte. </br> </br> N'oubliez pas de cliquer sur Appliquer pour activer les filtres choisis.  </br> </br>  Vous pouvez retirer les filtres à partir de ce menu ou à partir de la carte où tous les filtres activés sont affichés.",
      classes: "tour-customized-top",
      scrollTo: false,
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          let counter = 0;
          const interval = setInterval(() => {
            console.log(counter);
            counter++;
            if (counter > 100 || store.state.elementsForGuidedTour.tour6) {
              console.log(store.state.elementsForGuidedTour);
              clearInterval(interval);
              resolve();
            }
          }, 20);
        });
      },
      buttons: [
        {
          action() {
            return this.back();
          },
          classes: 'shepherd-button-secondary-custom',
          text: 'Précédent'
        },
        {
          action() {
           // store.commit("setShowGuidedTour", false);
           store.state.showQueryFilters = false;
            return this.next();
          },
          classes: 'shepherd-button-primary-custom',
          text: 'Suivant'
        }
      ],
    }, {
        attachTo: { element: () => store.state.elementsForGuidedTour.tour7.$el, on: 'bottom' },
        title: "<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Votre compte</span>",
        text: "Vous devez vous créer un compte pour ajouter de nouveaux arbres ou laisser des messages. </br> </br> <span>Vous pourrez ensuite afficher vos contributions sur la carte en sélectionnant un item.</span><img style='height:150px; margin:auto;display:block' src='./assets/images/guidedTourStats.png'>",
        classes: "tour-customized-bottom",
        scrollTo: false,
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            let counter = 0;
            const interval = setInterval(() => {
              console.log(counter);
              counter++;
              if (counter > 100 || store.state.elementsForGuidedTour.tour7) {
                console.log(store.state.elementsForGuidedTour);
                clearInterval(interval);
                resolve();
              }
            }, 20);
          });
        },
        buttons: [
          {
            action() {
              store.state.showQueryFilters = true;
              return this.back();
            },
            classes: 'shepherd-button-secondary-custom',
            text: 'Précédent'
          },
          {
            action() {
              // store.commit("setShowGuidedTour", false);
             /*  console.log(router);
              router.push({ path: "/userLogin/" }); */
              return this.next();
            },
            classes: 'shepherd-button-primary-custom',
            text: 'Suivant'
          }
        ],
      },
      {
        attachTo: { element: () => store.state.elementsForGuidedTour.tour8.$el, on: 'bottom' },
        title: "<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Ajouter de nouveaux arbres</span>",
        text: "Vous devez vous créer un compte pour ajouter de nouveaux arbres.",
        classes: "tour-customized-bottom",
        scrollTo: false,
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            let counter = 0;
            const interval = setInterval(() => {
              console.log(counter);
              counter++;
              if (counter > 100 || store.state.elementsForGuidedTour.tour8) {
                console.log(store.state.elementsForGuidedTour);
                clearInterval(interval);
                resolve();
              }
            }, 20);
          });
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary-custom',
            text: 'Précédent'
          },
          {
            action() {
              // store.commit("setShowGuidedTour", false);
             /*  console.log(router);
              router.push({ path: "/userLogin/" }); */
              return this.next();
            },
            classes: 'shepherd-button-primary-custom',
            text: 'Suivant'
          }
        ],
      },{
        attachTo: { element: () => store.state.elementsForGuidedTour.tour9.$el, on: 'bottom' },
        title: "<span style='margin-top:auto;margin-bottom:auto;margin-left:8px;font-weight:600'>Autres fonctionnalités</span>",
        text: "C'est ici que vous pourrez accéder aux projets vedettes de l'Alliance Forêt Urbaine.  </br> </br> Vous trouverez également dans la section À propos un lien vers un mode d'emploi plus détaillé de l'application.",
        classes: "tour-customized-bottom",
        scrollTo: false,
        beforeShowPromise: function () {
          return new Promise(function (resolve) {
            let counter = 0;
            const interval = setInterval(() => {
              console.log(counter);
              counter++;
              if (counter > 100 || store.state.elementsForGuidedTour.tour9) {
                console.log(store.state.elementsForGuidedTour);
                clearInterval(interval);
                resolve();
              }
            }, 20);
          });
        },
        buttons: [
          {
            action() {
              return this.back();
            },
            classes: 'shepherd-button-secondary-custom',
            text: 'Précédent'
          },
          {
            action() {
              // store.commit("setShowGuidedTour", false);
             /*  console.log(router);
              router.push({ path: "/userLogin/" }); */
              return this.next();
            },
            classes: 'shepherd-button-primary-custom',
            text: 'Terminé'
          }
        ],
      }
    ]
  });

  tour.start();

  tour.on("cancel",()=>{
    console.log("canceled");
    callFetchSpecimens.value = true;
  })

  tour.on("complete",()=>{
    console.log("completed");
    callFetchSpecimens.value = true;
  })

  /* const takePhoto = async (treePart, container, item) => {
    //console.log(treePart);
    //console.log(container);
    //console.log(item);
    uploading.value = false;
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
        quality: 100,
      });
      const filename = new Date().getTime() + ".jpg";
      const savedFileImage = await savePicture(
        photo,
        filename,
        treePart,
        container,
        item
      );
      photos.value = [savedFileImage, ...photos.value];
    } catch (e) {
      //console.log(e)
      // errors.value.push(e);
      //console.log('catch cameraphoto');
      uploading.value = false;
    }
  }; */
}
  return {
    callFetchSpecimens,
    guidedTour
  };
}
