/* eslint-disable no-prototype-builtins */
<template>
  <ion-content>
    <ion-header>
      <ion-toolbar style="padding-top: 0px">
        <ion-title>Modifier le rayon</ion-title>
      </ion-toolbar>
    </ion-header>
    <!--     rayon -->
    <div class="requests-options">
      <div :ref="(el) => { $store.state.elementsForGuidedTour['tour5'] = el }">
        <ion-item lines="none"><ion-label class="ion-text-wrap">
            Afficher uniquement les spécimens situés à
            <ion-text color="primary">{{
              $store.state.queryOptions.radius
              }}</ion-text>
            mètres du centre de la carte
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-range min="2" max="200" color="primary" v-model="$store.state.queryOptions.radius"
            @ionKnobMoveEnd="$store.state.drawRadiusCircleOnMap()">
            <ion-icon slot="start" size="small" :icon="locate"></ion-icon>
            <ion-icon slot="end" :icon="locate"></ion-icon>
          </ion-range>
        </ion-item>
      </div>
      <ion-header>
        <ion-toolbar style="padding-top: 0px" :ref="(el) => { $store.state.elementsForGuidedTour['tour6'] = el }">
          <ion-title>Filtrer les requêtes</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-progress-bar v-show="$store.state.showSpeciesListProgressBar" type="indeterminate"></ion-progress-bar>
      <div v-if="!$store.state.showSpeciesList">
        <span v-for="filter in $store.state.queryFiltersList.basicQueryFilters" :key="filter" class="ion-no-padding">
          <ion-item v-if="filter.displayName == 'classification' && !$store.state.showSpeciesList">
            <ion-input :label="filter.displayName" v-model="filter.displayValue" style="margin-left: 0px"
              :autofocus="false" :type="filter.inputType ? filter.inputType : 'text'" :placeholder="'espèce'"
              @click="activateSpeciesList()" :readonly="true"></ion-input>
            <ion-icon :icon="closeCircle" @click="removeFromQueryFiltersList(filter)"></ion-icon>
          </ion-item>
          <ion-item v-else-if="filter.type == 'boolean' && !$store.state.showSpeciesList
          ">
            <ion-toggle v-model="filter.enteredValue" @ionChange="handleToggleChange(filter)">{{ filter.displayName
              }}</ion-toggle>
          </ion-item>
          <ion-item v-else-if="filter.inputType == 'dropDownMenu'">
            <ion-select :label="filter.displayName" v-model="$store.state.selectedIdentifiedBy"
              :placeholder="filter.displayValue ? filter.displayValue : filter.enteredValue"
              @ionChange="handleChange($event, filter)" @ionCancel="handleCancel($event, filter)"
              @ionDismiss="handleDismiss()">
              <ion-select-option v-for="item in Object.values($store.state.collaborativeFormObjModel.identifiePar)"
                :key="item.name" :value="item.name">{{ item.name }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item v-else-if="!$store.state.showSpeciesList && filter.fieldName.indexOf(
            'geojsonFeature.properties.classification'
          ) == -1
          ">
            <ion-input :label="filter.displayName" v-model="filter.enteredValue" style="margin-left:0px"
              autofocus="false" :type="filter.inputType" :placeholder="filter.placeholder" maxlength="4"></ion-input>
            <ion-icon :icon="closeCircle" @click="removeFromQueryFiltersList(filter)"></ion-icon>
          </ion-item>
        </span>
        <span v-for="filter in $store.state.queryFiltersList.groupQueryFilters" :key="filter" class="ion-no-padding">
          <ion-item v-if="!$store.state.showSpeciesList">
            <ion-input :label="filter.displayName" v-model="filter.enteredValue" style="margin-left:0px"
              autofocus="false" :type="filter.inputType ? filter.inputType : 'text'"
              :placeholder="filter.placeholder"></ion-input>
            <ion-icon :icon="closeCircle" @click="removeFromQueryFiltersList(filter)"></ion-icon>
          </ion-item>
        </span>
      </div>

      <ion-list>
        <div v-if="$store.state.selectedFilter" class="ion-no-padding" style="margin-top: 12px" lines="none">
          <div v-if="$store.state.showSpeciesList" style="max-height: 100vh; width: 100%; overflow-y: scroll">
            <search-species-component usedIn="requests"></search-species-component>
          </div>

          <div v-if="$store.state.selectedFilter.displayName == 'habitation'" style="max-height: 50vh">
            <ion-searchbar placeholder="filtrer liste des habitations" showCancelButton="false" debounce="0"
              v-model="habitationsInput">
            </ion-searchbar>
            <ion-list class="habitations-selector" v-if="$store.state.uniqueHabitationNames">
              <ion-item v-for="habitation in $store.state.uniqueHabitationNames" :key="habitation" @click="
                $store.state.selectedFilter.enteredValue = habitation;
              addToQueryFiltersList($store.state.selectedFilter);
              " v-show="habitation.indexOf(habitationsInput) > -1 || !habitationsInput
        ">{{ habitation }}</ion-item>
            </ion-list>
          </div>
        </div>
      </ion-list>
      <ion-item v-if="$store.state.selectedFilter.displayName != 'espèce' &&
        $store.state.selectedFilter.displayName != 'habitation'
      ">
        <ion-button style="padding-bottom: 12px" slot="start" @click="
          addToQueryFiltersList($store.state.selectedFilter); $emit('fetchSpecimens', 'fitBounds');
        " fill="clear">Appliquer</ion-button>
        <ion-button style="padding-bottom: 12px" slot="end" @click="$store.state.showQueryFilters = false" fill="clear"
          :color="'secondary'">Fermer</ion-button>
      </ion-item>
    </div>
    <div @click="$store.state.showQueryFilters = false" style="width:100%;height: 50%; opacity: 0;top:0px"></div>
  </ion-content>
</template>

<script>
import {
  IonItem,
  IonLabel,
  IonList,
  IonRange,
  IonIcon,
  IonText,
  IonToggle,
  IonInput,
  IonButton,
  IonSearchbar,
  IonSelect,
  IonSelectOption
} from "@ionic/vue";
import {
  personOutline,
  personCircle,
  search,
  ellipsisHorizontal,
  locate,
  checkmarkCircle,
  closeCircle,
  home,
  informationCircle,
  navigate,
  shuffle,
  star,
  checkmarkCircleOutline,
} from "ionicons/icons";
import { defineComponent, ref, watch, onMounted } from "vue";
import { useStore } from "vuex";
import searchSpeciesComponent from "./searchSpeciesComponent.vue";
import firebaseService from "@/services/firebaseService";

export default defineComponent({
  name: "SubView1",
  components: {
    IonItem,
    IonLabel,
    IonList,
    IonRange,
    IonIcon,
    IonText,
    IonToggle,
    IonInput,
    searchSpeciesComponent,
    IonButton,
    IonSearchbar,
    IonSelect,
    IonSelectOption
  },
  setup() {
    //console.log("setup function reached");
    // This is the equivalent of retrieving this.$store within a component using the Option API
    const store = useStore();

    onMounted(() => {
      console.log(store.state.selectedIdentifiedBy);
    });

    const compareWith = (o1, o2) => {
      return o1 && o2 ? o1.name === o2.name : o1 === o2;
    };

    const handleToggleChange = (filter) => {
      if (!filter.enteredValue) {
        removeFromQueryFiltersList(filter);
      }
    };

    const activateSpeciesList = () => {
      store.state.showSpeciesListProgressBar = true;
      setTimeout(() => {
        store.state.showSpeciesList = !store.state.showSpeciesList;
      }, 10)
    }

    const handleChange = (ev, filter) => {
      if (!ev.detail.value) {
        return
      }
      // find back proper query term in filters list
      for (let index = 0; index < Object.values(store.state.collaborativeFormObjModel.identifiePar).length; index++) {
        const element = Object.values(store.state.collaborativeFormObjModel.identifiePar)[index];
        if (element.name == ev.detail.value) {
          filter.enteredValue = element.name;
          if (element.customPath) {
            filter.customPath = element.customPath;
            filter.enteredValue = element.value;
            filter.displayValue = element.name;
          } else {
            delete filter.customPath;
            delete filter.displayValue;
          }
          break;
        }
      }
    };
    const handleCancel = (ev, filter) => {
      if (ev.type == "ion-cancel") {
        filter.enteredValue = null;
        delete filter.customPath;
        delete filter.displayValue;
        store.state.selectedIdentifiedBy = null;
        console.log("canceled")
      }
    };
    const handleDismiss = () => {
      console.log("dismissed")
    };

    watch(
      () => store.state.selectedSpeciesFilter,
      () => {
        //console.log("watch selected filter");
        if (Object.keys(store.state.selectedSpeciesFilter).length) {
          store.state.queryFiltersList.basicQueryFilters.forEach((filter) => {
            //console.log(store.state.selectedSpeciesFilter.fieldName);
            // check if another classification field was already set.  If so, clear it
            if (
              filter.fieldName.indexOf("classification") > -1 &&
              filter.fieldName.indexOf(
                store.state.selectedSpeciesFilter.fieldName
              ) == -1
            ) {
              filter.enteredValue = null;
              filter.displayValue = null;
            }
            if (
              filter.fieldName.indexOf(
                store.state.selectedSpeciesFilter.fieldName
              ) > -1 ||
              filter.displayName == "classification"
            ) {
              filter.enteredValue =
                store.state.selectedSpeciesFilter.enteredValue;
              filter.displayValue =
                store.state.selectedSpeciesFilter.displayValue;
            }
            store.state.showSpeciesList = false;
          });
        }
      }
    );
    const selectedFilter = ref({ displayName: "", enteredValue: "" });
    let queriesListObj = {};
    store.state.queryOptions.filters.forEach((filter) => {
      queriesListObj[filter.displayName] = filter;
    });
    const habitationsInput = ref();
    const queryFilterSelected = (e) => {
      if (
        store.state.userGroupMembership == "omhm" &&
        store.state.selectedFilter.value &&
        store.state.selectedFilter.value.displayName == "habitation" &&
        !store.state.omhmAddressesFullList
      ) {
        // fetch all omhm addresses
        firebaseService().fetchOMHMAddresses(null, 1000000, false);
      }
    };
    const removeFromQueryFiltersList = (filter) => {
      if (queriesListObj[filter.displayName]) {
        delete queriesListObj[filter.displayName];
      }
      for (
        let index = 0;
        index < store.state.queryFiltersList.basicQueryFilters.length;
        index++
      ) {
        const currFilter =
          store.state.queryFiltersList.basicQueryFilters[index];
        if (currFilter.displayName == "classification") {
          // reset all classification related filters
          currFilter.displayValue = null;
          currFilter.enteredValue = null;
          store.state.queryFiltersList.basicQueryFilters.forEach(
            (basicQueryFilter) => {
              if (basicQueryFilter.fieldName.indexOf("classification") > -1) {
                basicQueryFilter.enteredValue = null;
                basicQueryFilter.displayValue = null;
              }
            }
          );
          store.commit("setSelectedSpeciesFilter", {
            displayValue: "",
            enteredValue: null,
          });
        }
        if (currFilter.displayName == filter.displayName) {
          // ion-input automatically parses null value as a 0 and prevents the placeholder to show when input type is number
          store.state.queryFiltersList.basicQueryFilters[index].enteredValue = null;
          store.state.queryFiltersList.basicQueryFilters[index].displayValue = null;
          break;
        }
      }
      for (
        let index = 0;
        index < store.state.queryFiltersList.groupQueryFilters.length;
        index++
      ) {
        const currFilter =
          store.state.queryFiltersList.groupQueryFilters[index];
        if (currFilter.displayName == filter.displayName) {
          // ion-input automatically parses null value as a 0 and prevents the placeholder to show when input type is number
          store.state.queryFiltersList.groupQueryFilters[index].enteredValue =
            null;
          store.state.queryFiltersList.groupQueryFilters[index].displayValue =
            null;
          break;
        }
      }
    };
    const addToQueryFiltersList = () => {
      store.state.queryFiltersList.basicQueryFilters.forEach((filter) => {
        // add to queriesListObj that is used to avoid adding a filter twice
        if (filter.displayName != "classification") {
          if (filter.displayName == "identifié par") {
            // check if the displayValue requires a specific path 
            for (let index = 0; index < Object.values(store.state.collaborativeFormObjModel.identifiePar).length; index++) {
              const element = Object.values(store.state.collaborativeFormObjModel.identifiePar)[index];
              if (element.name == filter.displayValue) {
                filter.enteredValue = element.name;
                if (element.customPath) {
                  filter.customPath = element.customPath;
                  filter.enteredValue = element.value;
                  filter.displayValue = element.name;
                } else {
                  delete filter.customPath;
                  delete filter.displayValue;
                }
                break;
              }
            }
          }
          if (filter.customPath) {
            // make a copy and overwrite fieldName with custom path value
            const tempCustomPathFilter = JSON.parse(JSON.stringify(filter));
            tempCustomPathFilter.fieldName = tempCustomPathFilter.customPath;
            queriesListObj[filter.displayName] = tempCustomPathFilter;
            // remove custom Path prop from filter
            delete filter.customPath
          } else {
            queriesListObj[filter.displayName] = filter;
          }
        }
      });
      store.state.queryFiltersList.groupQueryFilters.forEach((filter) => {
        // add to queriesListObj that is used to avoid adding a filter twice
        queriesListObj[filter.displayName] = filter;
      });
      console.log(queriesListObj);
      // push in an array for the view
      const keyNames = Object.keys(queriesListObj);
      store.commit("setFiltersListArrayInQueryOptions", []);
      const tempArray = [];
      keyNames.forEach((keyName) => {
        if (queriesListObj[keyName].enteredValue)
          tempArray.push(queriesListObj[keyName]);
      });
      store.commit("setFiltersListArrayInQueryOptions", tempArray);
      store.state.selectedFilter = { displayName: "", enteredValue: "" };
      store.state.showQueryFilters = false;
    };
    const editValue = (value) => {
      store.state.selectedFilter.value = value;
    };

    return {
      personOutline,
      personCircle,
      search,
      ellipsisHorizontal,
      locate,
      checkmarkCircle,
      closeCircle,
      home,
      informationCircle,
      navigate,
      shuffle,
      star,
      checkmarkCircleOutline,
      queryFilterSelected,
      selectedFilter,
      addToQueryFiltersList,
      removeFromQueryFiltersList,
      editValue,
      habitationsInput,
      handleToggleChange,
      handleCancel,
      handleChange,
      handleDismiss,
      compareWith,
      activateSpeciesList,
    };
  },
});
</script>
<style lang="css" scoped>
ion-item {
  /* font-size: small;
   --background: transparent;
 */
  border-top: 1px solid --ion-color-secondary;
}

ion-input {
  background-color: var(--ion-background-color);
}

ion-range {
  padding: 0px;
}
</style>
