<template>
  <ion-page class="ion-page">
    <ion-header :translucent="true">
      <ion-toolbar class="toolbar-for-stats" id="top-toolbar">
        <ion-buttons slot="start">
          <ion-button @click="closeAllModals()" :routerLink="'/userLogin/'" :color="'secondary'" size="large">
            <ion-icon :icon="personOutline" :ref="(el) => { $store.state.elementsForGuidedTour['tour7'] = el }"/>
          </ion-button>
          <ion-button @click="displayQueryFilters()" :color="$store.state.showQueryFilters ? 'primary' : 'secondary'"
            size="large" class="toolbar-second-button">
            <ion-icon :icon="refreshOutline" :ref="(el) => { $store.state.elementsForGuidedTour['tour4'] = el }"/>
          </ion-button>
          <ion-button @click="showNewSpecimenModal({ centerMap: true, timeOut: 10000 })"
            :color="$store.state.showNewSpecimenModal ? 'primary' : 'secondary'" size="large"
            class="toolbar-third-button">
            <ion-icon :icon="add" style="font-size:xx-large" :ref="(el) => { $store.state.elementsForGuidedTour['tour8'] = el }"/>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button :color="$store.state.showMapMenu ? 'primary' : 'secondary'" size="large"
            @click="displayMapMenu()">
            <ion-icon :ios="ellipsisHorizontal" :md="ellipsisVertical" :ref="(el) => { $store.state.elementsForGuidedTour['tour9'] = el }"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar v-if="mapLoaded" class="toolbar-for-stats">
        <ion-title :class="{
      'fade-in': mapLoaded,
      'fade-out': !$store.state.showTryingToAcquirePosition,
    }" v-if="$store.state.showTryingToAcquirePosition" style="padding: 10px">
          <ion-label>Acquisition de votre position</ion-label>
          <ion-spinner style="height: 18px"></ion-spinner>
        </ion-title>

        <span v-else :class="{
      'fade-in': !$store.state.showTryingToAcquirePosition,
    }">
          <ion-note v-if="appliedFilterNames.length == 0">
            {{ specimensQty() }}
            <span
              v-if="$store.state.fetchedSpecimensAsFeatureCollection.features.length > 0 || $store.state.selectedFeaturedProject">
              <ion-label style="margin-left: 8px;cursor: pointer;" v-if="!$store.state.showFetchedSpecimensStats"
                @click="
      closeAllModals();
    $store.state.showFetchedSpecimensStats = true;
    " color="primary">
                Afficher stats
              </ion-label>
              <ion-label style="margin-left: 8px;cursor: pointer;" v-if="$store.state.showFetchedSpecimensStats"
                @click="$store.state.showFetchedSpecimensStats = false" color="primary">
                Fermer stats </ion-label><ion-icon style="margin-top: 2px" :icon="!$store.state.showFetchedSpecimensStats
      ? chevronForward
      : chevronDown
      " :color="!$store.state.showFetchedSpecimensStats
      ? 'primary'
      : 'secondary'
      "></ion-icon>
            </span>
            <span v-else style="cursor: pointer;color: orangered;"
              @click="$store.state.showQueryFilters = !$store.state.showQueryFilters"> <ion-icon :icon="refreshOutline"
                style="margin-left:6px;margin-right:4px" />rayon de requête: {{ $store.state.queryOptions.radius }}
              m</span>
          </ion-note>
          <!-- <ion-note
            
            style="float: right; cursor: pointer"
          >
            {{
              $store.state.fetchedSpecimensBySpecies.length +
              ($store.state.fetchedSpecimensBySpecies.length > 1
                ? " espèces différentes"
                : " espèce")
            }}
            <ion-icon
              :icon="
                !showFetchedSpecimensBySpecies ? chevronForward : chevronDown
              "
              color="primary"
            ></ion-icon>
          </ion-note> -->
          <ion-note v-else style="cursor: pointer; text-align: right">
            <ion-icon :icon="filterOutline" color="danger"></ion-icon>
            <span v-if="appliedFilterNames" style="margin-left: 12px">{{
      appliedFilterNames.join()
    }}</span>
            <b style="margin-left: 8px">{{ filteredSpecimensQty }} spécimens
            </b>

            <span>
              <ion-label style="margin-left: 8px;cursor: pointer;" v-if="!$store.state.showFetchedSpecimensStats"
                @click="
      closeAllModals();
    $store.state.showFetchedSpecimensStats = true;
    " color="primary">
                Afficher stats
              </ion-label>
              <ion-label style="margin-left: 8px;cursor: pointer;" v-if="$storestore.state.showFetchedSpecimensStats"
                @click="$store.state.showFetchedSpecimensStats = false" color="primary">
                Fermer stats </ion-label><ion-icon style="margin-top: 2px" :icon="!$store.state.showFetchedSpecimensStats
      ? chevronForward
      : chevronDown
      " color="primary"></ion-icon></span>
          </ion-note>
          <!-- <ion-select
            interface="popover"
            :placeholder="
              Object.keys($store.state.fetchedSpecimensBySpecies).length +
              ' espèces différentes'
            "
          >
          
            <ion-select-option
              v-for="(species, key) in $store.state.fetchedSpecimensBySpecies"
              :value="'a'"
              :key="key"
            >
              <img
                slot="start"
                :src="'../assets/arbres/' + key.slice(0, 2) + '.png'"
                @error="imageLoadOnError"
              />
              {{ species.nameFr }} {{ key.slice(0, 2) }}</ion-select-option
            >
          </ion-select> -->
        </span>
      </ion-toolbar>
      <ion-toolbar v-if="!mapLoaded">
        <ion-title :class="{
      'fade-out': mapLoaded,
    }">
          <ion-label class="ion-text-center" style="width: 100%">Chargement de la carte</ion-label>
          <ion-progress-bar type="indeterminate"></ion-progress-bar>
        </ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content id="main" style="--overflow: hidden">
      <div v-show="$store.state.showFetchedSpecimensStats" class="stats-overlay">
        <ion-segment :value="showFetchedSpecimensByYear ? 'years' : 'genres'">
          <ion-segment-button
            style="--color-checked:var(--ion-color-step-950);--indicator-color: var(--ion-color-step-150)"
            value="genres" @click="
      showFetchedSpecimensBySpecies = true;
    showFetchedSpecimensByYear = false;
    ">
            <ion-label>Genres/Espèces</ion-label>
          </ion-segment-button>
          <ion-segment-button
            style="--color-checked:var(--ion-color-step-950);--indicator-color: var(--ion-color-step-150)" value="years"
            @click="
      showFetchedSpecimensByYear = true;
    showFetchedSpecimensBySpecies = false;
    ">
            <ion-label>Années</ion-label>
          </ion-segment-button>
        </ion-segment>
        <div class="stats-section" v-show="showFetchedSpecimensBySpecies">
          <PieChart></PieChart>
          <ion-list lines="full">
            <!-- <ion-item
              v-if="filterFetchedSpecimensBySpecies"
              color="danger"
              @click="
                filterSpecimensOnMapBySpecies(null);
                filterSpecimensOnMapByYear(null);
              "
              style="font-size: smaller"
            >
              <ion-icon :icon="filterOutline"></ion-icon>
              <b style="margin-left: 8px; margin-right: 8px">Retirer filtres</b>
            </ion-item> -->
            <!-- <ion-item
              v-if="filterFetchedSpecimensBySpecies"
              color="danger"
              @click="
                filterSpecimensOnMapBySpecies(null);
                filterSpecimensOnMapByYear(null);
              "
              style="font-size: smaller"
            >
              <ion-icon :icon="filterOutline"></ion-icon>
              <b style="margin-left: 8px; margin-right: 8px">Retirer filtres</b>
            </ion-item>
            <ion-item
              v-for="species in $store.state.fetchedSpecimensBySpecies"
              :key="species"
              @click="filterSpecimensOnMapBySpecies(species)"
              style="font-size: smaller"
            >
              <img
                slot="start"
                :src="'../assets/arbres/' + species.code.slice(0, 2) + '.png'"
                @error="imageLoadOnError"
                style="width: 32px; max-height: 40px"
              />
              <ion-label class="ion-text-wrap">{{ species.nameFr }} </ion-label>
              <ion-label slot="end"> {{ species.amount }}</ion-label>
            </ion-item> -->
            <ion-item v-for="species in $store.state.fetchedSpecimensBySpecies" :key="species"
              style="font-size: smaller">
              <img slot="start" :src="'../assets/arbres/' + species.code.slice(0, 2) + '@2x.png'"
                @error="imageLoadOnError" style="width: 32px; max-height: 40px" />
              <ion-label class="ion-text-wrap">{{ species.nameFr }} </ion-label>
              <ion-label slot="end" style="max-width: 48px;text-align: right;">
                {{ species.amount }}</ion-label>
            </ion-item>
          </ion-list>
        </div>

        <div class="stats-section" v-show="showFetchedSpecimensByYear">
          <BarChart></BarChart>
          <ion-list>
            <!-- <ion-item
              v-if="filterFetchedSpecimensByYear"
              color="danger"
              @click="
                filterSpecimensOnMapByYear(null);
                filterSpecimensOnMapBySpecies(null);
              "
              style="font-size: smaller"
            >
              <ion-icon :icon="filterOutline"></ion-icon>
              <b style="margin-left: 8px; margin-right: 8px">Retirer filtres</b>
            </ion-item>
            <ion-item
              v-for="year in $store.state.fetchedSpecimensByPlantationDate"
              :key="year"
              @click="filterSpecimensOnMapByYear(year)"
              :style="{
                color:
                  year.year == 'inconnue' ? 'red' : 'var(--ion-text-color)',
                'font-size': 'smaller',
              }"
            >
              <ion-label>{{ year.year }} </ion-label>
              <ion-label slot="end"> {{ year.amount }}</ion-label>
            </ion-item> -->
            <ion-item v-for="year in $store.state.fetchedSpecimensByPlantationDate" :key="year">
              <ion-label>{{ year.year }} </ion-label>
              <ion-label slot="end" style="max-width: 48px;text-align: right;">
                {{ year.amount }}</ion-label>
            </ion-item>
          </ion-list>
        </div>
      </div>
      <ion-toast :is-open="showNearestSpecimenActivatedToast" message="Mode près de vous activé"
        position-anchor="top-toolbar" position="top" :duration="5000" :icon="locate"
        @didDismiss="showNearestSpecimenActivatedToast = false" swipeGesture="vertical" :translucent="true"></ion-toast>
      <ion-toast :is-open="showCompassActivatedToast" message="Mode boussole activé" position-anchor="top-toolbar"
        position="top" :duration="5000" :icon="compass" @didDismiss="showCompassActivatedToast = false"
        swipeGesture="vertical" :translucent="true"></ion-toast>
      <ion-toast :is-open="showCompassDeactivatedToast" message="Mode boussole désactivé" position-anchor="top-toolbar"
        position="top" :duration="5000" @didDismiss="showCompassDeactivatedToast = false" :icon="compassOutline"
        swipeGesture="vertical" :translucent="true"></ion-toast>
      <ion-fab v-if="$store.state.showCompass" horizontal="end" vertical="top" :activated="false">
        <ion-fab-button :ref="(el) => { $store.state.elementsForGuidedTour['tour3'] = el }" @click="deactivateNearestSpecimen" :color="'danger'">
          <ion-icon :icon="compass"></ion-icon>
        </ion-fab-button>

        <!-- <ion-fab-button
          
          @click="$store.state.showGeocoder = !$store.state.showGeocoder"
          :color="$store.state.showGeocoder ? 'primary' : 'secondary'"
        >
          <ion-icon :icon="searchOutline"></ion-icon>
        </ion-fab-button> -->
        <ion-fab-button :color="loading ? 'primary' : 'tertiary'" @click="fetchSpecimens" :disabled="loading">
          <ion-icon :icon="refreshOutline" v-if="!loading"></ion-icon>
          <ion-spinner v-else></ion-spinner>
        </ion-fab-button>
      </ion-fab>
      <ion-fab v-else horizontal="end" vertical="top" :activated="false">
        <ion-fab-button :ref="(el) => { $store.state.elementsForGuidedTour['tour2'] = el }" id="nearest-specimen" @click="showNearestSpecimen" :color="$store.state.showNearestSpecimenModal ? 'primary' : 'tertiary'
      ">
          <ion-icon :icon="locateOutline"></ion-icon>
        </ion-fab-button>
        <!-- <ion-fab-button
          
          @click="$store.state.showGeocoder = !$store.state.showGeocoder"
          :color="$store.state.showGeocoder ? 'primary' : 'secondary'"
        >
          <ion-icon :icon="searchOutline"></ion-icon>
        </ion-fab-button> -->
        <ion-fab-button :ref="(el) => { $store.state.elementsForGuidedTour['tour1'] = el }" :color="loading ? 'primary' : 'tertiary'" @click="fetchSpecimens"
          :disabled="loading">
          <ion-icon :icon="refreshOutline" v-if="!loading"></ion-icon>
          <ion-spinner v-else></ion-spinner>
        </ion-fab-button>
      </ion-fab>

      <div class="map-overlays">
        <nearest-specimen v-if="$store.state.showNearestSpecimenModal == 'disabled'" class="nearest-specimen-tab">
          <nearestSpecimenModal></nearestSpecimenModal>
        </nearest-specimen>
        <span v-for="(filter, key) in $store.state.queryOptions.filters" :key="key">
          <ion-chip v-show="!filter.hideFromMap" style="
              position: relative;
              padding-top: 8px;
              z-index: 1;
              --background: var(--ion-background-color-transparent);
            ">
            <ion-icon :icon="filterOutline" color="primary"></ion-icon>
            <ion-label>{{ filter.displayName }}<span v-show="filter.type != 'boolean'">: </span><b>{{ filter.displayValue ? filter.displayValue : filter.enteredValue }}</b></ion-label>
            <ion-icon :icon="closeCircle" color="dark-grey" @click="removeSelectedQueryFilter(filter, key)"></ion-icon>
          </ion-chip>
        </span>
      </div>
      <div v-if="!$store.state.sideMenuDisabled">
        <side-menu class="side-menu-main"></side-menu>
      </div>
      <mapbox-gl class="map-container" :class="{
      mapwithnearestspecimenelement: $store.state.showNearestSpecimenModal,
      mapwithoutnearestspecimenelement:
        !$store.state.showNearestSpecimenModal,
    }" :mapDidLoad="handleMapDidLoad" @fetch-specimens="fetchSpecimens">
      </mapbox-gl>

      <!-- <div v-show="$store.state.currentSegment == 'specimen'" class="modal-content-specimen-details" :style="{
        height:
          $store.state.specimenDetailsContentElementHeight ? $store.state.specimenDetailsContentElementHeight +
          50 +
          136 +
          100 + 
          'px':'95%',
        'max-height': '95%',
      }">
        <side-menu-specimen v-if="$store.state.currentSegment == 'specimen'"></side-menu-specimen>
      </div> -->

      <ion-modal ref="modal" class="modal-content-specimen-details" :is-open="true"
        :initial-breakpoint="$store.state.breakPointRelativeToContent"
        :breakpoints="$store.state.returnCappedBreakpoints()" :backdrop-dismiss="true" :backdrop-breakpoint="1"
        @ionModalDidDismiss="modalDismissedTest()" @ionBreakpointDidChange="deselectMarkerIfNeeded()"
        handleBehavior="none" :canDismiss="false">
        <side-menu-specimen v-if="$store.state.currentSegment == 'specimen'"></side-menu-specimen>
      </ion-modal>
      <ion-modal :is-open="$store.state.showCleAddNewSpecimen" @didDismiss="$store.state.showCleAddNewSpecimen = false">
        <cle-add-new-specimen></cle-add-new-specimen>
      </ion-modal>

      <!--       <ion-modal
        :is-open="$store.state.showMapMenu"
        :showBackdrop="true"
        :backdropDismiss="true"
        @ionModalDidDismiss="$store.state.showMapMenu = false"
        class="modal-content-map-settings"
        vertical="bottom"
        horizontal="center"
      >

        
      </ion-modal> -->

      <map-menu-component slot="fixed" v-if="$store.state.showMapMenu" @fetch-specimens="fetchSpecimens">
      </map-menu-component>
      <requests class="map-settings" slot="fixed" v-if="$store.state.showQueryFilters"
        @fetch-specimens="fetchSpecimens">
      </requests>
      <detail-projet-vedette v-if="$store.state.showSelectedFeaturedProject"></detail-projet-vedette>
      <!--  <ion-modal
        :is-open="$store.state.showQueryFilters"
        :showBackdrop="true"
        :backdropDismiss="true"
        @ionModalDidDismiss="$store.state.showQueryFilters = false"
      >
        <div>
          
        </div>
      </ion-modal> -->

      <new-specimen vertical="bottom" horizontal="center" slot="fixed" v-if="$store.state.showNewSpecimenModal &&
      $store.state.userGroupMembership != 'omhm'
      " class="new-specimen-component"></new-specimen>
    </ion-content>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonButton,
  IonFab,
  IonIcon,
  IonNote,
  IonButtons,
  createAnimation,
  IonFabButton,
  IonSpinner,
  IonModal,
  IonLabel,
  menuController,
  isPlatform,
  getPlatforms,
  IonChip,
  IonItem,
  IonList,
  IonSegment,
  IonSegmentButton,
  IonToast
} from "@ionic/vue";
import {
  personOutline,
  personCircle,
  searchOutline,
  ellipsisHorizontal,
  ellipsisVertical,
  locate,
  locateOutline,
  settings,
  refreshOutline,
  addCircleOutline,
  filterOutline,
  calendarOutline,
  leaf,
  add,
  close,
  closeCircle,
  compass,
  compassOutline,
  chevronForward,
  chevronDown,
} from "ionicons/icons";
import { defineComponent, computed, ref, onMounted, watch } from "vue";
import MapboxGl from "@/components/MapboxGL.vue";
import "mapbox-gl/dist/mapbox-gl.css";
import firebaseService from "../services/firebaseService";
import { useStore } from "vuex";
import SideMenu from "@/components/sideMenu.vue";
import NewSpecimen from "@/components/newSpecimen.vue";
import NearestSpecimen from "@/components/nearestSpecimen.vue";
import MapMenuComponent from "@/components/MapMenuComponent.vue";
import mapService from "@/services/mapService";
import sideMenuSpecimen from "@/components/sideMenuSpecimen.vue";
import nearestSpecimenModal from "@/components/nearestSpecimenModal.vue";
import requests from "@/components/sideMenuRequests.vue";
import CleAddNewSpecimen from "@/components/CleAddNewSpecimen.vue";
import { Capacitor } from "@capacitor/core";
import BarChart from "@/components/BarChart";
import PieChart from "@/components/PieChart";
import DetailProjetVedette from "@/components/detailProjetVedette.vue";
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';
import { useGuidedTour } from "@/composables/useGuidedTour";
import { Preferences } from '@capacitor/preferences';
console.log("loading tab1 view");

if (Capacitor.isPluginAvailable("Motion")) {
  //console.log("motion is available");
  /* if (DeviceOrientationEvent) {
  //console.log(DeviceOrientationEvent);
  //console.log(typeof DeviceOrientationEvent.requestPermission === "function");
  } */
} else {
  //console.log("motion is available NOT");
}

export default defineComponent({
  name: "Tab1Page",
  methods: {},
  components: {
    // ExploreContainer,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonButton,
    IonIcon,
    MapboxGl,
    IonButtons,
    NewSpecimen,
    MapMenuComponent,
    sideMenuSpecimen,
    CleAddNewSpecimen,
    DetailProjetVedette,
    BarChart,
    PieChart,
    IonModal,
    IonSpinner,
    IonFab,
    IonFabButton,
    IonLabel,
    NearestSpecimen,
    nearestSpecimenModal,
    SideMenu,
    IonNote,
    requests,
    IonChip,
    IonItem,
    IonList,
    IonSegment,
    IonSegmentButton,
    IonToast,
  },
  setup() {
    const store = useStore();
    const pageEl = document.querySelector(".test-class");
    const splitPaneHeight = ref("auto");
    const showFetchedSpecimensBySpecies = ref(true);
    const showFetchedSpecimensByYear = ref(false);
    const filterFetchedSpecimensBySpecies = ref(null);
    const filterFetchedSpecimensByYear = ref(null);
    const currentFilter = ref(null);
    const currentBreakpoint = ref(0.4);
    const mapLoaded = ref(false);
    const showNearestSpecimenActivatedToast = ref(false);
    const showCompassActivatedToast = ref(false);
    const showCompassDeactivatedToast = ref(false);

    const imageLoadOnError = (e) => {
      e.target.src = "../assets/arbres/XX@2x.png";
    };
    const specimensQty = () => {
      store.state.featureCollectionLength = 0;
      if (store.state.selectedFeaturedProject) {
        store.state.featureCollectionLength = store.state.featuredProjectsList[store.state.selectedFeaturedProject.name].qty;
      } else {
        store.state.featureCollectionLength = store.state.fetchedSpecimensAsFeatureCollection.features.length
      }
      return "" + store.state.featureCollectionLength +
        (store.state.featureCollectionLength > 1
          ? " spécimens trouvés"
          : " spécimen trouvé")
    };
    const modalDismissedTest = () => {
      console.log("modal dismissed event");
      store.state.currentSegment == "specimen";
      store.state.cappedBreakpoints = [
        0.001, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7,
        0.75, 0.8, 0.85, 0.9, 0.95,
      ];
      store.state.breakPointRelativeToContent = 0.15;
    };
    const breakpoints = ref([
      0.001, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7,
      0.75, 0.8, 0.85, 0.9, 0.95,
    ]);
    const cappedBreakpoints = ref([
      0.001, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7,
      0.75, 0.8, 0.85, 0.9, 0.95,
    ]);
    const applyCappedBreakpoints = () => {
      //console.log("applying capped breakpoints");
      store.state.cappedBreakpoints = store.state.breakpoints.filter(function (
        x
      ) {
        return x <= store.state.breakPointRelativeToContent;
      });
    };

    const removeSelectedQueryFilter = (filter, key) => {
      console.log("trying to remove selected query filter");
      store.state.queryOptions.filters.splice(key, 1);
      for (
        let index = 0;
        index < store.state.queryFiltersList.basicQueryFilters.length;
        index++
      ) {
        const currFilter =
          store.state.queryFiltersList.basicQueryFilters[index];
        if (
          currFilter.fieldName.indexOf("classification") > -1 &&
          currFilter.displayName == filter.displayName
        ) {
          // reset all classification related filters
          currFilter.displayValue = null;
          currFilter.enteredValue = null;
          store.state.queryFiltersList.basicQueryFilters.forEach(
            (basicQueryFilter) => {
              if (basicQueryFilter.fieldName.indexOf("classification") > -1) {
                basicQueryFilter.enteredValue = null;
                basicQueryFilter.displayValue = null;
              }
            }
          );
          store.commit("setSelectedSpeciesFilter", {
            displayValue: "",
            enteredValue: null,
          });
        } else if (currFilter.displayName == filter.displayName) {
          store.state.queryFiltersList.basicQueryFilters[index].enteredValue = null;
            if (filter.displayName == "identifié par") {
              store.state.selectedIdentifiedBy = null;
            }
          store.state.queryFiltersList.basicQueryFilters[index].displayValue =
            null;
          break;
        }
      }
      for (
        let index = 0;
        index < store.state.queryFiltersList.groupQueryFilters.length;
        index++
      ) {
        const currFilter =
          store.state.queryFiltersList.groupQueryFilters[index];
        if (currFilter.displayName == filter.displayName) {
          store.state.queryFiltersList.groupQueryFilters[index].enteredValue =
            null;
          store.state.queryFiltersList.groupQueryFilters[index].displayValue =
            null;
          break;
        }
      }
      fetchSpecimens("fitBounds");
    };

    let innerHeight = ref(window.innerHeight);
    const calculateInnerHeight = () => {
      innerHeight.value = window.innerHeight;
    };

    let filtersArray = ["any"];

    let appliedFilterNames = ref([]);

    let filteredSpecimensQty = ref(0);

    const filterSpecimensOnMapByYear = (year) => {
      if (!year) {
        filtersArray = ["any"];
        filterFetchedSpecimensByYear.value = null;
        store.state.mapboxMap.setFilter("fetchedSpecimensLayer", null);
        store.state.mapboxMap.setFilter("fetchedSpecimensSoverdiIconLayer", [
          "has",
          "soverdiCircle",
        ]);
        //  showFetchedSpecimensBySpecies.value = false;
        currentFilter.value = null;
        appliedFilterNames.value = [];
        filteredSpecimensQty.value = 0;
        mapService.addCollaborativeIcons();
        mapService.fitBoundsToSelection(
          store.state.fetchedSpecimensAsFeatureCollection
        );
        return;
      }
      filterFetchedSpecimensByYear.value = {
        year: year.year,
        amount: year.amount,
      };
      filtersArray.push(["==", ["get", "yearPlanted"], year.year]);
      appliedFilterNames.value.push(year.year);
      filteredSpecimensQty.value += year.amount;
      console.log(filteredSpecimensQty);
      store.state.mapboxMap.setFilter("fetchedSpecimensLayer", filtersArray);
      store.state.mapboxMap.setFilter("fetchedSpecimensSoverdiIconLayer", [
        "all",
        filtersArray,
        ["has", "soverdiCircle"],
      ]);
      // showFetchedSpecimensBySpecies.value = false;

      setTimeout(() => {
        const features = store.state.mapboxMap.querySourceFeatures(
          "fetchedSpecimens",
          { filter: filtersArray }
        );
        function getUniqueFeatures(features, comparatorProperty) {
          const uniqueIds = new Set();
          const uniqueFeatures = [];
          for (const feature of features) {
            const id = feature.properties[comparatorProperty];
            if (!uniqueIds.has(id)) {
              uniqueIds.add(id);
              uniqueFeatures.push(feature);
            }
          }
          return uniqueFeatures;
        }
        if (features) {
          const uniqueFeatures = getUniqueFeatures(features, "_id");
          console.log(uniqueFeatures);
          // check if one of the remaining feature has collaborative data
          const foundMatches = [];
          uniqueFeatures.forEach((feature) => {
            store.state.collaborativeDataQueryObj.forEach(
              (collaborativeData) => {
                if (feature.properties._id == collaborativeData.specimen_id) {
                  foundMatches.push(collaborativeData);
                }
              }
            );
          });
          // put collaborativeIcons back on map
          mapService.addCollaborativeIcons(foundMatches);
          const renderedFeaturesOnMap =
            store.state.mapboxMap.queryRenderedFeatures({
              layers: ["fetchedSpecimensLayer"],
            });
          console.log(renderedFeaturesOnMap);
          mapService.fitBoundsToSelection({
            type: "FeatureCollection",
            features: renderedFeaturesOnMap,
          });
        }
      }, 5);
    };

    const filterSpecimensOnMapBySpecies = (species) => {
      //console.log(species);
      if (!species) {
        filtersArray = ["any"];
        filterFetchedSpecimensBySpecies.value = null;
        store.state.mapboxMap.setFilter("fetchedSpecimensLayer", null);
        store.state.mapboxMap.setFilter("fetchedSpecimensSoverdiIconLayer", [
          "has",
          "soverdiCircle",
        ]);
        //  showFetchedSpecimensBySpecies.value = false;
        currentFilter.value = null;
        appliedFilterNames.value = [];
        filteredSpecimensQty.value = 0;
        mapService.addCollaborativeIcons();
        return;
      }
      filterFetchedSpecimensBySpecies.value = {
        nameFr: species.nameFr,
        amount: species.amount,
      };
      appliedFilterNames.value.push(species.nameFr);
      filteredSpecimensQty.value += species.amount;
      if (species.code.length > 2) {
        filtersArray.push([
          "==",
          ["get", "species", ["get", "classification"]],
          species.code,
        ]);
        console.log(filtersArray);
        // use 4 digits species code
        store.state.mapboxMap.setFilter("fetchedSpecimensLayer", filtersArray);
        store.state.mapboxMap.setFilter("fetchedSpecimensSoverdiIconLayer", [
          "all",
          filtersArray,
          ["has", "soverdiCircle"],
        ]);
      } else {
        filtersArray.push(["==", ["get", "essence"], species.code]);
        // use 2 digits genus code
        store.state.mapboxMap.setFilter("fetchedSpecimensLayer", filtersArray);
        store.state.mapboxMap.setFilter("fetchedSpecimensSoverdiIconLayer", [
          "all",
          filtersArray,
          ["has", "soverdiCircle"],
        ]);
      }
      //  showFetchedSpecimensBySpecies.value = false;
      setTimeout(() => {
        const features = store.state.mapboxMap.querySourceFeatures(
          "fetchedSpecimens",
          { filter: ["==", ["get", "essence"], species.code] }
        );
        function getUniqueFeatures(features, comparatorProperty) {
          const uniqueIds = new Set();
          const uniqueFeatures = [];
          for (const feature of features) {
            const id = feature.properties[comparatorProperty];
            if (!uniqueIds.has(id)) {
              uniqueIds.add(id);
              uniqueFeatures.push(feature);
            }
          }
          return uniqueFeatures;
        }
        if (features) {
          const uniqueFeatures = getUniqueFeatures(features, "_id");
          console.log(uniqueFeatures);
          // check if one of the remaining feature has collaborative data
          const foundMatches = [];
          uniqueFeatures.forEach((feature) => {
            store.state.collaborativeDataQueryObj.forEach(
              (collaborativeData) => {
                if (feature.properties._id == collaborativeData.specimen_id) {
                  foundMatches.push(collaborativeData);
                }
              }
            );
          });
          // put collaborativeIcons back on map
          mapService.addCollaborativeIcons(foundMatches);
        }
      }, 5);
    };

    let sideMenuRef;
    let mainContainerRef;
    let mapContainerRef;
    let mapInSplitPaneRef;
    let step;
    let delta;
    let fadeInAnimation;
    let orientationHandler;
    let myButton;
    const prefersDark = ref(false);
    // for testing purposes
    // store.state.setDoNotShowGuidedTour('false');

    onMounted(() => {

      // Use matchMedia to check the user preference
      prefersDark.value = window.matchMedia("(prefers-color-scheme: dark)").matches;
      async function openMenu() {
        //console.log("opening clé");
        await menuController.open();
      }
      openMenu();
      let initialStep = 0;
      let started = false;
      sideMenuRef = document.querySelector(".modal-content-specimen-details");
      mainContainerRef = document.querySelector(".main-container-selector");
      mapContainerRef = document.querySelector(".map-container");
      store.commit("setSideMenuRef", sideMenuRef);
      const MAX_TRANSLATE = -1200;
      const clamp = (min, n, max) => {
        return Math.max(min, Math.min(n, max));
      };
      let getStep = (ev) => {
        delta = initialStep + ev.deltaX;
        //console.log("step: " + clamp(0, delta / MAX_TRANSLATE, 1));
        return clamp(0, delta / MAX_TRANSLATE, 1);
      };
      fadeInAnimation = createAnimation()
        .addElement(mapContainerRef)
        .duration(1000)
        .fromTo("opacity", "0", "1");

      store.commit("setMapFadeInAnimation", fadeInAnimation);
      const slideOutAnimation = createAnimation()
        .addElement(sideMenuRef)
        .duration(5000)
        .fromTo("opacity", "1", "0");
      //  .fromTo("transform", "translateY(0)", `translateY(${MAX_TRANSLATE}px)`);
      store.state.slideOutAnimation = slideOutAnimation;

      const checkDoNotShowGuidedTour = async () => {
        const { value } = await Preferences.get({ key: 'doNotShowGuidedTour' });
/*         alert(value);
 */        
        const checkedValue = value ? value  : 'false';
        store.state.setLocalPreferences('doNotShowGuidedTour', checkedValue);
        store.commit("setDoNotShowGuidedTourStatusFromLocalPreferences", checkedValue);
        console.log(`Hello ${checkedValue}!`);
        if (store.state.doNotShowGuidedTourStatusFromLocalPreferences == "false") {
          console.log("showing guided tour");
          const { callFetchSpecimens, guidedTour } = useGuidedTour();
          guidedTour();
          watch(callFetchSpecimens, ()=>{
            console.log("watch!");
            fetchSpecimens("fitBounds");
          })
        }
      };
      checkDoNotShowGuidedTour();
    });
    const returnBreakpointsForSpecimenModal = () => {
      if (store.state.userGroupMembership == "omhm") {
        return [0.94];
      } else {
        return [
          0,
          store.state.initialBreakpoint,
          store.state.secondaryBreakpoint,
          0.94,
        ];
      }
    };
    const returnInitialBreakpointsForSpecimenModal = () => {
      if (store.state.userGroupMembership == "omhm") {
        return 0.94;
      } else {
        return store.state.initialBreakpoint;
      }
    };
    const displayMapMenu = () => {
      calculateInnerHeight();
      if (!store.state.showMapMenu) {
        closeAllModals();
        store.state.showMapMenu = true;
        store.state.showSpeciesList = false;
        store.state.showQueryFilters = false;
      } else {
        store.state.showMapMenu = false;
      }
    };
    const displayQueryFilters = () => {
      if (!store.state.showQueryFilters) {
        closeAllModals();
        store.state.showQueryFilters = true;
        store.state.showMapMenu = false;
      } else {
        store.state.showSpeciesList = false;
        store.state.showQueryFilters = false;
      }
    };
    const showNewSpecimenModal = (options) => {
      function moveendCallback() {
        if (store.state.userGroupMembership == "omhm") {
          store.commit("setShowNewSpecimenModal", true);
          store.dispatch("formatNewSpecimen").then(function () {
            store.state.openNewSpecimenDetailsForm();
            // store.state.relativeToContent();
            store.state.breakPointRelativeToContent = 0.95;
          });
        } else {
          store.commit("setShowNewSpecimenModal", true);
          store.commit("setShowRefreshMap", false);
          store.commit("setActivatedTabQuerySelector", ".add-specimen");
        }
        store.state.mapboxMap.off("moveend", moveendCallback);
      }
      if (store.state.showNewSpecimenModal) {
        //console.log("showNewSpecimenModal");
        store.commit("setShowNewSpecimenModal", false);
        //      store.commit("setShowNearestSpecimenModal", false);
        store.commit("setShowRefreshMap", false);
        store.commit("setActivatedTabQuerySelector", ".add-specimen");
        //   mapService.clearGetCurrentPositionSetInterval();
      } else {
        store.state.deactivateCompassMode();
        store.state.showMapMenu = false;
        store.state.showSpeciesList = false;
        store.state.showQueryFilters = false;
        store.commit("setShowNearestSpecimenModal", false);
        const modalRef = document.querySelector(
          ".modal-content-specimen-details"
        );
        //console.log(modalRef);
        modalRef.setCurrentBreakpoint(0.001);
        //console.log("trying to get positiomn");
        // store.commit("setShowTryingToAcquirePosition", true);
        //   mapService.enableWatchPosition("centerMap");
        store.state.mapboxMap.flyTo({
          center: store.state.mapCenter,
          zoom:
            store.state.mapboxMap.getZoom() < 18
              ? 18
              : store.state.mapboxMap.getZoom(),
          offset:
            store.state.userGroupMembership == "omhm" ? [187, 0] : [0, -150],
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
        store.state.mapboxMap.on("moveend", moveendCallback);
      }
    };
    const compassCallback = () => {
      console.log('compass callback');
      store.state.showCompassClickEventHandler();
    };
    const showNearestSpecimen = () => {
      if (!store.state.currentPosition) {
        alert(
          "Votre position n'a pas encore été déterminée.  Veuillez réessayer plus tard svp."
        );
        return;
      }
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
      //console.log(modalRef);
      modalRef.setCurrentBreakpoint(0.001);
      // centermap on current user location
      store.commit("setMapCenter", [
        store.state.currentPosition.coords.longitude,
        store.state.currentPosition.coords.latitude,
      ]);
      setTimeout(() => {
        store.state.mapboxMap.flyTo({
          bearing: 0,
          center: store.state.mapCenter,
          zoom:
            store.state.mapboxMap.getZoom() < 19
              ? 19
              : store.state.mapboxMap.getZoom(),
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }, 20);
      // toggle
      if (!store.state.showNearestSpecimenModal) {
        store.commit("setShowNearestSpecimenModal", true);
        showNearestSpecimenActivatedToast.value = true;
        showCompassActivatedToast.value = false;
        showCompassDeactivatedToast.value = false;
        //     mapService.enableWatchPosition("centerMap");
        console.log("resize map!");
        store.state.mapboxMap.resize();
        store.state.mapboxMap.easeTo({
          bearing: 0,
        });
        if (!store.state.orientationHandler) {
          // activate orientation listener to rotate position arrow
          myButton = document.getElementById("nearest-specimen");
          myButton.addEventListener("click", compassCallback());
        }
      } else if (!store.state.showNewSpecimenModal) {
        // toggle compass
        store.state.showCompass = true;
        showCompassActivatedToast.value = true;
        showNearestSpecimenActivatedToast.value = false;
        showCompassDeactivatedToast.value = false;
        // tilt camera
        store.state.mapboxMap.jumpTo({
          pitch: 60,
        });
      }
      // resize map
      /*  setTimeout(() => {
       //console.log("resizing map");
         store.state.mapboxMap.resize();
       }, 200) */
      /* 
      // handle button color
      if (store.state.showNearestSpecimenModal) {
        handleTabButtonsColor(".show-nearest-specimen", true);
      } else {
        handleTabButtonsColor(".show-nearest-specimen", false);
      } */
    };
    const deactivateNearestSpecimen = () => {
      showCompassDeactivatedToast.value = true;
      showNearestSpecimenActivatedToast.value = false;
      showCompassActivatedToast.value = false;
      //remove listeners
      store.state.deactivateCompassMode();
      store.commit("setShowNearestSpecimenModal", false);
      //console.log(store.state.showNearestSpecimenModal);
      console.log("resize map deactivate nearest");
      store.state.mapboxMap.resize();
      /* setTimeout(() => {
        //console.log("resizing map");
        store.state.mapboxMap.resize();
      }, 20); */
    };

    const fetchSpecimens = (fitBounds) => {
      function proceedWithFetching() {
        setTimeout(() => {
          // for some reason the map blinks back to day mode without a 1ms timeout here
          var radiusCircleLayer =
            store.state.mapboxMap.getLayer("radiusCircleLayer");
          if (typeof radiusCircleLayer !== "undefined") {
            store.state.mapboxMap.removeLayer("radiusCircleLayer");
          }
          var radiusCircleSourc =
            store.state.mapboxMap.getSource("radiusCircleSource");
          if (typeof radiusCircleSourc !== "undefined") {
            store.state.mapboxMap.removeSource("radiusCircleSource");
          }
        }, 1);
        // store.state.showSelectedFeaturedProject = false;
        store.commit("setChosenSpecimenMarkerFirestoreId", null);
        store.commit("setShowRefreshMap", true);
        // store.commit("setSideMenuDisabled", true);
        store.commit("setShowNewSpecimenModal", false);
        store.commit("setShowNearestSpecimenModal", false);
        store.commit("setActivatedTabQuerySelector", ".fetch-specimens");
        store.commit("setFormattedSpecimen", null);
        store.commit("setBreakPointRelativeToContent", 0.001);
        closeAllModals();
        //   mapService.clearGetCurrentPositionSetInterval();
        if (fitBounds == "fitBounds" && !store.state.showNearestSpecimenModal) {
          store.state.disableFitBounds = false;
        }
        var specimensLoaded = firebaseService().loadSpecimens();
        specimensLoaded.then(function (res) {
          console.log("SPECIMENS LOADED");
          console.log(res);
          store.commit(
            "setFetchedSpecimens",
            firebaseService().specimenMarkers
          );
          //    store.state.disableFitBounds = false;
        });
      }
      if (!store.state.currentPosition) {
        //console.log("no coords");
        mapService.getCurrentPosition({ centerMap: true }).then(() => {
          function moveendCallback() {
            console.log("A moveend event occurred!!");
            mapService.animateUserLocationOnMap();
            proceedWithFetching();
            store.state.mapboxMap.off("moveend", moveendCallback);
          }
          store.state.mapboxMap.on("moveend", moveendCallback);
        });
      } else {
        //console.log("coords present");
        //console.log(store.state.currentPosition);
        proceedWithFetching();
      }
    };
    const modalDismissed = (modalName) => {
      if (modalName === "newSpecimenModal") {
        //console.log("dismissing newSpecimenModal");
        store.commit("setShowNewSpecimenModal", false);
        document
          .querySelector(".add-specimen")
          .style.setProperty("--color", "#666666");
        if (!store.state.showNearestSpecimenModal) {
          document
            .querySelector(".fetch-specimens")
            .style.setProperty("--color", "#00967d");
        }
      } else if (modalName === "nearestSpecimenModal") {
        //console.log("dismissing nearestSpecimenModal");
        store.commit("setShowNearestSpecimenModal", false);
        document
          .querySelector(".show-nearest-specimen")
          .style.setProperty("--color", "#666666");
        if (!store.state.showNewSpecimenModal) {
          document
            .querySelector(".fetch-specimens")
            .style.setProperty("--color", "#00967d");
        }
      } else {
        //console.log("specimen modal dismissed");
        store.commit("setCurrentSegment", null);
      }
    };
    let modalBreakpoints = store.state.calculateSheetModalBreakpoints();
    const initialBreakpoint = store.state.initialBreakpoint;

    const limitQueryResultsTo = ref(0);
    const sideMenuDisabled = ref(false);

    const dealWithMapSize = () => {
      ////console.log("map size");
      setTimeout(() => {
        // console.log(store.state.currentProjectID);
        console.log("dealwithmapsize");
        store.state.mapboxMap.resize();
        if (store.state.sideMenuDisabled && store.state.currentProjectID) {
          //console.log("flyto");
          store.state.mapboxMap.flyTo({
            center:
              store.state.currentProjectID.absoluteCenter.geometry.coordinates,
            offset: [187, 0],
            speed: 0.5,
            zoom: 16,
            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          });
        }
      }, 20);
    };

    const { errors, loading, getFirebaseDbData } = firebaseService();

    getFirebaseDbData("allEssences", "setAllEssencesDictionary");
    getFirebaseDbData("publishedProjects", "setFeaturedProjects");
    getFirebaseDbData("publishedProjectsList", "setFeaturedProjectsList");

    const counter = computed(() => store.state.radius + 1);

    const closeAllModals = () => {
      // store.commit("setCurrentSegment", "specimen");
      store.state.showMapMenu = false;
      //  store.commit("setShowNearestSpecimenModal", false);
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
      modalRef.setCurrentBreakpoint(0.001);
      store.commit("setShowNewSpecimenModal", false);
      store.state.showSpeciesList = false;
      store.state.showQueryFilters = false;
      //  store.state.showNewSpecimenModal = false;
      //deactivateCompassMode();
      store.state.showEditProfile = false;
      store.state.showCreateProfile = false;
      store.state.showFetchedSpecimensStats = false;
    };

    const deselectMarkerIfNeeded = (ev) => {
      console.log("deselectMarkerIfNeeded");
      console.log(store.state.breakPointRelativeToContent);
      console.log(ev);
      const modalRef = document.querySelector(
        ".modal-content-specimen-details"
      );
      modalRef.getCurrentBreakpoint().then((res) => {
        console.log(res);
        if (res == 0.001) {
          mapService.deselectMarker();
        } else if (res > store.state.breakPointRelativeToContent) {
          store.state.relativeToContent(null, res);
        }
      });
    };

    // called when the map has finished loading
    const handleMapDidLoad = (map, string) => {
      //console.log("handleMapDidLoad");
      //console.log(string);
      //console.log("map", map);
      mapLoaded.value = true;
      fadeInAnimation.delay(100).play();
      mapService.enableWatchPosition();

      // add confirm on mapbox button attribution before navigating to mapbox.com
      // const mapboxAttributionButton = document.getElementsByClassName('mapboxgl-ctrl');
      const mapboxAttributionButton = document.querySelector(
        ".mapboxgl-ctrl-logo"
      );
      //console.log(mapboxAttributionButton);
      function confirmNavigateToMapbox(event) {
        //console.log(mapboxAttributionButton);
        //console.log(event);
        //   if (event.cancelable) {
        event.preventDefault();
        //    }
        if (
          window.confirm(
            "Souhaitez-vous être redirigés vers le site de Mapbox?"
          )
        ) {
          window.open("https://www.mapbox.com");
        }
      }
      mapboxAttributionButton.addEventListener(
        "click",
        confirmNavigateToMapbox
      );
      /* // add listener to put specimens back on map on style change
      map.on("style.load", () => {
        mapService.putSpecimensOnMap(
          store.state.fetchedSpecimensAsFeatureCollection,
          false,
          true,
          store.state.treeIconsOptions
        );
      }); */
    };

    return {
      handleMapDidLoad,
      errors,
      loading,
      personOutline,
      personCircle,
      searchOutline,
      ellipsisHorizontal,
      limitQueryResultsTo,
      settings,
      counter,
      sideMenuDisabled,
      locate,
      locateOutline,
      modalDismissed,
      pageEl,
      splitPaneHeight,
      sideMenuRef,
      dealWithMapSize,
      refreshOutline,
      fetchSpecimens,
      showNewSpecimenModal,
      showNearestSpecimen,
      ellipsisVertical,
      modalBreakpoints,
      initialBreakpoint,
      addCircleOutline,
      returnInitialBreakpointsForSpecimenModal,
      returnBreakpointsForSpecimenModal,
      innerHeight,
      calculateInnerHeight,
      closeAllModals,
      filterOutline,
      calendarOutline,
      leaf,
      add,
      isPlatform,
      getPlatforms,
      close,
      closeCircle,
      deactivateNearestSpecimen,
      compass,
      compassOutline,
      showFetchedSpecimensBySpecies,
      filterFetchedSpecimensBySpecies,
      filterFetchedSpecimensByYear,
      filterSpecimensOnMapBySpecies,
      filterSpecimensOnMapByYear,
      chevronForward,
      chevronDown,
      removeSelectedQueryFilter,
      currentBreakpoint,
      cappedBreakpoints,
      modalDismissedTest,
      displayMapMenu,
      CleAddNewSpecimen,
      BarChart,
      PieChart,
      DetailProjetVedette,
      deselectMarkerIfNeeded,
      currentFilter,
      appliedFilterNames,
      filteredSpecimensQty,
      showFetchedSpecimensByYear,
      mapLoaded,
      showNearestSpecimenActivatedToast,
      showCompassActivatedToast,
      showCompassDeactivatedToast,
      displayQueryFilters,
      specimensQty,
      imageLoadOnError,
      prefersDark,
      // access a getter in computed function
      // double: computed(() => store.getters.double)
    };
  },
});
</script>
<style lang="css" scoped></style>
