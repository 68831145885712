import { ref } from "vue";

import {
  doc,
  getDoc,
  onSnapshot,
  query as firestoreQuery,
  collection,
  collectionGroup,
  orderBy,
  startAt,
  endAt,
  getDocs,
  where,
  limit,
  getFirestore
} from "firebase/firestore";
import * as geofire from "geofire-common";
import store from "../store";
import arbresIcons from "../constants/arbresIcons";
import {
  indexedDBLocalPersistence,
  initializeAuth,
  getAuth,
} from "firebase/auth";
import {
  getDatabase,
  ref as rtdbRef,
  onValue,
  get,
  orderByChild,
  equalTo,
  query,
} from "firebase/database";
import { Capacitor } from "@capacitor/core";
import mapService from "./mapService";
import specimensColor from "./specimensColor";
import { featureCollection, geojsonType, nearestPoint } from "@turf/turf";

console.log("firebaseService");

/* function whichAuth() {
  let auth;
  if (Capacitor.isNativePlatform()) {
    console.log("capacitor is native platform in firebaseService");
    //console.log(store.state.firebaseApp);
    //console.log(store.state.firebaseApp);
    auth = initializeAuth(store.state.firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
  } else {
    console.log("capacitor is NOT native platform");

    auth = getAuth();
  }
  return auth;
}
setTimeout(()=>{
  console.log("before init auth");
  const auth = whichAuth();
  console.log(auth);
},2000) */

/* firebasedb.ref('allEssences').on('value', (snapshot) => {
    store.commit("setAllEssencesDictionary", snapshot.val());
    var array = [];
    Object.keys(snapshot.val()).forEach(function (keyName) {
        const item = snapshot.val()[keyName];
        if (item.fr.name.split(" ").length === 1) {
            // because of an autocomplete bug, we must not have a single word option come first in array, otherwise it will trigger autocomplete event when pressing spacebar
            item.fr.name =
                item.fr.name + " ";
        }
        item.code = keyName;
        array.push(item);
    });
    store.commit("setAllEssencesDictionaryAsArray", array);
}); */
console.log("before getting firestore db");
const firebasedb = getDatabase();
let geocollection;
let queryObj = null;
let specimenMarkers = [];
const errors = ref([]);
const loading = ref(false);

export default () => {
  const listenToFirebaseDbData = (path, storeMutationName) => {
    const currentRef = rtdbRef(firebasedb, path);
    onValue(currentRef, (snapshot) => {
      //console.log("onValue " + path + " firebase event triggered");
      ////console.log(snapshot.val());
      const fetchedProjectId = snapshot.val();
    });
  };
  const getFirebaseDbData = async (
    rtdbPath,
    storeMutationName,
    filterValue
  ) => {
    console.log(rtdbPath);
    console.log(storeMutationName);
    const currentRef = rtdbRef(firebasedb, rtdbPath);
    if (rtdbPath == "allEssences" || rtdbPath == "publishedProjects" || rtdbPath == "publishedProjectsList") {
      onValue(currentRef, (snapshot) => {
        console.log("onvalue!");
        store.commit(storeMutationName, snapshot.val());
        if (rtdbPath == "allEssences") {
          var array = [];
          Object.keys(snapshot.val()).forEach(function (keyName) {
            const item = snapshot.val()[keyName];
            /*  if (item.fr.name.split(" ").length === 1) {
              // because of an autocomplete bug, we must not have a single word option come first in array, otherwise it will trigger autocomplete event when pressing spacebar
              item.fr.name = item.fr.name + " ";
            } */
            item.code = keyName;
            array.push(item);
          });
          store.commit("setAllEssencesDictionaryAsArray", array);
        } else if (rtdbPath == "publishedProjectsList") {
          // add map events
          mapService.addMapEvents();
        }
      });
    } else {
      //   const dbRef = ref(getDatabase());
      console.log(filterValue);
      let currQuery;
      if (filterValue) {
        currQuery = query(
          currentRef,
          orderByChild("tags/" + filterValue),
          equalTo(filterValue)
        );
      } else {
        currQuery = query(currentRef);
      }
      await get(currQuery)
        .then((snapshot) => {
          if (snapshot.exists()) {
            console.log(snapshot.val());
            store.commit(storeMutationName, snapshot.val());
            /* if (rtdbPath == "allEssences") {
                        var array = [];
                        Object.keys(snapshot.val()).forEach(function (keyName) {
                            const item = snapshot.val()[keyName];
                            if (item.fr.name.split(" ").length === 1) {
                                // because of an autocomplete bug, we must not have a single word option come first in array, otherwise it will trigger autocomplete event when pressing spacebar
                                item.fr.name =
                                    item.fr.name + " ";
                            }
                            item.code = keyName;
                            array.push(item);
                        });
                        store.commit("setAllEssencesDictionaryAsArray", array);
                    } */
          } else {
            store.commit(storeMutationName, null);

            ////console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    /* firebasedb.ref(firebaseDbRef).on('value', (snapshot) => {
            store.commit(storeMutationName, snapshot.val());
            if (firebaseDbRef == "allEssences") {
                var array = [];
                Object.keys(snapshot.val()).forEach(function (keyName) {
                    const item = snapshot.val()[keyName];
                    if (item.fr.name.split(" ").length === 1) {
                        // because of an autocomplete bug, we must not have a single word option come first in array, otherwise it will trigger autocomplete event when pressing spacebar
                        item.fr.name =
                            item.fr.name + " ";
                    }
                    item.code = keyName;
                    array.push(item);
                });
                store.commit("setAllEssencesDictionaryAsArray", array);
            }
        }); */
  };
  const getCollectionData = async (queryOptions) => {
    console.log("GETCOLLECTIONDATA STARTED");
    const applyRadiusAndFiltersAndLimit = async (chosenCollectionToQuery) => {
      const docsToReturn = [];
      const filtersForQuery = [];
      console.log("applyRadiusAndFiltersAndLimit STARTED");
      // check if this is a query without radius and too few filters
      if (
        !queryOptions.radius &&
        queryOptions.filters &&
        queryOptions.filters.length < 2 &&
        store.state.radius != "deactivated" &&
        queryOptions.filters[0] &&
        queryOptions.filters[0].displayName != "projet"
      ) {
        // no radius limitatiion on query, make sure that more than two filters are applied in order to prevent returning too many trees
        alert(
          "Lorsque le rayon est désactivé, vous devez avoir au minimum 2 filtres sur vos requêtes pour éviter de retourner trop de spécimens d'un coup.  Votre requête actuelle en contient " +
          queryOptions.filters.length +
          "."
        );
      } else {
        console.log(queryOptions);
        if (queryOptions.filters && queryOptions.filters.length > 0) {
          console.log("adding filters to query");
          queryOptions.filters.forEach((filter) => {
            /* if (filter.fieldName.indexOf("owning_group") > -1) {
              // do not add to query because the query will only target the group specimen collection anyway
              return;
            } */
            if (
              chosenCollectionToQuery._query.collectionGroup ==
              "collaborativeSpecimenUIDs" &&
              (!filter.collection ||
                filter.collection.indexOf("collaborative") == -1)
            ) {
              // do not add to query because its not going to be applied to the good collection
              return;
            }
            // validate enteredValue's type
            if (filter.type == "number") {
              filter.enteredValue = parseInt(filter.enteredValue);
            }
            // add filters to array
            const filterForQuery = where(
              filter.fieldName,
              filter.operator,
              filter.enteredValue
            );
            filtersForQuery.push(filterForQuery);

            /* if (store.state.collaborativeDataQueryObj) {
              // do not add any filters except for the radius and limit if set
              console.log("not adding filters");
            } else {
              // when collaborativeCollectionQuery is enabled, remove specimens collection filters from query, they will be applied locally once we get the results from the collaborative collection
              if (collaborativeCollectionQuery) {
                if (filter.collection) {
                  queryObj = queryObj.where(
                    filter.fieldName,
                    filter.operator,
                    filter.enteredValue
                  );
                }
              } else {
                queryObj = queryObj.where(
                  filter.fieldName,
                  filter.operator,
                  filter.enteredValue
                );
              }
            } */
          });
        }
        if (queryOptions.limit > 0) {
          queryObj = queryObj.limit(parseInt(queryOptions.limit));
        }
        // add radius and create geoquery
        if (queryOptions.radius && store.state.radius != "deactivated") {
          let radiusCenter = {};
          // check if specific coords were provided 
          if (queryOptions.filters[0] && queryOptions.filters[0].coordsForRadius) {
            radiusCenter.lat = queryOptions.filters[0].coordsForRadius[1];
            radiusCenter.lng = queryOptions.filters[0].coordsForRadius[0];
          } else {
            console.log("using mapcenter for query");
            // create geoquery based on map center
            radiusCenter = store.state.mapboxMap.getCenter();
          }
          const bounds = geofire.geohashQueryBounds(
            [radiusCenter.lat, radiusCenter.lng],
            parseInt(queryOptions.radius)
          );
          const promises = [];
          for (const b of bounds) {
            let q = query(
              chosenCollectionToQuery,
              ...filtersForQuery,
              orderBy("g.geohash"),
              startAt(b[0]),
              endAt(b[1])
            );
            promises.push(getDocs(q));
          }

          // Collect all the query results together into a single list
          const snapshots = await Promise.all(promises);

          const matchingDocs = [];
          for (const snap of snapshots) {
            for (const doc of snap.docs) {
              const fetchedData = doc.data();
              const lat = fetchedData.g.geopoint.latitude;
              const lng = fetchedData.g.geopoint.longitude;
              // We have to filter out a few false positives due to GeoHash
              // accuracy, but most will match
              const distanceInKm = geofire.distanceBetween(
                [lat, lng],
                [radiusCenter.lat, radiusCenter.lng]
              );
              const distanceInM = distanceInKm * 1000;
              if (distanceInM <= parseInt(queryOptions.radius)) {
                matchingDocs.push(doc);
              }
            }
          }
          matchingDocs.map((doc) => {
            //console.log(doc.data());
            docsToReturn.push({ ...doc.data(), firestoreId: doc.id });
          });
          // save last geoquery coordinates for distance calculations
          store.commit("setLastGeoqueryCoordinatesLngLat", [
            radiusCenter.lng,
            radiusCenter.lat,
          ]);
        } else {
          // just make a query
          const returnQuery = () => {
            if (chosenCollectionToQuery._query.collectionGroup ==
              "collaborativeSpecimenUIDs") {
              return query(
                collectionGroup(store.state.firestoreDb, "collaborativeSpecimenUIDs"),
                ...filtersForQuery,
              )
            } else if (chosenCollectionToQuery._query.collectionGroup ==
              "specimens") {
              return query(
                collectionGroup(store.state.firestoreDb, "specimens"),
                ...filtersForQuery
              );
            } else if (queryOptions.collectionName == "cleIdentificationCategories") {
              return query(
                collection(store.state.firestoreDb, "cleIdentificationCategories")
              );
            }
          };
          const querySnapshot = await getDocs(returnQuery());
          querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());
            docsToReturn.push({ ...doc.data(), firestoreId: doc.id });
          });
        }

        try {
          /*    console.log("before queryObj.get");
          const querySnapshot = await queryObj.get();
          console.log("after queryObj.get test");
          return querySnapshot.docs.map((doc) => {
            console.log(doc.data());
            return { ...doc.data(), firestoreId: doc.id };
          }); */
          return docsToReturn;
        } catch (e) {
          console.log(e);
          errors.value.push(e);
        }
      }
    };
    console.log(JSON.stringify(queryOptions));
    let groupSpecificCollectionQuery = false;
    let collaborativeCollectionQuery = false;
    const specimensCollectionCurrentFilters = [];
    store.state.collaborativeDataQueryObj = [];
    if (queryOptions.filters) {
      queryOptions.filters.forEach((filter) => {
        // check if groupSpecific is selected as a filter
        if (
          filter.fieldName.indexOf("owning_group") > -1 ||
          (filter.fieldName.indexOf("groupSpecific") > -1 &&
            store.state.userGroupMembership == "omhm")
        ) {
          //  groupSpecificCollectionQuery = true;
          groupSpecificCollectionQuery = false;
        } else if (
          filter.collection &&
          filter.collection.indexOf("collaborative") > -1
        ) {
          collaborativeCollectionQuery = true;
        } else {
          specimensCollectionCurrentFilters.push(filter);
        }
      });
    }
    if (
      queryOptions.collectionName == "specimens" ||
      queryOptions.collectionName == "collaborative"
    ) {
      // check for collaborative data in order to be able to add collaborative icons onto displayed trees
      let collaborativeSpecimenUIDsCollectionToQuery = collectionGroup(
        store.state.firestoreDb,
        "collaborativeSpecimenUIDs"
      );
      store.state.collaborativeDataQueryObj =
        await applyRadiusAndFiltersAndLimit(
          collaborativeSpecimenUIDsCollectionToQuery
        );
      console.log("store.state.collaborativeDataQueryObj");
      console.log(store.state.collaborativeDataQueryObj);
    }
    const specimensToReturn = [];
    if (collaborativeCollectionQuery) {
      // fetch trees that are associated with collaborative messages
      const specimensToFetchPromises = [];
      const checkForDupes = { idList: {} };
      store.state.collaborativeDataQueryObj.forEach(
        async (collaborativeDoc) => {
          // make sure we only fetch a tree once, even if it has more than one collaborative document
          console.log(collaborativeDoc.specimen_uid);
          console.log(checkForDupes.idList);
          if (checkForDupes.idList[collaborativeDoc.specimen_uid] == "") {
            // do not fetch
          } else {
            checkForDupes.idList[collaborativeDoc.specimen_uid] = "";
            /*  let promise = getCollectionDoc({
          docName: collaborativeDoc.firestoreId,
          collectionName: "groups/soverdi/specimens",
        }); */

            let aquery = query(
              collectionGroup(store.state.firestoreDb, "specimens"),
              where(
                "geojsonFeature.properties._id",
                "==",
                collaborativeDoc.specimen_id
              )
            );
            let promise = getDocs(aquery);
            specimensToFetchPromises.push(promise);
            const fetchedSpecimens = await Promise.all(
              specimensToFetchPromises
            );
            console.log(fetchedSpecimens);
          }
        }
      );
      const fetchedSpecimens = await Promise.all(specimensToFetchPromises);
      console.log(fetchedSpecimens);
      fetchedSpecimens.forEach((response) => {
        if (response.empty) {
          console.log("missing tree id");
          console.log(response.query._query.filters[0].value);
          // search for collaborative data that doesnt have a tree
          let orphanCollaborativeData = null;
          store.state.collaborativeDataQueryObj.forEach((collaborativeData) => {
            if (collaborativeData.specimen_id == response.query._query.filters[0].value.stringValue) {
              orphanCollaborativeData = collaborativeData;
            }
          })
          // push empty tree so that the user can access its own collaborative data, even though the tree has been deleted
          var specimen = {
            _id: response.query._query.filters[0].value.stringValue,
            author_uid: store.state.user.uid,
            essence: "deleted",
            owner: store.state.userInfosToDisplay,
            photos: [],
            hasPhoto: false,
            plant_date: "",
            owning_group: store.state.userGroupMembership,
            groupSpecific: {
              [store.state.userGroupMembership]: {
                observations: {
                  healthStatus: {
                    current: {},
                  },
                  dhp: {
                    current: {},
                  },
                  leafVolume: {
                    current: {},
                  },
                },
                tasks: {},
                comments: "",
              },
            },
          };

          var geojsonFeature = {
            geometry: { coordinates: [orphanCollaborativeData.g.geopoint._long, orphanCollaborativeData.g.geopoint._lat], type: "Point" },
            properties: specimen,
          };
          store.state.fetchedSpecimensObj[orphanCollaborativeData.specimen_uid] = { g: orphanCollaborativeData.g, geojsonFeature: geojsonFeature, firestoreId: orphanCollaborativeData.specimen_uid };
          console.log(store.state.fetchedSpecimensObj[orphanCollaborativeData.specimen_uid]);
          specimensToReturn.push({ g: orphanCollaborativeData.g, geojsonFeature: geojsonFeature, firestoreId: orphanCollaborativeData.specimen_uid });
        }
        response.docs.map((doc) => {
          /*  if (doc.metadata.fromCache) {
                         alert("from cache " + doc.id)
                     } */
          //  //console.log("map each doc");
          /*  
        //console.log(doc.id); */
          console.log(doc.data());
          specimensToReturn.push({ ...doc.data(), firestoreId: doc.id });
        });
      });
      // check if a specimens collection filter was also set, if so filter selection locally
      specimensCollectionCurrentFilters.forEach((specimensCollectionFilter) => {
        console.log(specimensCollectionFilter);
        for (let i = specimensToReturn.length - 1; i >= 0; i -= 1) {
          let currentItem = specimensToReturn[i];
          if (currentItem.geojsonFeature.properties.essence == "deleted") {
            return
          }
          let specimenTemp = JSON.parse(JSON.stringify(currentItem));
          console.log(specimensCollectionFilter.fieldName.split("."));
          specimensCollectionFilter.fieldName
            .split(".")
            .forEach(function (val) {
              specimenTemp = specimenTemp[val];
            });
          if (specimenTemp != specimensCollectionFilter.enteredValue) {
            // remove from specimensToReturn
            specimensToReturn.splice(i, 1);
          }
        }
      });
      return specimensToReturn;
    } else {
      // fetch specimens
      let collectionToQuery;
      if (
        queryOptions.collectionName == "specimens" &&
        !groupSpecificCollectionQuery
      ) {
        // fetch from all specimens subcollections
        collectionToQuery = collectionGroup(store.state.firestoreDb, "specimens");
      } else if (
        queryOptions.collectionName == "specimens" &&
        groupSpecificCollectionQuery
      ) {
        // only query from groupSpecific collection which allows for more query filters if not using any range filter.
        collectionToQuery = collection(
          store.state.firestoreDb,
          "groups/" + store.state.userGroupMembership + "/specimens"
        );
      } else if (queryOptions.collectionName == "collaborative") {
        // fetch current user's collaborative contributions
        collectionToQuery = collection(
          store.state.firestoreDb,
          "collaborative/" + store.state.user.uid + "/collaborativeSpecimenUIDs"
        );
      } else {
        collectionToQuery = collection(store.state.firestoreDb, queryOptions.collectionName);
      }
      const specToreturn = await applyRadiusAndFiltersAndLimit(
        collectionToQuery
      );
      return specToreturn;
    }
  };

  const listenToCollectionDoc = (options) => {
    //console.log(JSON.stringify(options));
    const docSnap = onSnapshot(
      doc(store.state.firestoreDb, options.collectionName, options.docName),
      (doc) => {
        console.log("Current data: ", doc.data());
        if (!doc.data()) {
          store.commit("setUserStats", {
            reports: 0,
            likes: 0,
            likeTags: 0,
            identifiedSpecimens: 0,
          });
        } else {
          store.commit("setUserStats", doc.data());
        }
      },
      (error) => {
        //console.log(error);
      }
    );
    //console.log(docSnap);
  };

  const getCollectionDoc = async (options) => {
    console.log("trying to get collection doc");
    console.log(options);
    let docRef;
    try {
      if (options.docRef) {
        docRef = options.docRef;
      } else {
        docRef = doc(store.state.firestoreDb, options.collectionName, options.docName);
      }
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return { ...docSnap.data(), firestoreId: docSnap.id };
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    } catch (e) {
      //console.log(e);
      errors.value.push(e);
    }
  };
  /**
   * get the specimenObj from local array
   * @param {*} firestoreId
   * @returns
   */
  const getLocalSpecimenDetails = (firestoreId) => {
    return specimenMarkers.find((u) => u.firestoreId == firestoreId);
  };

  function returnTreeIcon(essenceCode) {
    ////console.log(essenceCode);
    let arbresIconsList = {};
    arbresIcons.arbresIcons().list.forEach((arbreIcon) => {
      arbresIconsList[arbreIcon.code] = arbreIcon.code;
    });

    var reducedEssenceCode = essenceCode;
    while (reducedEssenceCode.length) {
      //  var entry = dictionaries.allEssencesDictionary()[reducedEssenceCode];
      if (arbresIconsList[reducedEssenceCode]) {
        return reducedEssenceCode;
      } else {
        reducedEssenceCode = reducedEssenceCode.substr(
          0,
          reducedEssenceCode.length - 2
        );
      }
    }
    reducedEssenceCode = "XX";
    return reducedEssenceCode;
  }

  function returnCircleStrokeColor(specimenProp) {
    const owning_group = specimenProp.owning_group;
    ////console.log(owning_group);
    ////console.log(specimenProp.author_uid);
    // ////console.log(store.state.user.uid);
    if (store.state.userGroupMembership == owning_group) {
      if (
        owning_group == "singleUser" &&
        (!specimenProp.author_uid ||
          !store.state.user ||
          specimenProp.author_uid != store.state.user.uid)
      ) {
        return "black";
      } else {
        return "pink";
      }
    } else {
      return "black";
    }
  }

  const addMissingPropertiesToFetchedSpecimen = (specimen) => {
    specimen.geojsonFeature.properties.firestoreId = specimen.firestoreId;
    specimen.geojsonFeature.properties.treeIcon = returnTreeIcon(
      specimen.geojsonFeature.properties.essence
    );
    specimen.geojsonFeature.properties.iconColor =
      specimensColor.setSpecimenColor(specimen.geojsonFeature);
    specimen.geojsonFeature.properties.circleStrokeColor =
      returnCircleStrokeColor(specimen.geojsonFeature.properties);
    specimen.geojsonFeature.properties.yearPlanted =
      store.state.returnPlantationYear(specimen.geojsonFeature);
    specimen.geojsonFeature.properties.essenceName =
      mapService.setEssenceNameFromDict(specimen.geojsonFeature);
    if (specimen.geojsonFeature.properties.groupSpecific.soverdi) {
      specimen.geojsonFeature.properties.soverdiCircle = true;
    }
    generateSpeciesStats(specimen.geojsonFeature);
    generateGenusStats(specimen.geojsonFeature);
    generatePlantationYearStats(specimen.geojsonFeature);
    return specimen.geojsonFeature;
  };
  // console.log(store.state.fetchedSpecimensObj);
  /* if (!store.state.fetchedSpecimensObj || !store.state.queryOptions.addToDisplayedSpecimens) {
      //console.log("instanciating fetchedSpecimenObj");
        let fetchedSpecimensObj = {};
        let featureCollection = {
            type: "FeatureCollection",
            features: []
        }
    } */
  const checkForSameSpeciesInGivenRadius = async (speciesCode, radius) => {
    // check if another specimen of the same species is already in the user surrounding
    // only keep the first to letters of the speciesCode to check for genus
    const reducedSpeciesCode = speciesCode.slice(0, 2);
    if (!radius) {
      radius = 10;
    }
    //console.log("reducedSpeciesCode: " + reducedSpeciesCode.toUpperCase());
    const tempQueryOptions = {
      collectionName: "specimens",
      limit: 0,
      radius: radius,
      filters: [
        {
          displayName: "",
          displayValue: "",
          enteredValue: reducedSpeciesCode.toUpperCase(),
          fieldName: "geojsonFeature.properties.classification.genus",
          operator: "==",
          type: "string",
        },
      ],
    };
    const fetchedSpecimens = await getCollectionData(tempQueryOptions);
    //console.log(fetchedSpecimens);
    return fetchedSpecimens;
  };
  const fetchedSpecimensBySpecies = {};
  const fetchedSpecimensByPlantationDate = {};
  const fetchedSpecimensByGenus = {};

  const generateSpeciesStats = (specimen) => {
    // generate species stats
    let currentSpecies;
    if (specimen.properties.classification) {
      currentSpecies = specimen.properties.classification.species
        ? specimen.properties.classification.species
        : specimen.properties.classification.genus;
    } else {
      currentSpecies = specimen.properties.essence;
    }
    if (!fetchedSpecimensBySpecies[currentSpecies]) {
      fetchedSpecimensBySpecies[currentSpecies] = {};
      fetchedSpecimensBySpecies[currentSpecies].amount = 0;
    }
    fetchedSpecimensBySpecies[currentSpecies].nameFr = specimen.properties
      .essenceName
      ? specimen.properties.essenceName.fr.name
      : specimen.properties.essence;
    fetchedSpecimensBySpecies[currentSpecies].amount++;
    fetchedSpecimensBySpecies[currentSpecies].code = currentSpecies;
  };

  const generateGenusStats = (specimen) => {
    // generate species stats
    let currentGenus;
    if (specimen.properties.classification) {
      currentGenus = specimen.properties.classification.genus;
    } else {
      currentGenus = specimen.properties.essence;
    }
    if (!fetchedSpecimensByGenus[currentGenus]) {
      fetchedSpecimensByGenus[currentGenus] = {};
      fetchedSpecimensByGenus[currentGenus].amount = 0;
    }
    fetchedSpecimensByGenus[currentGenus].nameFr = specimen.properties
      .essenceName
      ? specimen.properties.essenceName.fr.name
      : specimen.properties.essence;
    fetchedSpecimensByGenus[currentGenus].amount++;
    fetchedSpecimensByGenus[currentGenus].code = currentGenus;
  };

  const generatePlantationYearStats = (specimen) => {
    // generate species stats
    //console.log("generate species stats");
    let currentPlantationYear;
    currentPlantationYear = store.state.returnPlantationYear(specimen);
    if (!currentPlantationYear) {
      currentPlantationYear = "année inconnue";
    }
    if (!fetchedSpecimensByPlantationDate[currentPlantationYear]) {
      fetchedSpecimensByPlantationDate[currentPlantationYear] = {};
      fetchedSpecimensByPlantationDate[currentPlantationYear].amount = 0;
    }
    fetchedSpecimensByPlantationDate[currentPlantationYear].year =
      currentPlantationYear;
    fetchedSpecimensByPlantationDate[currentPlantationYear].amount++;
  };

  const loadSpecimens = async (alreadyFetchedSpecimens) => {
    console.log("loading specimens");
    loading.value = true;
    try {
      //console.log(store.state.queryOptions.addToDisplayedSpecimens);
      store.state.fetchedSpecimensAsFeatureCollection.features = [];
      if (!store.state.queryOptions.addToDisplayedSpecimens) {
        // clear previously fetched specimens
        store.state.fetchedSpecimensObj = {};
      }
      let fetchedSpecimensObj = store.state.fetchedSpecimensObj;
      let featureCollectionForFetchedSpecimens =
        store.state.fetchedSpecimensAsFeatureCollection;
      //console.log("trying to load specimens");
      let fetchedSpecimens;
      if (alreadyFetchedSpecimens) {
        console.log("using already fetched specimens");
        fetchedSpecimens = alreadyFetchedSpecimens;
      } else {
        fetchedSpecimens = await getCollectionData(store.state.queryOptions);
      }
      console.log("GETCOLLECTIONDATA DONE");
      //console.log(fetchedSpecimens);
      //console.log(fetchedSpecimens.length);
      if (fetchedSpecimens.length == 0) {
        // alert("Aucun spécimen n'a été trouvé.");
        mapService.putSpecimensOnMap(
          {
            type: "FeatureCollection",
            features: [],
          },
          false,
          true,
          store.state.treeIconsOptions
        );
        return;
      }
      // const allEssencesDictionary = store.state.allEssencesDictionary;
      /* fetchedSpecimens.forEach((specimen) => {
        console.log(specimen.geojsonFeature.properties.essenceName);
        // format to put on map
        // eslint-disable-next-line no-prototype-builtins
        if (!specimen.hasOwnProperty("geojsonFeature")) {
          //console.log(JSON.stringify(specimen));
          alert("no geojsonFeature on specimen.  See log for details.");
        }
        if (!specimen.geojsonFeature.type) {
          // add property to make sure turf works as expected (it is doing validation on geojson format)
          specimen.geojsonFeature.type = "Feature";
        }
        fetchedSpecimensObj[specimen.firestoreId] = specimen;
      }); */
      store.commit("setFetchedSpecimensObj", fetchedSpecimensObj);

      //format as featureCollection
      var fetchedSpecimensProjectIDsListAsObject = {};
      var fetchedSpecimensProjectIDsListAsFeatureCollection = {
        type: "FeatureCollection",
        features: [],
      };
      const specimens = Object.keys(fetchedSpecimensObj);

      /*    if (store.state.userGroupMembership == "soverdi") {
        //console.log("group membership is soverdi");
        let fetchedProjectsCounter = 0;
        var fetchedSpecimensProjectsList = [];

        // format specimens and add to featureCollection and list all projects
        fetchedSpecimens.forEach((spec) => {
          // format to put on map
          // eslint-disable-next-line no-prototype-builtins
          if (!spec.hasOwnProperty("geojsonFeature")) {
            //console.log(JSON.stringify(specimen));
            alert("no geojsonFeature on specimen.  See log for details.");
          }
          if (!spec.geojsonFeature.type) {
            // add property to make sure turf works as expected (it is doing validation on geojson format)
            spec.geojsonFeature.type = "Feature";
          }
          fetchedSpecimensObj[spec.firestoreId] = spec;

          //console.log(keyname);
          const tempSpecimen = spec;
          const specimen = addMissingPropertiesToFetchedSpecimen(tempSpecimen);

          // check specimen's project
          if (
            specimen.properties.groupSpecific[
              store.state.userGroupMembership
            ] &&
            specimen.properties.groupSpecific[store.state.userGroupMembership]
              .plantation &&
            fetchedSpecimensProjectsList.indexOf(
              specimen.properties.groupSpecific[store.state.userGroupMembership]
                .plantation.project
            ) == -1
          ) {
            fetchedSpecimensProjectsList.push(
              specimen.properties.groupSpecific[store.state.userGroupMembership]
                .plantation.project
            );
          }
          const specimenOwningGroup = Object.keys(
            specimen.properties.groupSpecific
          )[0];
          featureCollectionForFetchedSpecimens.features.push(specimen);
        });
        if (fetchedSpecimensProjectsList.length) {
          //console.log("before fetching projectslist");

          // clear previous polygons
          mapService.getProjectLayersIDs().forEach(function (layerID) {
            if (store.state.mapboxMap.getLayer(layerID)) {
              store.state.mapboxMap.removeLayer(layerID);
            }
            if (store.state.mapboxMap.getSource(layerID)) {
              store.state.mapboxMap.removeSource(layerID);
            }
          });
          fetchedSpecimensProjectsList.forEach(function (projectName, i) {
            // fetch and listen to projectIds
            //console.log("fetch and listen to projectIds");
            //console.log(store.state.projectsList[projectName]);
            if (
              projectName &&
              projectName.indexOf("Un arbre pour mon quartier") == -1
            ) {
              const firebasedb = getDatabase();
              const currentRef = rtdbRef(
                firebasedb,
                "projectIDs/" + projectName
              );
              onValue(currentRef, (snapshot) => {
                //console.log("onValue projectID firebase event triggered");
                ////console.log(snapshot.val());
                const fetchedProjectId = snapshot.val();

                if (
                  fetchedProjectId &&
                  fetchedProjectId.absoluteCenter.geometry
                ) {
                  // console.log(fetchedProjectId.name);

                  const projectFeature = fetchedProjectId.absoluteCenter;
                  projectFeature.properties = JSON.parse(
                    JSON.stringify(fetchedProjectId)
                  );
                  fetchedSpecimensProjectIDsListAsObject[
                    fetchedProjectId.name
                  ] = projectFeature;
                  fetchedSpecimensProjectIDsListAsFeatureCollection.features =
                    Object.values(fetchedSpecimensProjectIDsListAsObject);
                  ////console.log("fetchedSpecimensProjectIDsListAsFeatureCollection length " + fetchedSpecimensProjectIDsListAsFeatureCollection.features.length)
                  //console.log("starting to put project marker on map");
                  mapService.loadProjectsMarkersOnMap(
                    fetchedSpecimensProjectIDsListAsFeatureCollection
                  );
                  store.commit(
                    "setFetchedSpecimensProjectIDsListAsObject",
                    fetchedSpecimensProjectIDsListAsObject
                  );
                  store.commit(
                    "setFetchedSpecimensProjectIDsListAsFeatureCollection",
                    fetchedSpecimensProjectIDsListAsFeatureCollection
                  );
                  if (
                    store.state.currentProjectID &&
                    fetchedProjectId.name == store.state.currentProjectID.name
                  ) {
                    // set right away in currentProjectId to update ui in case this function was triggered by the listener
                    store.commit(
                      "setCurrentProjectID",
                      projectFeature.properties
                    );
                  }
                }
              });
            }
          });
        }
      } else  */
      if (store.state.userGroupMembership == "omhm") {
        // check for abattus in applied query filters
        let abattusQueryFilterFound = false;
        store.state.queryOptions.filters.forEach((filter) => {
          if (filter.displayName === "année de l'abattage") {
            abattusQueryFilterFound = true;
          }
        });
        fetchedSpecimens.forEach((spec) => {
          // format to put on map
          // eslint-disable-next-line no-prototype-builtins
          if (!spec.hasOwnProperty("geojsonFeature")) {
            console.log("no geojsonFeature on specimen.");
            console.log(JSON.stringify(spec));
            return
          }
          if (!spec.geojsonFeature.type) {
            // add property to make sure turf works as expected (it is doing validation on geojson format)
            spec.geojsonFeature.type = "Feature";
          }
          fetchedSpecimensObj[spec.firestoreId] = spec;
          const tempSpecimen = spec;
          const specimen = addMissingPropertiesToFetchedSpecimen(tempSpecimen);

          // do not show specimens abattus except if specifically requested
          if (
            !abattusQueryFilterFound &&
            specimen.properties.groupSpecific &&
            specimen.properties.groupSpecific.omhm &&
            specimen.properties.groupSpecific.omhm.tasks &&
            specimen.properties.groupSpecific.omhm.tasks.abattage &&
            specimen.properties.groupSpecific.omhm.tasks.abattage.suivi &&
            specimen.properties.groupSpecific.omhm.tasks.abattage.suivi.dateDone
          ) {
            // do nothing
            //console.log("arbre abattu");
          } else {
            featureCollectionForFetchedSpecimens.features.push(specimen);
          }
        });
      } else {
        // format specimens and add to featureCollection
        let specimensCounter = 0;
        store.state.likeTagsInSelection = [];
        store.state.observationsInSelection = [];
        store.state.reportsInSelection = [];
        fetchedSpecimens.forEach((spec) => {
          // format to put on map
          // eslint-disable-next-line no-prototype-builtins
          if (!spec.hasOwnProperty("geojsonFeature")) {
            //console.log(JSON.stringify(specimen));
            console.log("no geojsonFeature on specimen.  See log for details.");
            return;
          }
          if (!spec.geojsonFeature.type) {
            // add property to make sure turf works as expected (it is doing validation on geojson format)
            spec.geojsonFeature.type = "Feature";
          }
          fetchedSpecimensObj[spec.firestoreId] = spec;
          specimensCounter++;
          //console.log(specimensCounter);
          //console.log("addMissingPropertiesToFetchedSpecimen");
          const specimen = addMissingPropertiesToFetchedSpecimen(spec);
          //console.log(specimen);
          featureCollectionForFetchedSpecimens.features.push(specimen);
        });
      }

      for (
        let index = store.state.collaborativeDataQueryObj.length - 1;
        index >= 0;
        index--
      ) {
        const collaborativeData = store.state.collaborativeDataQueryObj[index];
        //console.log(store.state.fetchedSpecimensObj);
        if (!store.state.fetchedSpecimensObj[collaborativeData.specimen_uid]) {
          store.state.collaborativeDataQueryObj.splice(index, 1);
        }
      }

      // convert into array and sort
      let fetchedSpecimensBySpeciesAsArray = Object.values(
        fetchedSpecimensBySpecies
      );
      let fetchedSpecimensBySpeciesAsArraySorted =
        fetchedSpecimensBySpeciesAsArray.sort((a, b) => a.amount - b.amount);
      store.state.fetchedSpecimensBySpecies =
        fetchedSpecimensBySpeciesAsArraySorted.reverse();
      let fetchedSpecimensByGenusAsArray = Object.values(
        fetchedSpecimensByGenus
      );
      // group items that account for less than 1% of the displayed specimens
      const others = {
        amount: 0,
        nameFr: "autres (<1%)",
        code: "autres"
      };
      for (let i = fetchedSpecimensByGenusAsArray.length - 1; i >= 0; i -= 1) {
        if (fetchedSpecimensByGenusAsArray[i].amount / fetchedSpecimens.length < 0.01) {
          others.amount += fetchedSpecimensByGenusAsArray[i].amount;
          fetchedSpecimensByGenusAsArray.splice(i, 1);
        }
      }
      if (others.amount > 0) {
        fetchedSpecimensByGenusAsArray.push(others);
      }
      let fetchedSpecimensByGenusAsArraySorted =
        fetchedSpecimensByGenusAsArray.sort((a, b) => a.amount - b.amount);
      store.state.fetchedSpecimensByGenus =
        fetchedSpecimensByGenusAsArraySorted.reverse();

      let fetchedSpecimensByPlantationDateAsArray = Object.values(
        fetchedSpecimensByPlantationDate
      );
      let fetchedSpecimensByPlantationDateAsArraySorted =
        fetchedSpecimensByPlantationDateAsArray.sort((a, b) => a.year - b.year);
      store.state.fetchedSpecimensByPlantationDate =
        fetchedSpecimensByPlantationDateAsArraySorted;
      // put last item first ("inconnue");
      store.state.fetchedSpecimensByPlantationDate.unshift(
        store.state.fetchedSpecimensByPlantationDate.pop()
      );

      store.commit("setFetchedSpecimensAsFeatureCollection", featureCollectionForFetchedSpecimens);
      console.log("before putting specimens on map");
      mapService.putSpecimensOnMap(
        featureCollectionForFetchedSpecimens,
        store.state.disableFitBounds,
        true,
        store.state.treeIconsOptions
      );
    } catch (e) {
      console.log(e);
      errors.value.push(e);
      loading.value = false;
    } finally {
      loading.value = false;
    }
  };

  const fetchOMHMAddresses = async (location, radius, omhmHabitationsOnly) => {
    var addressesArray = [];
    var uniqueHabitationNames = {};
    var turfPoints = [];
    var fetchCounter = 0;
    //console.log("before requesting omhm addresses ");
    //console.log(addressesArray.length);
    // specific set ogf query options for omhm_addresses
    var tempQueryOptions = {
      collectionName: "omhm_addresses_v2",
      radius: radius ? radius : 500,
      filters: [],
    };

    try {
      //console.log("trying to load habitations");

      const fetchedHabitations = await getCollectionData(tempQueryOptions);
      //console.log(fetchedHabitations);
      // reset queryOptions
      /*  store.state.queryOptions.collectionName = "specimens";
             store.state.queryOptions.radius = 300; */
      fetchedHabitations.forEach(function (doc) {
        addressesArray.push(doc.geojsonFeature);
        uniqueHabitationNames[
          doc.geojsonFeature.properties.place.habitationName
        ] = doc.geojsonFeature.properties.place.habitationName;
        /* if (!omhmHabitationsOnly) {
                    doc.geojsonFeature.properties.firestoreId = doc.id;

                    store.state.mapboxMap.addMarkers([
                        {
                            lat: doc.geojsonFeature.geometry.coordinates[1],
                            lng: doc.geojsonFeature.geometry.coordinates[0],
                            title:
                                "Habitation " +
                                doc.geojsonFeature.properties.place.habitationName,
                            subtitle: doc.geojsonFeature.properties.place.address,
                            //    icon: "res://habitationOmhm@2x"
                        },
                    ]);
                } else {
                    //
                } */
      });
      //console.log("after: " + addressesArray.length);
      /*  addressesArray.forEach(function (item) {
                 if (!item.properties.place.habitationName)
                 //console.log(JSON.stringify(item.properties));
             }); */

      if (radius == 1000000) {
        // all habitations were fetched in order to make a query based on habitation name.
        store.commit("setUniqueHabitationNames", uniqueHabitationNames);
      } else {
        store.commit(
          "setFetchedOmhmAddresses",
          featureCollection(addressesArray)
        );
        mapService.loadHabitationsOnMap(featureCollection(addressesArray));
        //console.log("after requesting omhm addresses ");
        //console.log(addressesArray.length);

        omhmHabitationsOnly = true;
        if (omhmHabitationsOnly) {
          // find closest habitation from user location
          if (
            store.state.userGroupMembership === "omhm" &&
            addressesArray.length > 0
          ) {
            //console.log("nearest habitation");
            var nearest = nearestPoint(
              [
                store.state.currentPosition.coords.longitude,
                store.state.currentPosition.coords.latitude,
              ],
              featureCollection(addressesArray)
            );
            store.commit("setNearestOmhmAddress", nearest);
            //console.log("nearest habitation set");
          }
          // if new specimen, automatically add nearest habitation
          if (store.state.createOrModifySpecimen == "create") {
            store.state.formattedSpecimen.groupSpecific[
              store.state.userGroupMembership
            ].place = store.state.nearestOmhmAddress.properties.place;
            // also add name properties for legacy reasons and to allow for habitation query
            store.state.formattedSpecimen.groupSpecific[
              store.state.userGroupMembership
            ].place.name =
              store.state.nearestOmhmAddress.properties.place.habitationName;
          }
        }
      }
    } catch (e) {
      //console.log(e);
      errors.value.push(e);
    } finally {
      //
      //console.log("habitations loaded");
    }
  };

  return {
    // functions
    loadSpecimens,
    getLocalSpecimenDetails,
    getCollectionDoc,
    getCollectionData,
    listenToCollectionDoc,
    getFirebaseDbData,
    fetchOMHMAddresses,
    addMissingPropertiesToFetchedSpecimen,
    checkForSameSpeciesInGivenRadius,
    //   setQueryOptions,
    // properties
    specimenMarkers,
    errors,
    loading,
    //     queryOptions
  };
};
