<template>
  <!-- Getting and setting the state of the accordion group -->
  <div style="width: 100%;height: 100%; overflow-y: scroll;">
    <div class="map-menu-background" @click="$store.state.showMapMenu = false;"></div>

    <div class="map-menu">
      <ion-accordion-group ref="accordionGroup" class="header-md">
        <!-- <span v-if="innerHeight > 800">
      <ion-item>
        <ion-label class="ion-text-wrap map-menu-fab-description"
          >Afficher les arbres à cet endroit selon les filtres de
          requête</ion-label
        >
      </ion-item>
      <ion-item>
        <ion-label class="ion-text-wrap map-menu-fab-description"
          >Centrer la carte sur votre position, afficher l'arbre le plus près de
          vous</ion-label
        >
      </ion-item>
      <ion-item v-if="$store.state.userGroupMembership != 'soverdi'">
        <ion-label class="ion-text-wrap map-menu-fab-description"
          >Ajouter un nouvel arbre</ion-label
        >
      </ion-item>
    </span> -->
        <!--  <ion-accordion
        v-if="$store.state.userGroupMembership == 'soverdi'"
        value="soverdiProjects"
      >
        <ion-item slot="header">
          <ion-label slot="end">Chercher un projet de la Soverdi</ion-label>
        </ion-item>
        <div slot="content" class="add-ion-background-color">
          <ion-searchbar
            placeholder="nom du projet"
            showCancelButton="true"
            debounce="0"
            v-model="soverdiProjectsSearchInput"
            @ionCancel="cancelSelectedSoverdiProject"
          >
          </ion-searchbar>
          <ion-list v-if="soverdiProjectsSearchInput" style="max-height: 500px">
            <ion-item
              v-for="project in $store.state.projectsList"
              v-show="
                project.displayName &&
                project.polygon &&
                project.displayName
                  .toLowerCase()
                  .indexOf(soverdiProjectsSearchInput.toLowerCase()) > -1
              "
              :key="project"
              @click="setChosenProject(project)"
              >{{ convert(project.displayName) }}</ion-item
            >
          </ion-list>
        </div>
      </ion-accordion> -->
        <ion-accordion>
          <ion-item slot="header" style="border-top: 0px">
            <ion-label slot="end">Centrer par adresse</ion-label>
          </ion-item>
          <div slot="content" id="geocoder" class="geocoder"
            style="width:100%;max-width: 100%;border:2px solid var(--ion-color-step-150);border-bottom:0;border-radius: 4px;">
          </div>
        </ion-accordion>
        <ion-accordion value="mapStyles">
          <ion-item slot="header">
            <ion-label slot="end">Apparence</ion-label>
          </ion-item>

          <ion-list slot="content" lines="none">
            <!--   <ion-item @click="setMapStyle('standard')">
            <ion-icon
              v-if="$store.state.currentMapStyle == 'standard'"
              :icon="checkmarkOutline"
              color="primary"
            ></ion-icon>
            <ion-label>Standard</ion-label>
          </ion-item>
          <ion-item @click="setMapStyle('soverdi2023')">
            <ion-icon
              v-if="$store.state.currentMapStyle == 'standard'"
              :icon="checkmarkOutline"
              color="primary"
            ></ion-icon>
            <ion-label>Soverdi2023</ion-label>
          </ion-item>
          <ion-item @click="setMapStyle('uapmq')">
            <ion-icon
              v-if="$store.state.currentMapStyle == 'standard'"
              :icon="checkmarkOutline"
              color="primary"
            ></ion-icon>
            <ion-label>uapmq</ion-label>
          </ion-item> -->
            <ion-item class="map-menu-subtitle">
              <ion-label>Carte</ion-label>
            </ion-item>
            <!--  <ion-item @click="setMapStyle('dark-v11')" class="map-menu-subitem">
            <ion-icon v-if="$store.state.currentMapStyle == 'dark-v11'" :icon="checkmarkOutline"
              color="primary"></ion-icon>
            <ion-label>Fond sombre</ion-label>
          </ion-item>
          <ion-item @click="setMapStyle('light-v11')" class="map-menu-subitem">
            <ion-icon v-if="$store.state.currentMapStyle == 'light-v11'" :icon="checkmarkOutline"
              color="primary"></ion-icon>
            <ion-label>Fond clair</ion-label>
          </ion-item> -->
            <ion-item class="map-style-options">
              <span @click="setMapStyle('satellite-v9')">
                <ion-icon v-if="$store.state.currentMapStyle == 'satellite-v9'" :icon="checkmarkOutline" color="primary"
                  style="float:left;margin-right: 6px;font-size: x-large;margin-top:-7px"></ion-icon>
                <ion-label class="map-menu-subitem">Image satellite</ion-label>
              </span>
              <span v-show="!$store.state.showScaleBar" @click="mapService.showScaleBar()">
                <ion-label class="map-menu-subitem">Afficher échelle</ion-label>
              </span>
              <span v-show="$store.state.showScaleBar" @click="mapService.hideScaleBar()">
                <ion-icon :icon="checkmarkOutline" color="primary"
                  style="float:left;margin-right: 6px;font-size: x-large;margin-top:-7px"></ion-icon>
                <ion-label class="map-menu-subitem">Afficher échelle</ion-label>
              </span>
            </ion-item>
            <!-- <ion-item @click="setMapStyle('aquarelle')">
            <ion-icon
              v-if="$store.state.currentMapStyle == 'aquarelle'"
              :icon="checkmarkOutline"
              color="primary"
            ></ion-icon>
            <ion-label>Aquarelle</ion-label>
          </ion-item>
          <ion-item @click="setMapStyle('buildings')">
            <ion-icon
              v-if="$store.state.currentMapStyle == 'buildings'"
              :icon="checkmarkOutline"
              color="primary"
            ></ion-icon> 
            <ion-label>3D Buildings</ion-label>
          </ion-item> -->

            <ion-item class="map-menu-subtitle">
              <ion-label>Interface</ion-label>
            </ion-item>
            <ion-radio-group class="radio-options" v-model="$store.state.currentUIAppearence">
              <ion-radio label-placement="end" justify="start" value="dark" style="margin-right:11px">Sombre</ion-radio>
              <ion-radio label-placement="end" justify="start" value="light">Claire</ion-radio>
              <ion-radio label-placement="end" justify="start" value="auto">Auto</ion-radio>
            </ion-radio-group>
            <!--   <ion-item class="map-menu-subitem"  @click="$store.state.currentUIAppearence = 'light'">
           
          </ion-item>
          <ion-item class="map-menu-subitem" @click="$store.state.currentUIAppearence = 'auto'">
           
          </ion-item> -->
            <!--  <ion-item @click="loadIlotsDeChaleurOnMap()">
          <ion-label>Ilots de chaleur (2012-16)</ion-label>
        </ion-item> -->
          </ion-list>
        </ion-accordion>
        <!--       <ion-accordion value="markers">
        <ion-item slot="header">
          <ion-label slot="end">Marqueurs</ion-label>
        </ion-item>
        <ion-list slot="content">
          <ion-item @click="setMapMarkers({ trees: false })">
            <ion-label>Cercles</ion-label>
          </ion-item>
          <ion-item @click="setMapMarkers({ trees: true })">
            <ion-label>Arbres</ion-label>
          </ion-item>
          <ion-item
            v-if="$store.state.userGroupMembership == 'omhm'"
            @click="
              showSpeciesCode = !showSpeciesCode;
              setMapMarkers({ showSpeciesCode: showSpeciesCode });
            "
          >
            <ion-icon
              v-show="showSpeciesCode"
              :icon="checkmarkOutline"
              color="primary"
            ></ion-icon>
            <ion-label>Afficher code de l'espèce</ion-label>
          </ion-item>
        </ion-list>
      </ion-accordion> -->
        <ion-accordion>
          <ion-item slot="header">
            <ion-label slot="end">À propos</ion-label>
          </ion-item>
          <div slot="content" class="ion-padding" style="max-width: 380px; font-size:14px; margin: auto;">
            <div v-html="returnAproposText()" style="padding-bottom:12px; color:var(--ion-text-color)"></div>
            <div style="margin-bottom:24px"><a href="https://forms.gle/LdQYSikQ2kTdR9yh7">Aide et soutien
                technique</a><ion-icon style="margin-left:6px" :icon="openOutline"></ion-icon></div>
            <div><a href="https://branche-mobile.web.app/privacy_policy.html">Politique de confidentialité</a><ion-icon
                style="margin-left:6px" :icon="openOutline"></ion-icon></div>
          </div>
        </ion-accordion>
        <ion-accordion>
          <ion-item slot="header">
            <ion-label slot="end">Tour guidé</ion-label>
          </ion-item>
          <div slot="content">
            <ion-item @click="toggleGuidedTour()">
              <ion-label>Afficher tour guidé</ion-label>
            </ion-item>
          </div>
        </ion-accordion>
        <ion-accordion value="featuredProjets">
          <ion-item slot="header">
            <ion-label slot="end">Projets en vedette</ion-label>
          </ion-item>
          <ion-list slot="content" style="padding-bottom: 24px;">
            <ion-item v-for="project in $store.state.featuredProjects" :key="project" @click="
              $store.state.selectedFeaturedProject = project;
            $store.state.showSelectedFeaturedProject = true;
            $store.state.showMapMenu = false;
            $store.state.showNearestSpecimenModal = false;
            $store.state.showCompass = false;
            " v-show="$store.state.featuredProjectsList[project.name]">
              <ion-label>{{ convert(project.displayName.content) }}</ion-label>
              <ion-button fill="clear" id="open-featured-project-modal">Voir les détails</ion-button>
            </ion-item>
          </ion-list>
        </ion-accordion>
      </ion-accordion-group>
    </div>
  </div>
</template>

<script>
import {
  IonLabel,
  IonItem,
  /*   IonSearchbar,
   */ IonList,
  IonAccordion,
  IonAccordionGroup,
  IonIcon,
  createAnimation,
  IonRadio,
  IonRadioGroup
} from "@ionic/vue";
import firebaseService from "../services/firebaseService";
import mapboxgl from "mapbox-gl";
import { ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { checkmarkCircleOutline, checkmarkOutline, openOutline } from "ionicons/icons";
import mapService from "@/services/mapService";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { convert } from "html-to-text";
import { useGuidedTour } from "@/composables/useGuidedTour";

export default {
  name: "MapMenuComponent",
  props: {
    name: String,
  },
  components: {
    IonLabel,
    IonItem,
    IonList,
    IonAccordion,
    IonAccordionGroup,
    IonIcon,
    IonRadio,
    IonRadioGroup
    /*     IonSearchbar,
     */
  },
  emits: ["fetchSpecimens"],
  setup(props, ctx) {
    //console.log("props");
    //console.log(props);
    //const emit = defineEmits(["fetchSpecimens"]);

    const store = useStore();
    watch(
      // we have to use a getter because we are watching a primitive object
      () => store.state.currentUIAppearence,
      () => {
        if (store.state.currentUIAppearence == "dark") {
          store.state.toggleDarkTheme(true)
        } else if (store.state.currentUIAppearence == "light") {
          store.state.toggleDarkTheme(false)
        } else {

          store.state.toggleDarkTheme("auto")
        }
      });
    const showSpeciesCode = ref();
    const innerHeight = window.innerHeight;
    const setMapStyle = (styleName) => {
      console.log(styleName);
      console.log(store.state.currentMapStyle);
      if (store.state.currentMapStyle == "satellite-v9") {
        mapService.setMapStyle("dark-v11");
        store.state.currentMapStyle = "dark-v11"
      } else {
        mapService.setMapStyle(styleName);
        store.state.currentMapStyle = "satellite-v9"
      }
      // store.state.showMapMenu = false;
    };
    const setMapMarkers = (option) => {
      const keyname = Object.keys(option)[0];
      store.state.treeIconsOptions[keyname] = option[keyname];
      //console.log(store.state.treeIconsOptions);
      //   store.commit("setTreeIconsOptions", store.state.treeIconsOptions);
      mapService.putSpecimensOnMap(
        store.state.fetchedSpecimensAsFeatureCollection,
        true,
        false,
        store.state.treeIconsOptions
      );
    };
    const loadIlotsDeChaleurOnMap = (remove) => {
      //console.log("loadIlotsDeChaleurOnMap");
      mapService.loadIlotsDeChaleurOnMap(remove);
    };
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      zoom: 18,
      placeholder: "Entrez une adresse",
      minLength: 7,
      marker: false,
      flyTo: true,
      proximity: { latitude: 45.55, longitude: -73.65 },
    });
    let expendGeocoderContainer;
    function callback(e) {
      //console.log("geocoder on result callback");
      //console.log(e);
      // turn off nearestSpecimen
      store.commit("setShowNearestSpecimenModal", false);
      store.state.showMapMenu = false;
      store.state.mapboxMap.flyTo({
        center: e.result.center,
        zoom:
          store.state.mapboxMap.getZoom() < 18
            ? 18
            : store.state.mapboxMap.getZoom(),
        offset: [187, 0],
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
      function moveendCallback() {
        store.state.mapboxMap.off("moveend", moveendCallback);

        //console.log("A moveend event occurred.");
        // mapService.animateUserLocationOnMap();
        store.state.disableFitBounds = false;
        var specimensLoaded = firebaseService().loadSpecimens();
        specimensLoaded.then(function (res) {
          //console.log(res);
          store.commit(
            "setFetchedSpecimens",
            firebaseService().specimenMarkers
          );
          // disable fitBounds for omhm after initial fetch
          if (store.state.userGroupMembership == "omhm") {
            store.state.disableFitBounds = true;
          }
        });
      }
      store.state.mapboxMap.on("moveend", moveendCallback);
      expendGeocoderContainer.stop();
    }
    function callback2(e) {
      //console.log("geocoder on loading event callback");
      //console.log(e);
      // adjust container size to show geocoder suggestions
      expendGeocoderContainer.play();
    }
    geocoder.on("result", callback);
    geocoder.on("loading", callback2);
    onMounted(() => {
      //
      const geocoderElementRef = document.getElementById("geocoder");
      geocoderElementRef.appendChild(geocoder.onAdd(store.state.mapboxMap));

      expendGeocoderContainer = createAnimation()
        .addElement(geocoderElementRef)
        .to("height", "300px");
    });

    const soverdiProjectsSearchInput = ref(null);

    const setChosenProject = (project) => {
      console.log("loadProjectOnMap");
      console.log(convert(project.displayName.content));
      console.log(project.displayName.content);
      store.commit("setSideMenuDisabled", true);
      store.commit("setShowSideMenuForProject", false);
      mapService.loadProjectOnMap(project, true);
      const tempFeatureCollection = {
        features: [
          {
            geometry:
              store.state.projectsList[project.name].absoluteCenter.geometry,
            properties: store.state.projectsList[project.name],
            type: "Feature",
          },
        ],
        type: "FeatureCollection",
      };
      mapService.loadProjectsMarkersOnMap(tempFeatureCollection);
    };

    const fetchSpecimensFromCurrentProject = async (project) => {
      store.state.showSelectedFeaturedProject = false;

      console.log("loadProjectOnMap");
      console.log(project);

      // first fetch all projectIds that have a match on the tags property
      await firebaseService().getFirebaseDbData(
        "projectIDs",
        "setProjectIDsMatches",
        project.name
      );
      // then for each projectId found, fetch specimens using project name
      console.log(store.state.projectIDsMatches);
      // set radius to 0 in the query options (which removes the radius filter from the geoquery)
      store.state.queryOptions.radius = 0;
      store.state.disableFitBounds = false;
      //   store.state.queryOptions.addToDisplayedSpecimens = true;
      // build a list of queries
      const promises = [];
      Object.values(store.state.projectIDsMatches).forEach(
        (projectIDsMatch) => {
          promises.push(
            firebaseService().getCollectionData({
              collectionName: "specimens",
              filters: [
                {
                  fieldName:
                    "geojsonFeature.properties.groupSpecific.soverdi.plantation.project",
                  operator: "==",
                  enteredValue: projectIDsMatch.name,
                  type: "string",
                  displayName: "projet",
                  displayValue: projectIDsMatch.displayName,
                },
              ],
            })
          );
          // ctx.emit("fetchSpecimens");
        }
      );
      // Collect all the query results together into a single list
      const resolvedPromises = await Promise.all(promises);
      let allFetchedSpecimensForFeaturedProject = [];
      resolvedPromises.forEach((array) => {
        allFetchedSpecimensForFeaturedProject = allFetchedSpecimensForFeaturedProject.concat(array);
      })
      console.log(allFetchedSpecimensForFeaturedProject);
      console.log(allFetchedSpecimensForFeaturedProject.length);
      firebaseService().loadSpecimens(allFetchedSpecimensForFeaturedProject)
      /*  const docsToReturn = [];
       for (const snap of snapshots) {
         for (const doc of snap.docs) {
           docsToReturn.push({ ...doc.data(), firestoreId: doc.id });
         }
       } */
      store.state.showMapMenu = false;

      //console.log(store.state.queryOptions);
      setTimeout(() => {
        //console.log("emiting");
        store.state.queryOptions.radius = 100;
        store.commit("setFiltersListArrayInQueryOptions", []);
      }, 5000);
      //console.log("function ends");
    };

    const cancelSelectedSoverdiProject = () => {
      //   showSearchInputElements.value = false;
      //   store.commit("setSelectedFilter", {});
    };
    let aproposTextAfter = '';
    aproposTextAfter = JSON.parse(JSON.stringify(store.state.specimensCounter.aproposText));

    const returnAproposText = () => {
      console.log(aproposTextAfter);
      console.log(store.state.specimensCounter.aproposText);
      const fromThese = ["specimensCounterTotal", "specimensCounterFromPublicData", "specimensCounterAddedUsingBranche"];
      const toThose = [store.state.specimensCounter.total, store.state.specimensCounter.fromPublicData, store.state.specimensCounter.addedUsingBranche];
      fromThese.map((name, i) => {
        aproposTextAfter = aproposTextAfter.replace(name, toThose[i]);
      })
      return aproposTextAfter;
    };

    const toggleGuidedTour = () => {
      console.log("allo");
      store.state.showCompass = false;
      store.state.showMapMenu = false;
      store.commit("setShowGuidedTour", true);
      useGuidedTour().guidedTour();
    };

    return {
      checkmarkCircleOutline,
      setMapStyle,
      setMapMarkers,
      loadIlotsDeChaleurOnMap,
      checkmarkOutline,
      showSpeciesCode,
      innerHeight,
      soverdiProjectsSearchInput,
      fetchSpecimensFromCurrentProject,
      cancelSelectedSoverdiProject,
      mapService,
      setChosenProject,
      convert,
      returnAproposText,
      openOutline,
      toggleGuidedTour,
    };
  },
};
</script>

<style scoped>
/* ion-accordion {
  border-top: 1px solid var(--ion-text-color);
background-color: var(--ion-background-color)
} */
/* ion-accordion::part(content) {  
  background-color: rgb(49, 192, 144);
} */
ion-accordion.accordion-expanding ion-item[slot='header'],
ion-accordion.accordion-expanded ion-item[slot='header'] {
  --color: var(--ion-color-primary) !important;
  /*   font-weight: bold
 */
}

ion-item {
  /*   --background: transparent;
 */
  border-top: 1px solid var(--ion-color-step-500);
  cursor: pointer;
}

ion-list {
  background-color: var(--ion-color-step-150);
  padding: 0px;
}
</style>
