<template>
  <ion-app>
    <ion-router-outlet :swipeGesture="false" />
  </ion-app>
</template>

<script>



import { initializeApp } from "firebase/app";
const firebaseApp = initializeApp({
  apiKey: "AIzaSyAWxsE2ZhL7SeSY_q6DmnMmPt403pM3-hs",
  authDomain: "soverdiwebsite.firebaseapp.com",
  databaseURL: "https://soverdiwebsite.firebaseio.com",
  projectId: "soverdiwebsite",
  storageBucket: "soverdiwebsite.appspot.com",
  messagingSenderId: "280893544026",
  appId: "1:280893544026:web:4daf5af58e9fa7e1ee2a63",
  measurementId: "G-KVBBY4DL7H",
});
import { IonApp, IonRouterOutlet, useBackButton } from "@ionic/vue";
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { App } from "@capacitor/app";
import { useStore } from "vuex";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { useRoute } from "vue-router";

console.log("App.vue");


export default defineComponent({
  // eslint-disable-next-line vue/multi-word-component-names
  name: "branche",
  components: {
    IonApp,
    IonRouterOutlet,
  },
  //emits: ["fetchSpecimens"],
  setup(props, ctx) {
    const router = useRouter();
    const store = useStore();
    store.commit("setFirebaseApp", firebaseApp);
    console.log("firebaseApp is in store");
    const db = getFirestore(store.state.firebaseApp);
    store.state.firestoreDb = db;
    // this is for Android hardware devices back button
    useBackButton(1000, (processNextHandler) => {
      // check for any opened modal or element from the UI and close it
      // start with components modals
      if (store.state.showListeEspeces) {
        store.state.showListeEspeces = false;
        return
      } else if (store.state.showCleIdentificationEspeces) {
        store.state.showCleIdentificationEspeces = false;
        return
      } else if (store.state.showNewLikeDetails) {
        store.state.showNewLikeDetails = false;
        return
      } else if (store.state.likeDetails) {
        store.state.likeDetails = null;
        return
      } else if (store.state.showReportDetails) {
        store.state.showReportDetails = false;
        return
      } else if (store.state.showImgModal) {
        store.state.showImgModal = false;
        return
      } else if (store.state.showSelectedFeaturedProject) {
        store.state.showSelectedFeaturedProject = false;
        return
      } else if (
        store.state.showMapMenu ||
        store.state.showNewSpecimenModal ||
        store.state.showQueryFilters ||
        store.state.showEditProfile ||
        store.state.showCreateProfile ||
        store.state.showFetchedSpecimensStats ||
        store.state.showImgModal
      ) {
        store.state.showMapMenu = false;
        store.commit("setShowNewSpecimenModal", false);
        store.state.showQueryFilters = false;
        store.state.showEditProfile = false;
        store.state.showCreateProfile = false;
        store.state.showFetchedSpecimensStats = false;
        store.state.showImgModal = false;
        return
        /* const modalRef = document.querySelector(
".modal-content-specimen-details"
);
modalRef.setCurrentBreakpoint(0.001); */
      } else {
        router.push({ path: "/" });
      }
    });
    App.addListener("appStateChange", ({ isActive }) => {
      console.log("App state changed. Is active?", isActive);
      if (!isActive) {
        return;
      }
      // make sure the map shows up properly when the app becomes active again
      if (store.state.mapboxMap) {
        store.state.mapboxMap.resize();
        // go to user position if there are no trees on map
        if (store.state.fetchedSpecimensAsFeatureCollection.features.length == 0) {
          /*      store.commit("setMapCenter", [
                 store.state.currentPosition.coords.longitude,
                 store.state.currentPosition.coords.latitude,
               ]); */
          store.state.showNearestSpecimenModal = true;
        /* setTimeout(() => {
          store.state.mapboxMap.flyTo({
            center: store.state.mapCenter,
            zoom: store.state.mapboxMap.getZoom() < 18 ? 18 : store.state.mapboxMap.getZoom(),
            essential: true, // this animation is considered essential with respect to prefers-reduced-motion
          });
        }, 20); */}
      }
      const auth = getAuth();
      const user = auth.currentUser;
      console.log("user");
      console.log(user);
      if (
        user &&
        user.providerData[0].providerId == "password" &&
        !user.emailVerified
      ) {
        // check if email was verified
        auth.signOut().then(() => {
          signInWithEmailAndPassword(auth, user.email, store.state.password)
            .then((userCredential) => {
              console.log("userCredential.user");
              console.log(userCredential.user);
              if (userCredential.user.emailVerified) {
                alert(
                  "Votre adresse courriel a été validée avec succès. Votre compte est prêt à être utilisé."
                );
                // go to map
                router.push({ path: "/" });
                // ...
              }
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              alert(
                store.state.returnErrorMessageInFrench(error.code)
              );
            });
        });
      }
      // fetch trees if there is none
    });

    App.addListener("appUrlOpen", (data) => {
      console.log("App opened with URL:", data);
      const url = data.url;
      console.log(url);
      const emailParam = url.split("?email").slice(-1)[0];
      console.log(emailParam);
      if (emailParam.indexOf(store.state.user.email) > -1) {
        // fetch the updated user
        const auth = getAuth();
        const user = auth.currentUser;
        console.log("user");
        console.log(user);
        user.reload().then(() => {
          const updatedUser = auth.currentUser;
          console.log("updatedUser");
          console.log(updatedUser);
          console.log(store.state.user.email);
        });
        // set the user as verified
        store.state.user.emailVerified = true;
        alert(
          "Votre adresse courriel a été vérifiée avec succès. Entrez votre mot de passe pour vous connecter."
        );
      }
    });

    App.addListener("appRestoredResult", (data) => {
      console.log("Restored state:", data);
    });

    const checkAppLaunchUrl = async () => {
      try {
        const { url } = await App.getLaunchUrl();
        console.log("App opened with URL : " + url);
      } catch (error) {
        console.error('Error getting launch url:', error);
      }
    };
    checkAppLaunchUrl();
    /* App.addListener("appUrlOpen", function (data) {
      // Example url: https://beerswift.app/tabs/tabs2
      // slug = /tabs/tabs2
      console.log("data.url");
      console.log(data.url);
        const slug = data.url.split("?")[0];

      // We only push to the route if there is a slug present
      switch (slug) {
        case "https://beerswift.app/":
          router.push('/');
          break;
        case "https://beerswift.app/tab1":
          router.push('/tabs/tab1');
          break;
        case "https://beerswift.app/tab2":
          router.push('/tabs/tab2');
          break;
        case "https://beerswift.app/tab3":
          router.push('/tabs/tab3');
          break;
        default:
          console.log("URL not found");
      }
    }); */
  },
});
</script>
