<template>
  <div class="chart-container">
  <Bar :options="chartOptions" :data="chartData" />
</div>
</template>

<script>
import { ref, watch } from "vue";
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import store from "@/store";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);
const chartColor = ref(true);

export default {
  name: "BarChart",
  components: { Bar },
  computed: {
    chartData() {
      let data = [];
      if (this.$store.state.selectedFeaturedProject && this.$store.state.selectedFeaturedProject.stats) {
        data = Object.values(this.$store.state.selectedFeaturedProject.stats.plantationDate);
        console.log(data);
      } else {
        data = this.$store.state.fetchedSpecimensByPlantationDate;
      }
      const labelsSpecimensByPlantationDate = [];
      const dataSpecimensByPlantationDate = [];
      console.log(data);
      if (!data || !data.length || !data[0]) {
        data = [{
          "amount": 12345,
          "year": "année inconnue"
        }]
      }
      data.forEach((item) => {
        if (item.year != "année inconnue") {
          labelsSpecimensByPlantationDate.push(item.year);
          dataSpecimensByPlantationDate.push(item.amount);
        }
      });
      return /* mutable chart data */ {
        labels: labelsSpecimensByPlantationDate,
        datasets: [
          {
            data: dataSpecimensByPlantationDate,
            label: "Quantité",
            backgroundColor: "#00967d",
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            labels: {
              color: chartColor.value ? "white" : "black",
              font: {
                size: 18
              }
            }
          }
        },
        responsive: true,
        scales: {
          x: {
            ticks: {
              color: chartColor.value ? "white" : "black"
            }
          },
          y: {
            ticks: {
              color: chartColor.value ? "white" : "black"
            }
          }
        }
      };
    },
  },
  setup() {
    const checkUIAppearence = () => {
      console.log("checking UI ");
      console.log(store.state.currentUIAppearence);
      if (store.state.currentUIAppearence == "auto") {
        // Use matchMedia to check the user preference
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
        chartColor.value = prefersDark.matches;
        //      ChartJS.defaults.color = prefersDark.matches ? "black" : "white";
        // Listen for changes to the prefers-color-scheme media query
        prefersDark.onchange = (mediaQuery) => {
          // alert(mediaQuery.matches);
          chartColor.value = mediaQuery.matches;
        };
      } else if (store.state.currentUIAppearence == "light") {
        chartColor.value = false;
      } else {
        chartColor.value = true;
      }
    }
    checkUIAppearence();
    watch(()=>store.state.currentUIAppearence, ()=>{
      checkUIAppearence();
    })
  }
};
</script>
