<!-- /* displays the available choices for adding a new tree for the current user, according to its groupmembership
 */ -->
<template>
  <div class="new-specimen-singleUser-initial-selection ion-padding">
    <ion-header>
      <ion-toolbar style="padding-top: 0px">
        <ion-title>{{ title }}</ion-title>
        <ion-button
          fill="clear"
          slot="end"
          @click="$store.state.showNewSpecimenModal = false"
        >
          <ion-icon :icon="closeCircle"></ion-icon>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <div v-if="$store.state.userGroupMembership == 'anonyme'">
      <ion-item style="padding-bottom:36px"
        ><ion-icon :icon="warning" color="danger" size="large"></ion-icon>
        <p >Vous devez vous authentifier pour ajouter un arbre.</p>
        <ion-button :routerLink="'/userLogin/'" style="margin-left:8px">
          <ion-icon :icon="personOutline"></ion-icon>
        </ion-button>
      </ion-item>
    </div>
    <div v-else style="margin-bottom:24px">
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-sm>
            <ion-item button @click="$store.state.showListeEspeces = true">
              <!-- <ion-thumbnail slot="start">
                <img :src="'../assets/images/feuille-bulle.png'" />
              </ion-thumbnail> -->
              <ion-icon :icon="book" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">
                <h3>Vous connaissez déjà l'espèce du spécimen</h3>
                <ion-text color="primary">Rechercher dans le répertoire</ion-text>
              </ion-label>
            </ion-item>
          </ion-col>
          <ion-col size="12" size-sm>
            <ion-item button @click="$store.state.showCleIdentificationEspeces = true; $store.state.pictureSet = {}; $store.state.cleIdentificationChosenSpecies = null">
              <!-- <img :src="'../assets/images/feuille-bulle.png'" /> -->
              <ion-icon :icon="leaf" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">
                <h3>J'ai besoin d'aide pour identifier ce spécimen</h3>
                <ion-text color="primary">Utiliser la clé d'identification</ion-text>
              </ion-label>
            </ion-item>
          </ion-col>
          <ion-col size="12" size-sm>
            <ion-item button @click="showImageRecognitionOption = true; $store.state.pictureSet = {}; $store.state.cleIdentificationChosenSpecies = null">
              <!-- <img :src="'../assets/images/feuille-bulle.png'" /> -->
              <ion-icon :icon="camera" slot="start"></ion-icon>
              <ion-label class="ion-text-wrap">
                <h3>J'ai besoin d'aide pour identifier ce spécimen</h3>
                <ion-text color="primary">Utiliser la reconnaissance visuelle</ion-text>
              </ion-label>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <ion-modal
      id="modal-content-liste-especes"
      :is-open="$store.state.showListeEspeces"
      :showBackdrop="true"
      :backdropDismiss="true"
      @ionModalDidDismiss="$store.state.showListeEspeces = false"
      class="modal-content-liste-especes"
    >
      <liste-especes-home addSpecimen="addSpecimen"></liste-especes-home>
    </ion-modal>
    <ion-modal
      :is-open="$store.state.showCleIdentificationEspeces"
      :showBackdrop="true"
      :backdropDismiss="true"
      @ionModalDidDismiss="setOpen(false)"
      class="modal-content-cle-identification"
    >
      <cle-identification-especes
        :rootPage="ModalHome"
      ></cle-identification-especes>
    </ion-modal>
    <ion-modal
      :is-open="showImageRecognitionOption"
      :showBackdrop="true"
      :backdropDismiss="true"
      @ionModalDidDismiss="showImageRecognitionOption = false"
      class="modal-content-cle-identification"
    >
      <visual-recognition-add-new-specimen></visual-recognition-add-new-specimen>
    </ion-modal>

   <!--  <div v-if="$store.state.userGroupMembership == 'omhm'" class="tabs-content">
      <specimen-page-omhm :createOrModify="'create'"></specimen-page-omhm>
    </div> -->
  </div>
</template>

<script>
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonModal,
  IonButton,
} from "@ionic/vue";
import {
  camera,
  book,
  closeCircle,
  leaf,
  warning,
  personOutline,
} from "ionicons/icons";
import { defineComponent, ref } from "vue";
// import SpecimenPageOmhm from "@/components/specimenPageOMHM.vue";
import { useStore } from "vuex";
import CleIdentificationEspeces from "@/components/CleIdentificationEspeces.vue";
import ListeEspecesHome from "@/components/ListeEspecesHome.vue";
// root page component defined here
import ModalHome from "@/components/CleIdentificationHome.vue";
import VisualRecognitionAddNewSpecimen from "./VisualRecognitionAddNewSpecimen.vue";

//console.log("newSpecimen view loaded");
export default defineComponent({
  name: "NewSpecimen",
  props: {
    title: { type: String, default: "Ajouter un spécimen" },
  },
  setup() {
    const store = useStore();
    const showImageRecognitionOption = ref(false);
    const setOpen = (state) => (store.state.showCleIdentificationEspeces = state);
    // make sure specimen detail is closed
    store.state.currentSegment = null;

    return {
      showImageRecognitionOption,
      camera,
      leaf,
      setOpen,
      ModalHome,
      book,
      warning,
      closeCircle,
      personOutline,
    };
  },
  components: {
    IonHeader,
    IonTitle,
    IonToolbar,
    IonItem,
    IonLabel,
    // SpecimenPageOmhm,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonModal,
    IonButton,
    ListeEspecesHome,
    CleIdentificationEspeces,
    VisualRecognitionAddNewSpecimen,
  },
});
</script>
