/* eslint-disable no-prototype-builtins */
import { createStore } from "vuex";
import SpecimenService from "../services/SpecimenService";
import { createAnimation, isPlatform } from "@ionic/vue";
import CleAddNewSpecimen from "../components/CleAddNewSpecimen.vue";
import { Motion } from "@capacitor/motion";
import { Preferences } from '@capacitor/preferences';
import mapService from "@/services/mapService";
import { round } from "@turf/turf";

console.log("loading index.ts");
const state: any = {
  returnActionCodeSettings: () => {
    return {
      url:
        "https://branche-mobile.firebaseapp.com/" +
        "?email=" +
        state.user.email,
      iOS: {
        bundleId: "org.soverdi.branche",
      },
      android: {
        packageName: "org.soverdi.branche",
        installApp: true,
        minimumVersion: "12",
      },
      handleCodeInApp: false,
      // When multiple custom dynamic link domains are defined, specify which
      // one to use.
      dynamicLinkDomain: "branche.page.link",
    };
  },
  activatedTabQuerySelector: ".fetch-specimens",
  activePlantationsInProject: null,
  activePlantationsInProjectAsArray: null,
  auth: null,
  showActivePlantationListOnMap: false,
  allEssencesDictionary: { value: "value" },
  allEssencesDictionaryAsArray: null,
  basicQueriesList: null,
  breakPointRelativeToContent: 0.001,
  breakpoints: [
    0.001, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6,
    0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1
  ],
  cappedBreakpoints: [
    0.001, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6,
    0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1
  ],
  returnCappedBreakpoints: () => {
    //console.log(state.breakPointRelativeToContent);
    return store.state.cappedBreakpoints;
  },
  relativeToContent: (firstClicked: any, previousBreakpoint: number) => {
    console.log("relative to content");
    //console.log(store.state.formattedSpecimen);
    let total = 0.2;
    let specimenDetailsContentElement: HTMLElement | null;
    let specimenBasicInfosContentElement: HTMLElement | null;
    if (store.state.formattedSpecimen) {
      const groupSpecific = store.state.formattedSpecimen.groupSpecific;
      //console.log(groupSpecific);
      console.log("specimenbasicinfos");
      specimenBasicInfosContentElement =
        document.getElementById("specimenbasicinfos");
      if (specimenBasicInfosContentElement) {
        console.log('specimenBasicInfosContentElement.offsetHeight');
      } else {
        setTimeout(() => {
          specimenBasicInfosContentElement =
            document.getElementById("specimenbasicinfos");
          console.log("from timeout block");
          /* if (specimenBasicInfosContentElement) {
          } */
          //console.log(specimenBasicInfosContentElement.offsetHeight);
        }, 1000);
      }
      specimenDetailsContentElement =
        document.getElementById("sideMenuSpecimen");
      if (specimenDetailsContentElement) {
        console.log('specimenDetailsContentElement.offsetHeight');
        if (specimenDetailsContentElement && specimenBasicInfosContentElement) {
          if (!previousBreakpoint || previousBreakpoint < store.state.breakPointRelativeToContent)
            store.commit(
              "setSpecimenDetailsContentElementHeight",
              specimenDetailsContentElement.offsetHeight +
              specimenBasicInfosContentElement.offsetHeight
            );
          const extraPadding = isPlatform("ios") ? -80 : -80;
          // also check if there are pictures
          let picturesHeight = 0;
          if (
            store.state.formattedSpecimen.photos &&
            store.state.formattedSpecimen.photos.length > 0
          ) {
            const rows = store.state.formattedSpecimen.photos.length / 3;
            console.log("pictures found " + Math.ceil(rows) + " rows");
            picturesHeight = Math.ceil(rows) * 125;
          }
          total =
            (store.state.specimenDetailsContentElementHeight +
              picturesHeight +
              extraPadding) /
            window.innerHeight;
          console.log('store.state.specimenDetailsContentElementHeight');
          console.log(store.state.specimenDetailsContentElementHeight);
          console.log('picturesHeight');
          console.log(picturesHeight);
          console.log('extraPadding');
          console.log(extraPadding);
          console.log("total " + total);
          total = Math.round(total * 100) / 100;
          //console.log(total);
          const totalDoubled = JSON.parse(JSON.stringify(total)) * 2;
          let rounded = Math.ceil(totalDoubled * 1e1) / 10;
          if (firstClicked == "firstClicked" && rounded > 1.4) {
            // when opened for the first time make sure the tree is still visible on map
            rounded = 1.4;
          } else if (rounded > 2) {
            // make sure the modal doesnt open completely because otherwise it overflows on top
            rounded = 1.9;
          } else if (rounded < 0.4) {
            rounded = 0.4
          } 

          //   store.state.cappedBreakpoints = store.state.breakpoints;
          store.state.breakPointRelativeToContent = rounded / 2;
          //console.log(total);
          console.log(rounded / 2);
          //console.log(store.state.breakPointRelativeToContent);
          //  setTimeout(() => {
          store.state.cappedBreakpoints = store.state.breakpoints.filter(
            function (x: any) {
              return x <= store.state.breakPointRelativeToContent;
            }
          );
          console.log("store.state.cappedBreakpoints");
          console.log(store.state.returnCappedBreakpoints());
          console.log("store.state.breakPointRelativeToContent");
          console.log(store.state.breakPointRelativeToContent);
          console.log("store.state.breakPoints");
          console.log(store.state.breakPoints);
          //    }, 100);

          /* store.state.cappedBreakpoints = store.state.breakpoints.filter(function (x:any) {
            return x <= store.state.breakPointRelativeToContent;
          }); */
          /* store.commit("setCurrentSegment", null);
          setTimeout(()=>{
            store.commit("setCurrentSegment", "specimen");
          },300) */
        }
      }

      if (groupSpecific.collaborative) {
        // count how many items will be displayed in the list
        /*  let itemsCount = 0;
        Object.values(groupSpecific.collaborative).forEach((property: any) => {
          if (property != "specimen_uid") {
            itemsCount = itemsCount + Object.values(property).length;
          }
        });
        // each item has a height of 80.5 px
        const listHeight = itemsCount * 80.5;
        const specimenDetailsHeader = 100;
        const specimenDetailsSegmentedButtons = 60; */
        /* total =
          (listHeight +
            specimenDetailsHeader +
            specimenDetailsSegmentedButtons) /
          window.innerHeight;
        // return as a fraction of the windows height
      //console.log("total");
      //console.log(total);
      //console.log(total.toFixed(1)); */
        /* if (total < 0.2) {
          store.state.breakPointRelativeToContent = 0.2;
        } else if (total < 0.25) {
          store.state.breakPointRelativeToContent = 0.25;
        } else {
          store.state.breakPointRelativeToContent = 0.5;
        } */
        //  store.state.breakPointRelativeToContent = total.toFixed(1);
      } else if (groupSpecific.omhm) {
        store.state.breakPointRelativeToContent = 0.95;
      } else {
        //     store.state.breakPointRelativeToContent = 0.5;
        //     store.state.breakPointRelativeToContent = total.toFixed(1);
      }
      const modalRef: any = document.querySelector(
        ".modal-content-specimen-details"
      );
      //console.log(modalRef);
      if (modalRef) {
        setTimeout(() => {
          modalRef.setCurrentBreakpoint(
            store.state.breakPointRelativeToContent
          );
        }, 100);
      } else {
        //console.log("no modal ref");
      }
      const calculateOffset = () => {
        specimenDetailsContentElement =
          document.getElementById("sideMenuSpecimen");
        if (specimenDetailsContentElement) {
          /* //console.log(
            specimenDetailsContentElement.offsetHeight / window.innerHeight
          );
        //console.log(
            specimenDetailsContentElement.offsetHeight / window.innerHeight <
              0.5
          );
        //console.log(
            specimenDetailsContentElement.offsetWidth / window.innerWidth
          );
        //console.log(
            specimenDetailsContentElement.offsetWidth / window.innerWidth > 0.65
          ); */
        }
        if (
          window.innerHeight > window.innerWidth &&
          ((specimenDetailsContentElement &&
            specimenDetailsContentElement.offsetWidth / window.innerWidth >
            0.65) ||
            (specimenDetailsContentElement &&
              specimenDetailsContentElement.offsetHeight / window.innerHeight <
              0.5))
        ) {
          //portrait and there is not enough free space on the right or above
          if (Math.ceil(total * 1e1) / 10 > 0.5) {
            return [0, -window.innerHeight / 3.5];
          } else if (Math.ceil(total * 1e1) / 10 != 1) {
            return [0, -150];
          } else {
            return [0, 0];
          }
        } else {
          //landscape
          // offset to the left based on element width if no featuredProject is displayed
          if (specimenBasicInfosContentElement) {
            if (!store.state.showSelectedFeaturedProject) {
              return [specimenBasicInfosContentElement.offsetWidth / -2, 0];
            } else {
              return [0, -150];
            }
          }
        }
      };
      // center map on clicked specimen
      setTimeout(() => {
        //console.log("resize and flyto");
        //console.log((total / -2) * 1000);
        store.state.mapboxMap.resize();
        if (
          store.state.fetchedSpecimen &&
          store.state.fetchedSpecimen.geojsonFeature
        )
          store.state.mapboxMap.flyTo({
            center:
              store.state.fetchedSpecimen.geojsonFeature.geometry.coordinates,
            offset: calculateOffset(),
            zoom:
              store.state.mapboxMap.getZoom() < 18
                ? 18
                : store.state.mapboxMap.getZoom(),
            essential: false, // this animation is considered essential with respect to prefers-reduced-motion
          });
      }, 100);
    } else {
      // store.state.breakPointRelativeToContent = 0.3;
      console.log("NO FORMATTED SPECIMEN");
      store.state.breakPointRelativeToContent = total.toFixed(1);
    }
  },
  calculateSheetModalBreakpoints: () => {
    //console.log("window.innerHeight");
    //console.log(window.innerHeight);
    state.initialBreakpoint = 170 / window.innerHeight;
    //console.log(state.initialBreakpoint);
    state.secondaryBreakpoint = 650 / window.innerHeight;
    return [0, state.initialBreakpoint, state.secondaryBreakpoint, 1];
  },
  calculateNearestSpecimenSheetModalBreakpoints: () => {
    //console.log("window.innerHeight");
    //console.log(window.innerHeight);
    state.initialBreakpointForNearestSpecimen = 180 / window.innerHeight;
    //console.log(state.initialBreakpoint);
    state.secondaryBreakpointForNearestSpecimen = 600 / window.innerHeight;
    return [
      0,
      state.initialBreakpointForNearestSpecimen,
      state.secondaryBreakpointForNearestSpecimen,
      1,
    ];
  },
  chosenQueriesList: [],
  chosenActivePlantationIndex: null,
  chosenSpecimenMarkerFirestoreId: null,
  chosenCodeForAvatar: "",
  cleIdentification: null,
  cleIdentificationChosenSpecies: null,
  collaborativeFormObjModel: null,
  compassDir: 0,
  createGeoJSONCircle: (center: any, radiusInKm: number, points: number) => {
    if (!points) points = 64;

    const coords = {
      latitude: center[1],
      longitude: center[0]
    };

    const km = radiusInKm;

    const ret = [];
    const distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
    const distanceY = km / 110.574;

    let theta: any, x: any, y: any;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [{
          "type": "Feature",
          "geometry": {
            "type": "Polygon",
            "coordinates": [ret]
          }
        }]
      }
    };
  },
  currentPosition: null,
  currentMapStyle: "dark-v11",
  currentUIAppearence: "dark-v11",
  createOrModifySpecimen: "modify",
  currentProjectID: null,
  currentProjectPlantations: null,
  currentSegmentForProjectView: "activePlantationList",
  currentUserLocation: null,
  currentSegment: "requetes",
  deactivateCompassMode: () => {
    store.state.showCompass = false;
    // store.state.compassDir = null;
    // tilt camera
    store.state.mapboxMap.jumpTo({
      pitch: 0,
    });
    if (store.state.orientationHandler) {
      //orientationHandler.remove();
      // myButton.removeEventListener("click", showCompassClickEventHandler);
      // Motion.removeAllListeners();
      store.state.mapboxMap.easeTo({
        bearing: 0,
      });
    }
  },
  deletedSpecimen: null,
  disableFitBounds: true,
  displayedSpecimens: null,
  drawRadiusCircleOnMap: (map: any) => {
    if (!map) {
      if (!state.mapboxMap) {
        return
      }
      map = state.mapboxMap;
    }
    const radiusCircleLayer = map.getLayer("radiusCircleLayer");
    if (typeof radiusCircleLayer !== "undefined") {
      map.removeLayer("radiusCircleLayer");
    }
    const radiusCircleSourc = map.getSource("radiusCircleSource");
    if (typeof radiusCircleSourc !== "undefined") {
      map.removeSource("radiusCircleSource");
    }
    const radiusCircleSource = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [map.getCenter().lng, map.getCenter().lat],
          },
        },
      ],
    };

    // Add a single point to the map
    map.addSource("radiusCircleSource", {
      type: "geojson",
      data: radiusCircleSource,
    });
    map.addLayer({
      id: "radiusCircleLayer",
      type: "circle",
      source: "radiusCircleSource",
      paint: {
        "circle-emissive-strength": 1,
        "circle-color": "orangered",
        "circle-opacity": 0,
        "circle-radius": [
          "interpolate",
          ["linear"],
          map.getZoom(),
          10,
          3 * (store.state.queryOptions.radius / 100),
          11,
          5 * (store.state.queryOptions.radius / 100),
          12,
          10 * (store.state.queryOptions.radius / 100),
          13,
          15 * (store.state.queryOptions.radius / 100),
          14,
          30 * (store.state.queryOptions.radius / 100),
          15,
          60 * (store.state.queryOptions.radius / 100),
          16,
          120 * (store.state.queryOptions.radius / 100),
          17,
          240 * (store.state.queryOptions.radius / 100),
          18,
          500 * (store.state.queryOptions.radius / 100),
          19,
          900 * (store.state.queryOptions.radius / 100),
          20,
          1850 * (store.state.queryOptions.radius / 100),
          21,
          3800 * (store.state.queryOptions.radius / 100),
          22,
          7000 * (store.state.queryOptions.radius / 100),
        ],
        "circle-stroke-width": 0.5,
        "circle-stroke-opacity": 0,
        "circle-stroke-color": "orangered",
      },
    });
    map.setPaintProperty('radiusCircleLayer', 'circle-stroke-opacity-transition', {
      duration: 250
    });
    map.setPaintProperty('radiusCircleLayer', 'circle-stroke-opacity', 1);
  },
  editingTask: { name: "", prop: "" },
  elementsForGuidedTour: {},
  fallbackIndex: 0,
  featuredProjects: null,
  featuredProjectsList: null,
  likeTagsInSelection: [],
  reportsInSelection: [],
  observationsInSelection: [],
  fetchedSpecimensProjectIDsListAsFeatureCollection: {
    features: [],
    type: "FeatureCollection",
  },
  fetchedSpecimensProjectIDsListAsObject: null,
  fetchedSpecimen: null,
  fetchedSpecimens: [],
  fetchedSpecimensObj: null,
  fetchedSpecimensAsFeatureCollection: {
    type: "FeatureCollection",
    features: [],
  },
  fetchedSpecimensBySpecies: [],
  fetchedSpecimensByGenus: [],
  fetchedSpecimensByGenusChartLabels: [],
  fetchedSpecimensByGenusChartData: [],
  fetchingInProgess: false,
  fetchedSoverdiProjects: null,
  fetchedProject: null,
  fetchAllSpecimensFromCurrentProjectEnabled: false,
  firebaseApp: null,
  formattedSpecimen: null,
  geoHashesQueryEnabled: true,
  geoHashesToQuery: null,
  googleMap: null,
  googleMapsServices: null,
  goToAddNewSpecimen: (item: any, fromSpeciesList: any) => {
    //console.log(state.modalNav);
    if (!item.essenceCode) {
      // comes from the species list
      item.essenceCode = item.code;
      item.imageName = item.code.slice(0, 2);
    }
    state.cleIdentificationChosenSpecies = item;
    // open specimen form
    //    store.state.currentSegment == 'specimen';
    store.dispatch("formatNewSpecimen").then(function () {
      state.formattedSpecimen.essence = item.essenceCode;
      state.formattedSpecimen.nameFr =
        state.allEssencesDictionary[item.essenceCode].fr.name;
      state.formattedSpecimen.nameLa =
        state.allEssencesDictionary[item.essenceCode].la;
      state.formattedSpecimen.owner = state.user.displayName;
      state.formattedSpecimen.plant_date = new Date().toISOString();
      if (!fromSpeciesList) {
        // state.openNewSpecimenDetailsForm();
        state.modalNav.push(CleAddNewSpecimen, {
          // these come across as properties on the component
          modalNav: state.modalNav,
        });
      } else {
        store.commit("setShowCleAddNewSpecimen", true);
      }
    });
  },
  groupInfos: null,
  groupSpecificTreeModel: null,
  groupQueriesList: null,
  habitationsOMHMQueryEnabled: true,
  historyCollection: null,
  imageLoadOnError: (e: any) => {
    e.target.src = "../assets/arbres/XX_feuille.png";

    /* store.state.fallbackIndex++;
    console.log(store.state.fallbackIndex);
    if (!store.state.formattedSpecimen) {
      e.target.src = "../assets/arbres/XX_feuille.png";
      return
    }
    if (store.state.fallbackIndex > 3) {
      return;
    }
    if (store.state.fallbackIndex == 1) {
      e.target.src =
        "../assets/arbres/" +
        store.state.formattedSpecimen.essence.slice(0, 4) +
        "_feuille.png";
    } else if (store.state.fallbackIndex == 2) {
      e.target.src =
        "../assets/arbres/" +
        store.state.formattedSpecimen.essence.slice(0, 2) +
        "_feuille.png";
    } else {
      e.target.src = "../assets/arbres/XX_feuille.png";
      store.state.fallbackIndex = 0;
    } */
  },
  initialBreakpoint: 0.4,
  initialBreakPointForNearestSpecimen: 0.16,
  initialFetch: true,
  isLoggedIn: null,
  lastPlantedFirestoreID: null,
  lastGeoqueryCoordinatesLngLat: null,
  mapboxMap: null,
  mapCenter: [-73.5542, 45.5365],
  mapFadeInAnimation: null,
  modalNav: { value: [null] },
  nearestOmhmAddress: null,
  nearestSpecimen: { distanceFromUser: null },
  nearestSpecimenModal: null,
  newSpecimenCounterId: null,
  omhmAddresses: null,
  omhmAddressesTurfPoints: null,
  openSpecimenDetails: function (elOffsetHeight: any) {
    //console.log("opening specimenDetails");
    //console.log(store.state.sideMenuRef);

    const slideInAnimation = createAnimation()
      .addElement(store.state.sideMenuRef)
      .duration(250)
      //   .fromTo("opacity", "0", "1");
      .fromTo("transform", "translateY(1000px)", `translateY(0)`);
    state.specimenDetailsContentElementHeight = elOffsetHeight;
    //console.log(state.specimenDetailsContentElementHeight);

    //slideOutAnimation.play();
    slideInAnimation.play();
  },
  openNewSpecimenDetailsForm: (species: any) => {
    //console.log("trying to open newspecimendetailsform");
    // make sure element is hidden before animation
    /* const slideDownAnimation = createAnimation()
      .addElement(store.state.sideMenuRef)
      .duration(0)
      //   .fromTo("opacity", "0", "1");
      .fromTo("transform", "translateY(0)", `translateY(1000px)`);
    slideDownAnimation.play(); */
    store.commit("setCurrentSegment", "specimen");
    store.commit("setShowRefreshMap", false);
    store.commit("setActivatedTabQuerySelector", ".add-specimen");
    // adjust specimen detail height
    if (store.state.userGroupMembership != "oomhm") {
      setTimeout(() => {
        store.state.relativeToContent();
      }, 50);
    }

    if (species) {
      // already fill the species property
      store.state.formattedSpecimen.essence = species.code;
      store.state.formattedSpecimen.nameFr = species.fr.name;
      store.state.formattedSpecimen.plant_date = new Date().toISOString();
    }

    // wait for the element to be rendered
    /*  setTimeout(() => {
      const sideMenuSpecimenElement = document.getElementById("sideMenuSpecimen");
      if (sideMenuSpecimenElement)
        state.specimenDetailsContentElementHeight = sideMenuSpecimenElement.offsetHeight;

      if (
        !sideMenuSpecimenElement ||
        sideMenuSpecimenElement.offsetHeight == 0 || state.specimenDetailsContentElementHeight == 0
      ) {
        setTimeout(() => {
        //console.log("element not rendered yet!");

          if (sideMenuSpecimenElement) {
          //console.log(sideMenuSpecimenElement.offsetHeight);

            state.openSpecimenDetails(
              sideMenuSpecimenElement.offsetHeight
            );
          }
        }, 500);
      } else {
      //console.log("element already rendered!");
      //console.log(sideMenuSpecimenElement.offsetHeight);
        state.specimenDetailsContentElementHeight = sideMenuSpecimenElement.offsetHeight;
      //console.log(state.specimenDetailsContentElementHeight);
        state.openSpecimenDetails(
          sideMenuSpecimenElement.offsetHeight
        );
      }

    }, 100); */
  },
  openPicturesSlider: (i: number, picturesToSlide: any, plantNetResult: any) => {
    if (!picturesToSlide) {
      const imageName = store.state.cleIdentificationChosenSpecies.imageName;
      store.state.picturesToSlide = [{ path: '../assets/arbres/' + imageName + '_feuille.png', id: null }, { path: '../assets/arbres/' + imageName + '_fleur.png', id: null }, { path: '../assets/arbres/' + imageName + '_fruit.png', id: null }];
    } else {
      const tempArray: any = [];
      picturesToSlide.forEach((picture: any) => {
        if (picture.organ) {
          // these come from plantNet. No need for an ID because it cannot be deleted by the user
          tempArray.push({
            path: picture.url.o,
            citation: picture.citation,
            organ: picture.organ,
            plantNetResult: plantNetResult
          })
        } else {
          tempArray.push({ path: store.state.returnImgUrl(picture._id), id: picture })
        }
      })
      store.state.picturesToSlide = tempArray;
    }
    store.state.picturesToSlideInitialSlideIndex = i;
    store.state.showPicturesSlider = true;
  },
  orientationHandler: null,
  password: null,
  pictureSet: {},
  picturesToSlide: [],
  profile: null,
  projectsQueryEnabled: null,
  propertyAnnotations: null,
  projectsList: {},
  projectsListAsArray: null,
  queryFiltersList: {
    basicQueryFilters: [
      {
        fieldName:
          "geojsonFeature.properties.classification.WONTBESEND JUST FOR UI",
        operator: "==",
        enteredValue: null,
        type: "string",
        inputType: "text",
        displayName: "classification",
        placeholder: "Choisissez dans la liste",
      },
      {
        fieldName: "geojsonFeature.properties.classification.genus",
        operator: "==",
        enteredValue: null,
        type: "string",
        inputType: "text",
        displayName: "genre",
        placeholder: "Choisissez dans la liste",
      },
      {
        fieldName: "geojsonFeature.properties.classification.species",
        operator: "==",
        enteredValue: null,
        type: "string",
        inputType: "text",
        displayName: "espèce",
        placeholder: "Choisissez dans la liste",
      },
      {
        fieldName: "geojsonFeature.properties.classification.cultivar",
        operator: "==",
        enteredValue: null,
        type: "string",
        inputType: "text",
        displayName: "cultivar",
        placeholder: "Choisissez dans la liste",
      },
      {
        fieldName: "geojsonFeature.properties.createdOn.year",
        operator: "==",
        enteredValue: null,
        type: "number",
        inputType: "number",
        displayName: "année",
        placeholder: "Entrez l'année d'identification",
      },
      {
        fieldName: "geojsonFeature.properties.owner",
        operator: "==",
        enteredValue: null,
        type: "string",
        inputType: "dropDownMenu",
        displayName: "identifié par",
        placeholder: "Choisissez dans la liste",
      },
      {
        fieldName: "geojsonFeature.properties.hasPhoto",
        operator: "==",
        enteredValue: false,
        type: "boolean",
        inputType: "text",
        displayName: "photo d'identification",
        placeholder: "",
        displayValue: " "
      },
      {
        collection: "collaborative",
        fieldName: "has_likeTags",
        operator: "==",
        enteredValue: false,
        type: "boolean",
        inputType: "text",
        displayName: "favori d'un ou plusieurs usagers",
        placeholder: "",
        displayValue: " "
      },
      {
        collection: "collaborative",
        fieldName: "has_likes",
        operator: "==",
        enteredValue: false,
        type: "boolean",
        inputType: "text",
        displayName: "remarques",
        placeholder: "",
        displayValue: " "
      },
      {
        collection: "collaborative",
        fieldName: "has_reports",
        operator: "==",
        enteredValue: false,
        type: "boolean",
        inputType: "text",
        displayName: "signalements",
        placeholder: "",
        displayValue: " "
      },
    ],
    groupQueryFilters: [],
  },
  queryOptions: {
    collectionName: "specimens",
    limit: 0,
    radius: 100,
    filters: [],
  },
  radius: 300,
  repositionSpecimen: false,
  returnErrorMessageInFrench: (errCode: string, err: any) => {
    console.log(errCode);
    if (!errCode && !err) {
      alert("Code d'erreur manquant.");
      return;
    } else if (err && err.message && err.message.indexOf("No user is signed in") > -1) {
      alert("Vous devez vous déconnecter et vous reconnecter pour effectuer cette opération.");
      return
    }
    if (errCode.indexOf("auth/weak-password") > -1) {
      return "Votre mot de passe doit avoir au moins 6 caractères.";
    } else if (errCode.indexOf("auth/invalid-email") > -1) {
      return "L'adresse email n'est pas valide.";
    } else if (errCode.indexOf("auth/wrong-password") > -1) {
      return "Vous n'avez pas entré le bon mot de passe.";
    } else if (errCode.indexOf("auth/missing-email") > -1) {
      return "Vous n'avez pas entré votre courriel.";
    } else if (errCode.indexOf("auth/missing-password") > -1) {
      return "Vous n'avez pas entré votre mot de passe.";
    } else if (errCode.indexOf("auth/internal-error") > -1) {
      return "Une erreur s'est produite.  L'opération n'a pas été complétée.";
    } else if (errCode.indexOf("auth/user-not-found") > -1) {
      return "Aucun compte avec ce courriel n'a été trouvé.  Assurez-vous d'avoir entré le bon courriel ou créez un nouveau compte avec ce courriel.";
    } else if (errCode.indexOf("auth/too-many-requests") > -1) {
      return "L'application a initié trop de requêtes. Si vous tentez de valider votre email, consultez votre boîte de réception, le lien a été envoyé.";
    } else if (errCode.indexOf("auth/requires-recent-login") > -1) {
      return "Vous devez vous authentifier avant d'effectuer cette opération. Déconnectez-vous puis reconnectez-vous avec vos email et mot de passe actuels.";
    } else {
      return "Une erreur s'est produite. " + errCode;
    }
  },
  returnImgUrl: (imgId: any) => {
    if (imgId && imgId.indexOf(".jpg") == -1) {
      // add jpg as file extension to standardize it across all saved pictures ids
      imgId = imgId + ".jpg";
    }
    return (
      "https://firebasestorage.googleapis.com/v0/b/soverdiwebsite.appspot.com/o/specimens%2F" +
      imgId +
      "?alt=media"
    );
  },
  returnPlantationYearForPopup: (specimen: any) => {
    if (specimen.properties.owning_group == "villeMontreal") {
      return specimen.properties.createdOn.year
        ? " planté en " + specimen.properties.createdOn.year
        : "";
    } else if (
      specimen.properties.groupSpecific.plantation &&
      specimen.properties.groupSpecific.plantation.plantationDone
    ) {
      return specimen.properties.createdOn.year
        ? " planté en " + specimen.properties.createdOn.year
        : "";
    } else if (
      specimen.properties.new_tree &&
      specimen.properties.new_tree_plantationYear
    ) {
      return specimen.properties.new_tree_plantationYear;
    } else if (specimen.properties.new_tree) {
      return specimen.properties.createdOn.year
        ? " planté en " + specimen.properties.createdOn.year
        : "";
    } else {
      return "";
    }
  },
  returnOwner: (specimen: any) => {
    let specimenData;
    if (!specimen) {
      specimenData = store.state.formattedSpecimen;
    } else {
      specimenData = specimen.properties;
    }
    if (
      specimenData.owning_group ==
      store.state.userGroupMembership &&
      specimenData.owning_group != "singleUser"
    ) {
      return specimenData.owning_group;
    } else if (specimenData.groupSpecific.soverdi) {
      return specimenData.groupSpecific.soverdi.plantation.project ? specimenData.groupSpecific.soverdi.plantation.project : specimenData.owner;
    } else if (!specimenData.owner) {
      return "anonyme";
    } else {
      return specimenData.owner;
    }
  },
  returnPlantationYear: (specimen: any) => {
    if (specimen.properties.owning_group == "villeMontreal") {
      return specimen.properties.createdOn.year
        ? specimen.properties.createdOn.year
        : "";
    } else if (
      specimen.properties.groupSpecific.soverdi && specimen.properties.groupSpecific.soverdi.plantation &&
      specimen.properties.groupSpecific.soverdi.plantation.plantationDone
    ) {
      return specimen.properties.createdOn.year
        ? specimen.properties.createdOn.year
        : "";
    } else if (
      specimen.properties.new_tree &&
      specimen.properties.new_tree_plantationYear
    ) {
      return specimen.properties.new_tree_plantationYear;
    } else if (specimen.properties.new_tree) {
      return specimen.properties.createdOn.year
        ? specimen.properties.createdOn.year
        : "";
    } else {
      return "";
    }
  },
  savedSpecimen: null,
  secondaryBreakpoint: 0.5,
  secondaryBreakpointForNearestSpecimen: 0.5,
  selectedFilter: { displayName: "", enteredValue: "" },
  selectedIdentifiedBy: null,
  selectedSpeciesFilter: {},
  selectedSpecimenHistory: null,
  // the Preferences API only accepts strings
  setLocalPreferences: async (keyName: string, value: string) => {
    console.log("setPreferences");
    await Preferences.set({
      key: keyName,
      value: value,
    });
  },
  sideMenuRef: null,
  showCleAddNewSpecimen: false,
  showCompass: false,
  showDateTime: false,
  showDeadSpecimensEnabled: null,
  showFetchedSpecimensStats: false,
  showGeocoder: false,
  showGuidedTour: false,
  doNotShowGuidedTourStatusFromLocalPreferences: false,
  showKeyboard: false,
  showModal: false,
  showNewSpecimenModal: false,
  showNearestSpecimenModal: false,
  showQueryFilters: false,
  showRefreshMap: true,
  showSpeciesListProgressBar: false,
  selectedFeaturedProject: null,
  showSelectedFeaturedProject: false,
  showSideMenuForProject: false,
  showSpeciesList: false,
  showTryingToAcquirePosition: false,
  showCompassClickEventHandler: async () => {
    console.log("hallo");
    if ((DeviceMotionEvent as any).requestPermission && typeof (DeviceMotionEvent as any).requestPermission === "function") {
      // Note: You can use "DeviceOrientationEvent" here as well
      await (DeviceMotionEvent as any).requestPermission().catch((error: any) => {
        console.log("Error getting sensor permission: %O", error);
        // alert("Error getting sensor permission");
        return; // Exit out of logic
      });
    }
    let previousCompassDir = 0;
    // Once the user approves, can start listening:
    store.state.orientationHandler = await Motion.addListener("orientation", (event: any) => {
      // alert("start listening to orientation");

      // console.log("Device motion event:", event);
      let compassDir;
      const easing = (t: any) => t * (2 - t);
      if (event.webkitCompassHeading) {
        // Safari browser
        compassDir = event.webkitCompassHeading;
        //console.log("safari");
        //    alert("webkitCompass");
      } else {
        compassDir = event.alpha;
        //console.log("not safari");
        //console.log(JSON.stringify(event));
      }
      /* console.log("compassDir");
      console.log(compassDir); */
      // reduce precision of compass to mitigate jerkiness of the map when this function is enabled
      store.state.compassDir = parseInt(compassDir);
      // small adjustment towards the east because the map seems to rotate a bit too much towards the west
      //store.state.compassDir = store.state.compassDir - 20;
      if (store.state.compassDir < 0) {
        store.state.compassDir + 360;
      }
      if (
        previousCompassDir !== store.state.compassDir && previousCompassDir !== store.state.compassDir + 1 && previousCompassDir !== store.state.compassDir - 1 && previousCompassDir !== store.state.compassDir + 2 && previousCompassDir !== store.state.compassDir - 2
      ) {
        previousCompassDir = store.state.compassDir;
        console.log('applying compassDir');
        console.log(store.state.compassDir);
        store.state.mapboxMap.setLayoutProperty('layer-with-pulsing-dot', 'icon-rotate', store.state.compassDir);
        if (store.state.showCompass) {
          store.state.mapboxMap.setLayoutProperty('layer-with-pulsing-dot', 'icon-rotate', 0);
          store.state.mapboxMap.easeTo({
            bearing: store.state.compassDir,
            center: store.state.mapCenter,
          });
        }
      }
      /* store.state.mapboxMap.easeTo({
        bearing: store.state.compassDir,
        center: store.state.mapCenter,
        curve: 1,
        speed: 0.1,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      }); */

    });
    console.log('store.state.orientationHandler');
    console.log(store.state.orientationHandler);
  },
  sideMenuDisabled: true,
  specimensCounter: { fromPublicData: 0, addedUsingBranche: 0, total: 0 },
  specimenDetailsContentElementHeight: 0,
  tasksArray: null,
  // Add or remove the "dark" class based on if the media query matches
  toggleDarkTheme: (shouldAdd: any, currentUIAppearence: any) => {
    console.log(shouldAdd);
    console.log(currentUIAppearence);
    //document.documentElement.setAttribute("data-theme", "dark");
    //document.body.classList.toggle("dark", shouldAdd);
   // alert("toggleDarkTheme");
    if (store.state.currentUIAppearence == "auto" || store.state.currentUIAppearence == null) {
      store.state.setLocalPreferences("UIAppearence", "auto");
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        if (document.documentElement.classList.contains("light")) {
          document.documentElement.classList.remove("light")
        }
        document.documentElement.classList.add("dark")
        shouldAdd = true;
      } else {
        if (document.documentElement.classList.contains("dark")) {
          document.documentElement.classList.remove("dark")
        }
        document.documentElement.classList.add("light")
        shouldAdd = false;
      }
    } else if (store.state.currentUIAppearence == "light") {
      if (document.documentElement.classList.contains("dark")) {
        document.documentElement.classList.remove("dark")
      }
      document.documentElement.classList.add("light");
      store.state.setLocalPreferences("UIAppearence", "light");
    } else if (store.state.currentUIAppearence == "dark") {
      if (document.documentElement.classList.contains("light")) {
        document.documentElement.classList.remove("light")
      }
      document.documentElement.classList.add("dark")
      store.state.setLocalPreferences("UIAppearence", "dark");
    }



    //document.body.classList.toggle("dark", shouldAdd);

    // also toggle the map
    if (store.state.mapboxMap) {
      if (shouldAdd && store.state.currentMapStyle != "dark-v11") {
        store.state.currentMapStyle = "dark-v11";
        mapService.setMapStyle("dark-v11");
        /* store.state.mapboxMap.setConfigProperty(
          store.state.currentMapStyle,
          "lightPreset",
          "night"
        ); */
      } else if (!shouldAdd && store.state.currentMapStyle != "light-v11") {
        //store.state.mapboxMap.setConfigProperty(store.state.currentMapStyle, "lightPreset", "day");
        store.state.currentMapStyle = "light-v11";
        mapService.setMapStyle("light-v11");
      }
    }
  },
  treeIconsList: [
    "AB",
    "AC",
    "ACGI",
    "ACPLCO",
    "ACRU",
    "AE",
    "AL",
    "AM",
    "ARBU",
    "BE",
    "CA",
    "CE",
    "CG",
    "CR",
    "CT",
    "FA",
    "FR",
    "GI",
    "GL",
    "GY",
    "JU",
    "LA",
    "MA",
    "MG",
    "OS",
    "PH",
    "PI",
    "PLATEB",
    "PN",
    "PO",
    "PONIIT",
    "PR",
    "PS",
    "QU",
    "QUBI",
    "RO",
    "SA",
    "SO",
    "SY",
    "TH",
    "TI",
    "UL",
  ],
  treeIconsOptions: { trees: true },
  uniqueHabitationNames: null,
  updatedSpecimen: null,
  user: {
    email: "",
    displayName: null,
    password: "",
  },
  userGroupMembership: "anonyme",
  userInfosToDisplay: "anonyme",
  userReadOnly: null,
  userEditOnly: null,
  userHasMembership: false,
  userStats: {
    reports: 0,
    likes: 0,
    likeTags: 0,
    identifiedSpecimens: 0,
  },
  hasOwnership: false,
};

const getters = {
  isLoggedIn: (state: { isLoggedIn: any }) => {
    return state.isLoggedIn;
  },
  /* basicQueriesList: state => {
    return state.basicQueriesList
  }, */
  profile: (state: { profile: any }) => {
    return state.profile;
  },
  formattedSpecimen: (state: { formattedSpecimen: any }) => {
    return state.formattedSpecimen;
  },
  fetchingInProgess: (state: { fetchingInProgess: any }) => {
    return state.fetchingInProgess;
  },
  lastPlantedFirestoreID: (state: { lastPlantedFirestoreID: any }) => {
    return state.lastPlantedFirestoreID;
  },
};

const mutations = {
  setElementsForGuidedTour: (state: { elementsForGuidedTour: any }, item: any) => {
    console.log(item.name);
    console.log(item.ref);
    state.elementsForGuidedTour[item.name] = item.ref.value;
    console.log(state.elementsForGuidedTour[item.name]);
  },
  setShowMapMenu: (state: { showMapMenu: boolean }, value: boolean) => {
    state.showMapMenu = value;
  },
  setShowGuidedTour: (state: { showGuidedTour: boolean }, value: boolean) => {
    state.showGuidedTour = value;
  },
  setAuth: (state: { auth: any }, value: any) => {
    //console.log("setting auth in store: " + value);
    state.auth = value;
  },
  setFeaturedProjects: (state: { featuredProjects: any }, value: any) => {
    console.log("setting featuredProjets in store: " + value);
    state.featuredProjects = value;
  },
  setFeaturedProjectsList: (state: { featuredProjectsList: any }, value: any) => {
    console.log("setting featuredProjetsList in store: " + value);
    state.featuredProjectsList = value;
  },
  setProjectIDsMatches: (state: { projectIDsMatches: any }, value: any) => {
    //console.log("setting auth in store: " + value);
    state.projectIDsMatches = value;
  },
  setQueryOptions: (state: { queryOptions: any }, payload: any) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.queryOptions.hasOwnProperty(payload.key)) {
      state.queryOptions[payload.key] = payload.value;
    } else {
      console.log("queryOptions key does not exist");
    }
  },
  setBreakPointRelativeToContent: (
    state: { breakPointRelativeToContent: any },
    value: any
  ) => {
    //console.log("setBreakPointRelativeToContent");
    //console.log(value);
    state.breakPointRelativeToContent = value;
  },
  setGoogleMapsServices: (state: { googleMapsServices: any }, value: any) => {
    // console.log("setGoogleMapsServices");
    // console.log(value);
    state.googleMapsServices = value;
  },
  setGoogleMap: (state: { googleMap: any }, value: any) => {
    // console.log("setGoogleMapInstance");
    // console.log(value);
    state.googleMap = value;
  },
  setLastPlantedFirestoreID: (
    state: { lastPlantedFirestoreID: any },
    value: any
  ) => {
    // console.log("setLastPlantedFirestoreID");
    // console.log(value);
    state.lastPlantedFirestoreID = value;
  },
  setShowActivePlantationListOnMap: (
    state: {
      showActivePlantationListOnMap: boolean;
      chosenActivePlantationIndex: any;
    },
    value: boolean
  ) => {
    // console.log("setShowActivePlantationListOnMap");
    // console.log(value);
    if (value === false) {
      state.showActivePlantationListOnMap = false;
    } else {
      state.showActivePlantationListOnMap = true;
    }
    state.chosenActivePlantationIndex = value;
  },
  setUserInfosToDisplay: (state: { userInfosToDisplay: any }, value: any) => {
    // console.log("userInfosToDisplay");
    // console.log(value);
    state.userInfosToDisplay = value;
  },
  setUserDisplayName: (state: { user: { displayName: any } }, value: any) => {
    // console.log("setUserDisplayName");
    // console.log(value);
    state.user.displayName = value;
  },
  setRadius: (state: { radius: any }, value: any) => {
    // console.log("setRadius");
    // console.log(value);
    state.radius = value / 1000;
  },
  setFetchAllSpecimensFromCurrentProjectEnabled: (
    state: { fetchAllSpecimensFromCurrentProjectEnabled: any },
    value: any
  ) => {
    // console.log("setFetchAllSpecimensFromCurrentProjectEnabled");
    // console.log(value);
    state.fetchAllSpecimensFromCurrentProjectEnabled = value;
  },
  setDoNotShowGuidedTourStatusFromLocalPreferences: (state: { doNotShowGuidedTourStatusFromLocalPreferences: string }, value: string) => {
    console.log("setDoNotShowGuidedTourStatusFromLocalPreferences");
    console.log(value);
    state.doNotShowGuidedTourStatusFromLocalPreferences = value ? value : 'false';
  },
  setDisplayedSpecimens: (state: { displayedSpecimens: any }, value: any) => {
    // console.log("setdisplayedSpecimens");
    // console.log(value);
    state.displayedSpecimens = value;
  },
  setFetchedSoverdiProjects: (
    state: { fetchedSoverdiProjects: any },
    value: any
  ) => {
    // console.log("setFetchedSoverdiProjects");
    // console.log(value);
    state.fetchedSoverdiProjects = value;
  },
  setNewSpecimenCounterId: (
    state: { newSpecimenCounterId: any },
    value: any
  ) => {
    // console.log("setNewSpecimenCounterId");
    // console.log(value);
    state.newSpecimenCounterId = value;
  },
  setFetchedOmhmAddressesTurfPoints: (
    state: { omhmAddressesTurfPoints: any },
    value: any
  ) => {
    // console.log(value);
    state.omhmAddressesTurfPoints = value;
  },
  setBasicQueriesList: (state: { basicQueriesList: any }, value: any) => {
    // console.log("setBasicQueriesList");
    // console.log(value);
    state.basicQueriesList = value;
  },
  setGroupQueriesList: (state: { groupQueriesList: any }, value: any) => {
    // console.log("setgroupQueriesList");
    // console.log(value);
    state.groupQueriesList = value;
  },
  setNearestOmhmAddress: (state: { nearestOmhmAddress: any }, value: any) => {
    // console.log(value);
    state.nearestOmhmAddress = value;
  },
  setGeoHashesToQuery: (state: { geoHashesToQuery: any }, value: any) => {
    // console.log("geohashes to query in store");
    // console.log(value);
    state.geoHashesToQuery = value;
  },
  setHistoryCollection: (state: { historyCollection: any }, value: any) => {
    // console.log(value);
    state.historyCollection = value;
  },
  setCurrentUserLocation: (state: { currentUserLocation: any }, value: any) => {
    // console.log("setCurrentUserLocationnn");
    // console.log(value);
    state.currentUserLocation = value;
  },
  setIsLoggedIn: (state: { isLoggedIn: any }, value: string) => {
    // console.log(value + " in setIsLoggedIn function");
    state.isLoggedIn = value;
  },
  setProfile: (state: { profile: any }, profile: any) => {
    state.profile = profile;
  },
  setFetchedSpecimen: (state: { fetchedSpecimen: any }, specimenObj: any) => {
    // console.log("trying to set fetched specimen");
    // console.log(JSON.stringify(specimenObj));
    state.fetchedSpecimen = specimenObj;
  },
  setFetchedSpecimens: (state: { fetchedSpecimens: any }, specimens: any) => {
    // console.log("trying to set fetched specimens");
    // console.log(JSON.stringify(specimens));
    state.fetchedSpecimens = specimens;
  },
  setFetchedOmhmAddresses: (state: { omhmAddresses: any }, addresses: any) => {
    // console.log("trying to set fetchedOmhmAddresses");
    // console.log(addresses);
    state.omhmAddresses = addresses;
  },
  setFormattedSpecimen: (
    state: { formattedSpecimen: any },
    specimenObj: any
  ) => {
    //console.log("trying to set formatted specimen");
    //console.log(specimenObj);
    state.formattedSpecimen = specimenObj;
  },
  setSelectedSpecimenHistory: (
    state: { selectedSpecimenHistory: any },
    selectedSpecimenHistory: any
  ) => {
    // console.log("trying to set selectedSpecimenHistory");
    // console.log(selectedSpecimenHistory);
    state.selectedSpecimenHistory = selectedSpecimenHistory;
  },
  setUpdatedSpecimen: (state: { updatedSpecimen: any }, specimenObj: any) => {
    // console.log("trying to set updated specimen");
    state.updatedSpecimen = specimenObj;
  },
  setPropertyAnnotations: (
    state: { propertyAnnotations: any },
    propertyAnnotations: any
  ) => {
    // console.log("trying  to set propertyAnnotations");
    state.propertyAnnotations = propertyAnnotations;
  },
  setModalNav: (state: { modalNav: any }, value: any) => {
    //console.log("modalNav in store: " + value);
    state.modalNav = value;
  },
  setUser: (state: { user: any }, value: any) => {
    //console.log("user in store: " + value);
    state.user = value;
  },
  setUserStats: (state: { userStats: any }, value: any) => {
    console.log("userStats in store: " + value);
    if (value) {
      state.userStats = value;
    }
  },
  setProfilePicture: (
    state: { profile: { profile_pic: any } },
    profilepicture: any
  ) => {
    state.profile.profile_pic = profilepicture;
  },
  setSpecimenPicture: (
    state: {
      formattedSpecimen: {
        hasOwnProperty: (arg0: string) => any;
        photos: { url: any; _id: any }[];
        hasPhoto: boolean;
      };
    },
    specimenPicture: { url: any; filename: string }
  ) => {
    // console.log("specimenPictureUrl in store");
    // console.log(specimenPicture.url);
    // console.log(specimenPicture.filename);
    const splitFilename = specimenPicture.filename.split(".");
    // console.log(splitFilename[0]);
    if (!state.formattedSpecimen.hasOwnProperty("photos")) {
      state.formattedSpecimen.photos = [];
    }
    state.formattedSpecimen.photos.push({
      url: specimenPicture.url,
      _id: splitFilename[0],
    });
    state.formattedSpecimen.hasPhoto = true;
    // console.log("state.formattedSpecimen.photos");
    // console.log(state.formattedSpecimen.photos);
  },
  setChosenPicture: (state: { chosenPicture: any }, chosenPicture: any) => {
    // console.log("setting chosenPicture in store");
    state.chosenPicture = chosenPicture;
  },
  setSavedSpecimen: (state: { savedSpecimen: any }, savedSpecimen: any) => {
    // console.log("setting savedSpecimen in store");
    state.savedSpecimen = savedSpecimen;
  },
  setUniqueHabitationNames: (
    state: { uniqueHabitationNames: any },
    value: any
  ) => {
    //console.log("setting uniqueHabitationNames in store: " + value);
    state.uniqueHabitationNames = value;
  },
  setUserReadOnly: (state: { userReadOnly: any }, value: string) => {
    // console.log("setting user readOnly in store: " + value);
    state.userReadOnly = value;
  },
  setUserEditOnly: (state: { userEditOnly: any }, value: string) => {
    // console.log("setting user editOnly in store: " + value);
    state.userEditOnly = value;
  },
  setUserGroupMembership: (
    state: { userGroupMembership: any },
    groupMembership: any
  ) => {
    // console.log("setting user group memebership in store");
    state.userGroupMembership = groupMembership;
  },
  setGroupSpecificTreeModel: (
    state: { groupSpecificTreeModel: any },
    groupSpecificTreeModel: any
  ) => {
    // console.log("setting groupSpecificTreeModel in store");
    // console.log(groupSpecificTreeModel);
    state.groupSpecificTreeModel = groupSpecificTreeModel;
  },
  setCollaborativeFormObjModel: (
    state: { collaborativeFormObjModel: any },
    groupSpecificTreeModel: any
  ) => {
    // console.log("setCollaborativeFormObjModel in store");
    // console.log(groupSpecificTreeModel);
    state.collaborativeFormObjModel = groupSpecificTreeModel;
  },
  setGroupInfos: function (state: { groupInfos: any }, data: any) {
    // console.log("setting groupInfos in store");
    // console.log(data);
    state.groupInfos = data;
  },
  setAllEssencesDictionary: (
    state: { allEssencesDictionary: any },
    allEssencesDictionary: any
  ) => {
    //console.log("setting allEssencesDictionary in store");
    //console.log(allEssencesDictionary);
    state.allEssencesDictionary = allEssencesDictionary;
  },
  setAllEssencesDictionaryAsArray: (
    state: { allEssencesDictionaryAsArray: any },
    allEssencesDictionary: any
  ) => {
    // console.log("setting allEssencesDictionaryAsArray in store");
    // // console.log(allEssencesDictionary);
    state.allEssencesDictionaryAsArray = allEssencesDictionary;
  },
  setShowCleAddNewSpecimen: (
    state: { showCleAddNewSpecimen: any },
    showCleAddNewSpecimen: any
  ) => {
    //console.log("setting showCleAddNewSpecimen in store");
    // // console.log(allEssencesDictionary);
    state.showCleAddNewSpecimen = showCleAddNewSpecimen;
  },
  setProjectsList: (
    state: { projectsList: { [x: string]: any } },
    result: {
      hasOwnProperty: (arg0: string) => any;
      allProjects: { [x: string]: any };
      type: string;
      value: { name: string | number };
    }
  ) => {
    // console.log("setting projectsList in store ");
    // console.log(JSON.stringify(result));
    if (result.hasOwnProperty("allProjects")) {
      // server returned all matches at once
      Object.keys(result.allProjects).forEach(function (projectName) {
        //  // console.log(projectName);
        state.projectsList[projectName] = result.allProjects[projectName];
      });
    } else {
      if (result.type == "ChildRemoved") {
        delete state.projectsList[result.value.name];
      } else {
        state.projectsList[result.value.name] = result.value;
      }
    }
    // state.projectsList = projectsList;
  },
  setProjectsListAsArray: (
    state: { projectsListAsArray: any },
    projectsList: any
  ) => {
    // console.log("setting projectsListAsArray in store");
    // console.log(projectsList);
    state.projectsListAsArray = projectsList;
  },
  setHabitationsOMHMQueryEnabled(
    state: { habitationsOMHMQueryEnabled: any },
    habitationsOMHMQueryEnabled: any
  ) {
    // console.log("setting habitationsOMHMQueryEnabled in store");
    // console.log(habitationsOMHMQueryEnabled);
    state.habitationsOMHMQueryEnabled = habitationsOMHMQueryEnabled;
  },
  setProjectsQueryEnabled(state: { projectsQueryEnabled: any }, value: any) {
    // console.log("setting projectsQueryEnabled in store");
    // console.log(value);
    state.projectsQueryEnabled = value;
  },
  setShowDeadSpecimensEnabled(
    state: { showDeadSpecimensEnabled: any },
    value: any
  ) {
    // console.log("setting showDeadSpecimensEnabled in store");
    // console.log(value);
    state.showDeadSpecimensEnabled = value;
  },
  setGeoHashedQueryEnabled(state: { geoHashesQueryEnabled: any }, value: any) {
    // console.log("setting setGeoHashedQueryEnabled in store");
    // console.log(value);
    state.geoHashesQueryEnabled = value;
  },
  setFetchedProject(state: { fetchedProject: any }, value: any) {
    // console.log("setting setFetchedProject in store");
    // console.log(value);
    state.fetchedProject = value;
  },
  setActivePlantationsInProject(
    state: { activePlantationsInProject: any },
    value: any
  ) {
    //console.log("setting activePlantationsInProject in store");
    //console.log(JSON.stringify(value));
    state.activePlantationsInProject = value;
  },
  setActivePlantationsInProjectAsArray(
    state: { activePlantationsInProjectAsArray: any },
    value: any
  ) {
    // console.log("setting setActivePlantationsInProjectAsArray in store");
    // console.log(JSON.stringify(value));
    state.activePlantationsInProjectAsArray = value;
  },
  setFetchingSpecimenInProgress(state: { fetchingInProgess: any }, value: any) {
    // console.log("setting fetchingSpecimen in store");
    // console.log(value);
    state.fetchingInProgess = value;
  },
  setDeletedSpecimen(state: { deletedSpecimen: any }, value: any) {
    // console.log("setting deleted specimen in store");
    // console.log(value);
    state.deletedSpecimen = value;
  },
  setRepositionSpecimen(state: { repositionSpecimen: any }, value: any) {
    // console.log("setRepositionSpecimen");
    state.repositionSpecimen = value;
  },
  setHasOwnership(
    state: {
      hasOwnership: boolean;
      userGroupMembership: string;
      userEditOnly: any;
      user: { email: any };
      projectsList: { hasOwnProperty: (arg0: any) => any };
    },
    formattedSpecimen: {
      owning_group: string;
      groupSpecific: {
        hasOwnProperty: (arg0: string) => any;
        soverdi: {
          hasOwnProperty: (arg0: string) => any;
          lastModifiedBy: { name: any };
          plantation: { project: any };
        };
        singleUser: { lastModifiedBy: { name: any } };
      };
      _id: number;
      owner: string;
    }
  ) {
    // console.log("setHasOwnership");
    // console.log(JSON.stringify(formattedSpecimen.owning_group));
    // console.log(JSON.stringify(formattedSpecimen.groupSpecific));
    if (formattedSpecimen._id == 0) {
      // grant ownership, its a new tree
      state.hasOwnership = true;
      return null;
    }
    if (formattedSpecimen.owning_group == state.userGroupMembership) {
      // console.log("group ownership");
      /*  if (state.userGroupMembership === "soverdi") {
         // specific set of conditions for soverdi group
         if (formattedSpecimen.owner === "écoquartier") {
           // deny ownership
           state.hasOwnership = false;
           return null;
         }
         if (state.userEditOnly) {
           // console.log("editonly check");
           // console.log(state.user.email);
           if (
             formattedSpecimen.groupSpecific.hasOwnProperty("soverdi") &&
             formattedSpecimen.groupSpecific.soverdi.hasOwnProperty(
               "lastModifiedBy"
             ) &&
             formattedSpecimen.groupSpecific.soverdi.lastModifiedBy.name ==
               state.user.email
           ) {
             // //console.log(
             //     formattedSpecimen.groupSpecific.soverdi.lastModifiedBy.name
             //   );
             // specimen was created / lastModified by current user
             // //console.log(
             //      "specimen was created / lastModified by current user"
             //   );
             state.hasOwnership = true;
             return null;
           }
           //  //console.log(
           //     state.projectsList.hasOwnProperty(
           //       formattedSpecimen.groupSpecific.soverdi.plantation.project
           //     )
           //   );
 
           // check if userEditOnly has ownership over that tree
           state.hasOwnership = state.projectsList.hasOwnProperty(
             formattedSpecimen.groupSpecific.soverdi.plantation.project
           );
           return null;
         }
       } */
      if (state.userGroupMembership === "singleUser") {
        if (
          formattedSpecimen.groupSpecific.singleUser.lastModifiedBy.name ==
          state.user.email
        ) {
          // specimen was created / lastModified by current user
          // console.log("specimen was created / lastModified by current user");
          state.hasOwnership = true;
          return null;
        } else {
          state.hasOwnership = false;
          return null;
        }
      }
      state.hasOwnership = true;
    } else {
      state.hasOwnership = false;
    }
  },
  setShowNewSpecimenModal(
    state: { showNewSpecimenModal: boolean },
    value: any
  ) {
    // console.log(value);
    state.showNewSpecimenModal = value;
  },
  setShowNearestSpecimenModal(
    state: { showNearestSpecimenModal: boolean },
    value: any
  ) {
    // console.log(value);
    state.showNearestSpecimenModal = value;
  },
  setNearestSpecimenSheetModal(
    state: { nearestSpecimenModal: boolean },
    value: any
  ) {
    // console.log(value);
    state.nearestSpecimenModal = value;
  },
  setShowRefreshMap(state: { showRefreshMap: boolean }, value: any) {
    // console.log(value);
    state.showRefreshMap = value;
  },
  setChosenSpecimenMarkerFirestoreId(
    state: { chosenSpecimenMarkerFirestoreId: any },
    value: any
  ) {
    // console.log(value);
    state.chosenSpecimenMarkerFirestoreId = value;
  },
  setFetchedSpecimensObj(state: { fetchedSpecimensObj: any }, value: any) {
    // console.log(value);
    state.fetchedSpecimensObj = value;
  },
  setSideMenuDisabled(state: { sideMenuDisabled: boolean }, value: any) {
    // console.log(value);
    state.sideMenuDisabled = value;
  },
  setCurrentSegment(state: { currentSegment: string }, value: any) {
    //console.log("setCurrentSegment");
    //console.log(value);
    state.currentSegment = value;
  },
  setCurrentPosition(state: { currentPosition: any }, value: any) {
    // console.log("value");
    // console.log(value);
    state.currentPosition = value;
  },
  setMapCenter(state: { mapCenter: any }, value: any) {
    // console.log("value");
    // console.log(value);
    state.mapCenter = value;
  },
  setNearestSpecimen(state: { nearestSpecimen: any }, value: any) {
    // console.log("value");
    // console.log(value);
    state.nearestSpecimen = value;
  },
  setFirebaseApp(state: { firebaseApp: any }, value: any) {
    //console.log("setFirebaseApp");
    // // console.log(value);
    state.firebaseApp = value;
  },
  setShowDateTime(state: { showDateTime: any }, value: any) {
    // console.log("setShowDateTime");
    // console.log(value);
    state.showDateTime = value;
  },
  setEditingTask(state: { editingTask: any }, value: any) {
    // console.log("value");
    // console.log(value);
    state.editingTask = { name: value.name, prop: value.prop };
  },
  setTasksArray(state: { tasksArray: any }, value: any) {
    // console.log("setTasksArray");
    // console.log(value);
    state.tasksArray = value;
  },
  setMapboxMap(state: { mapboxMap: any }, value: any) {
    // console.log("setMapboxMap");
    // console.log(value);
    state.mapboxMap = value;
  },
  setFetchedSpecimensAsFeatureCollection(
    state: { fetchedSpecimensAsFeatureCollection: any },
    value: any
  ) {
    // console.log("setFetchedSpecimensAsFeatureCollection");
    // console.log(value);
    state.fetchedSpecimensAsFeatureCollection = value;
  },
  setSideMenuRef(state: { sideMenuRef: any }, value: any) {
    //console.log("setSideMenuRef");
    //console.log(value);
    state.sideMenuRef = value;
  },
  setActivatedTabQuerySelector(
    state: { activatedTabQuerySelector: any },
    value: any
  ) {
    // console.log("setActivatedTabQuerySelector");
    // console.log(value);
    state.activatedTabQuerySelector = value;
  },
  setLastGeoqueryCoordinatesLngLat(
    state: { lastGeoqueryCoordinatesLngLat: any },
    value: any
  ) {
    //console.log("setLastGeoqueryCoordinatesLngLat");
    //console.log(value);
    state.lastGeoqueryCoordinatesLngLat = value;
  },
  setSelectedFilter(state: { selectedFilter: any }, value: any) {
    // console.log("setSelectedFilter");
    // console.log(value);
    state.selectedFilter = value;
  },
  setSelectedSpeciesFilter(state: { selectedSpeciesFilter: any }, value: any) {
    // console.log("setSelectedFilter");
    // console.log(value);
    state.selectedSpeciesFilter = value;
  },
  setFiltersListArrayInQueryOptions(state: { queryOptions: any }, value: any) {
    // console.log("setFiltersListArrayInQueryOption");
    // console.log(value);
    state.queryOptions.filters = value;
  },
  setInitialFetch(state: { initialFetch: any }, value: any) {
    // console.log("setInitialFetch");
    // console.log(value);
    state.initialFetch = value;
  },
  setShowKeyboard(state: { showKeyboard: any }, value: any) {
    //console.log("setShowKeyboard");
    //console.log(value);
    state.showKeyboard = value;
  },
  setCreateOrModifySpecimen(
    state: { createOrModifySpecimen: any },
    value: any
  ) {
    // console.log("setCreateOrModifySpecimen");
    // console.log(value);
    state.createOrModifySpecimen = value;
  },
  setGroupQueryFilters(state: { queryFiltersList: any }, value: any) {
    // console.log("setGroupQueryFilters");
    // console.log(value);
    state.queryFiltersList.groupQueryFilters = value;
  },
  setFetchedSpecimensProjectIDsListAsFeatureCollection(
    state: { fetchedSpecimensProjectIDsListAsFeatureCollection: any },
    value: any
  ) {
    // console.log("setFetchedSpecimensProjectIDsListAsFeatureCollection");
    // console.log(value);
    state.fetchedSpecimensProjectIDsListAsFeatureCollection = value;
  },
  setFetchedSpecimensProjectIDsListAsObject(
    state: { fetchedSpecimensProjectIDsListAsObject: any },
    value: any
  ) {
    //console.log("setFetchedSpecimensProjectIDsListAsObject");
    // console.log(value);
    state.fetchedSpecimensProjectIDsListAsObject = value;
  },
  setCurrentProjectID(state: { currentProjectID: any }, value: any) {
    //console.log("setCurrentProjectID");
    //console.log(value);
    state.currentProjectID = value;
  },
  setCurrentSegmentForProjectView(
    state: { currentSegmentForProjectView: any },
    value: any
  ) {
    // console.log("setCurrentSegmentForProjectView");
    // console.log(value);
    state.currentSegmentForProjectView = value;
  },
  setCurrentProjectPlantations(
    state: { currentProjectPlantations: any },
    value: any
  ) {
    // console.log("setCurrentProjectPlantations");
    // console.log(value);
    state.currentProjectPlantations = value;
  },
  setTreeIconsOptions(state: { treeIconsOptions: any }, value: any) {
    // console.log("setTreeIconsOptions");
    // console.log(value);
    state.treeIconsOptions = value;
  },
  setShowTryingToAcquirePosition(
    state: { showTryingToAcquirePosition: any },
    value: boolean
  ) {
    // console.log("setCreateOrModifySpecimen");
    // console.log(value);
    state.showTryingToAcquirePosition = value;
  },
  setMapFadeInAnimation(state: { mapFadeInAnimation: any }, value: any) {
    // console.log("setCreateOrModifySpecimen");
    // console.log(value);
    state.mapFadeInAnimation = value;
  },
  setShowSideMenuForProject(
    state: { showSideMenuForProject: any },
    value: boolean
  ) {
    // console.log("setCreateOrModifySpecimen");
    // console.log(value);
    state.showSideMenuForProject = value;
  },
  setSpecimenIsFromAnActivePlantation(
    state: { specimenIsFromAnActivePlantation: any },
    value: any
  ) {
    //console.log("setSpecimenIsFromAnActivePlantation");
    //console.log(value);
    state.specimenIsFromAnActivePlantation = value;
  },
  setSpecimenDetailsContentElementHeight(
    state: { specimenDetailsContentElementHeight: any },
    value: any
  ) {
    //console.log("setSpecimenDetailsContentElementHeight");
    //console.log(value);
    state.specimenDetailsContentElementHeight = value;
  },
  setDraggingModeActivated(
    state: { draggingModeActivated: boolean },
    value: boolean
  ) {
    // console.log("setCreateOrModifySpecimen");
    //console.log(value);
    state.draggingModeActivated = value;
  },
  setWatchId(state: { watchId: any }, value: any) {
    // console.log("setCreateOrModifySpecimen");
    //console.log(value);
    state.watchId = value;
  },
};

const actions = {
  /*   geolocalizeCartItemOnRemote(
      context: any,
      payload: { cartItemHashKey: any; specimenToGeolocalize: any }
    ) {
      // console.log('trying to geolocalizeCartItemOnRemote in index.js');
      // console.log(JSON.stringify(payload));
      return new Promise(function (res, reject) {
        ActivePlantationService.geolocalizeCartItemOnRemote(
          payload.cartItemHashKey,
          payload.specimenToGeolocalize
        ).then(function (quantityLeft: unknown) {
          res(quantityLeft);
        });
      });
    }, */
  // use ES2015 argument destructuring to simplify the code a bit (especially when we need to call commit multiple times)
  addPictureURLToSpecimen({ commit, url }: { commit: any; url: any }) {
    // console.log('trying to addPictureURLToSpecimen in index.js');
    commit("increment", url);
  },
  fetchProfile() {
    // console.log('trying to fetchProfile in index.js');
    // ProfileService.getProfile();
  },
  formatNewSpecimen() {
    // console.log("new specimen store action");
    return new Promise(function (res: any, reject) {
      // console.log("specimen from formatFetchedSpecimen store action");
      //// console.log(specimen.groupSpecific);
      SpecimenService.getNewFormattedSpecimen().then(function () {
        res();
      });
    });
  },
  /*   checkForActivePlantation(context: any, projectName: any) {
      return new Promise(function (res: any, reject) {
        // console.log("checkForActivePlantation");
        // console.log(projectName);
        ActivePlantationService.checkForActivePlantation(projectName)
          .then(function () {
            // console.log("promise resolved in checkForActivePlantation");
            res();
          })
          .catch(function (error: any) {
            alert(
              "Échec de l'opération checkForActivePlantation  " +
                JSON.stringify(error)
            );
            reject();
  
            console.error("Error checkForActivePlantation: ", error);
          });
      });
    }, */
  formatFetchedSpecimen(context: any, specimen: any) {
    return new Promise(function (res: any, reject) {
      // console.log("specimen from formatFetchedSpecimen store action");
      // console.log(JSON.stringify(specimen));
      SpecimenService.getFormattedSpecimen(specimen).then(function () {
        res();
      });
    });
  },
  formatSpecimenForServer(context: { savedSpecimen: any }, options: any) {
    //console.log("store reached");
    context.savedSpecimen = options.specimen;
    mutations.setSavedSpecimen(context, options.specimen);
    return new Promise(function (res, reject) {
      // console.log("specimen from formatSpecimenForServer store action");
      // console.log(formattedSpecimen);
      SpecimenService.formatSpecimenForServer(
        options.specimen,
        options.collaborativeForm
      ).then(function (result: unknown) {
        // console.log(result);
        res(result);
      });
    });
  },
  batchDeleteSpecimenInfoOnRemote(context: any, formattedSpecimen: any) {
    // console.log("store reached");
    return new Promise(function (res, reject) {
      // console.log("specimen from batchDeleteSpecimenInfoOnRemote store action");
      // console.log(formattedSpecimen);
      SpecimenService.batchDeleteSpecimenInfoOnRemote(formattedSpecimen).then(
        function (result: unknown) {
          // console.log(result);
          res(result);
        }
      );
    });
  },
};
const storeConfig: any = {
  state,
  getters,
  mutations,
  actions,
};

const store: any = createStore(storeConfig);

export default store;
