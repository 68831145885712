<template>
  <div class="chart-container">
    <Pie :data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "vue-chartjs";
import { ref, watch } from "vue";
import store from "@/store";

ChartJS.register(ArcElement, Tooltip, Legend);
const chartColor = ref(true);

export default {
  name: "PieChart",
  components: {
    Pie,
  },
  computed: {
    chartData() {
      let data = [];
      const labelsSpecimensByGenus = [];
      const dataSpecimensByGenus = [];
      const backgroundColorsList = ['#a43ae6','#33b1ff','#007d79','#ff7eb6','#fa4d56','#fff1f1','#6fdc8c','#4589ff','#d12771','#d2a106','#08bdba','#bae6ff','#ba4e00','#d4bbff'];
      const backgroundColors = [];
      if (this.$store.state.selectedFeaturedProject && this.$store.state.selectedFeaturedProject.stats) {
        data = Object.values(this.$store.state.selectedFeaturedProject.stats.genus);
      } else if (this.$store.state.fetchedSpecimensByGenus) {
        data = this.$store.state.fetchedSpecimensByGenus;
      }
      if (!data || !data.length) {
        data = [{
          "amount": 0,
          "nameFr": "empty",
          "code": "PR"
        }];
      }
      let counter = 0
      data.forEach((item) => {
        if (item.code == "autres") {
          backgroundColors.push('#000000')
        } else {
          if (counter > 6) {
            counter = 0
          }
          backgroundColors.push(backgroundColorsList[counter])
          counter++;
        }
        labelsSpecimensByGenus.push(item.nameFr);
        dataSpecimensByGenus.push(item.amount);
      });
      return /* mutable chart data */ {
        labels: labelsSpecimensByGenus,
        datasets: [
          {
            data: dataSpecimensByGenus,
            backgroundColor: backgroundColors,
            radius: "95%"
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
/*           title: {
            display: true,
            text: 'Genres'
          }, */
          legend: {
            position: 'top',
            display: true,
            labels: {
              color: chartColor.value ? "white" : "black",
              /* font: {
                size: 1
              } */
            }
          }
        },
        responsive: true,
        radius: "150%"
      };
    },
  },
  setup() {
    const checkUIAppearence = () => {
      console.log("checking UI ");
      console.log(store.state.currentUIAppearence);
      if (store.state.currentUIAppearence == "auto") {
        // Use matchMedia to check the user preference
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
        chartColor.value = prefersDark.matches;
        //      ChartJS.defaults.color = prefersDark.matches ? "black" : "white";
        // Listen for changes to the prefers-color-scheme media query
        prefersDark.onchange = (mediaQuery) => {
          // alert(mediaQuery.matches);
          chartColor.value = mediaQuery.matches;
        };
      } else if (store.state.currentUIAppearence == "light") {
        chartColor.value = false;
      } else {
        chartColor.value = true;
      }
    }
    checkUIAppearence();
    watch(() => store.state.currentUIAppearence, () => {
      checkUIAppearence();
    })
  },
};
</script>
